import React from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import UpdatePhoneNumberForm from '../components/form/forms/updatePhoneNumber';
import Data from '../containers/data';
import Header from '../components/header';

function AccountPhoneNumber() {
  return (
    <NarrowColumn>
      <Header />
      <Data keys={['user']}>{([user]) => <UpdatePhoneNumberForm user={user} />}</Data>
    </NarrowColumn>
  );
}

export default AccountPhoneNumber;
