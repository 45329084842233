import React from 'react';
import cc from 'classcat';
import styles from './style.module.scss';

function Box({
  component: Comp = 'div',
  width,
  justify,
  align,
  column,
  style,
  children,
  className,
}) {
  return (
    <Comp
      className={cc([styles.box, className])}
      style={{
        justifyContent: justify,
        alignItems: align,
        flexDirection: column ? 'column' : 'row',
        width,
        ...style,
      }}
    >
      {children}
    </Comp>
  );
}

export default Box;
