import React from 'react';
import style from './style.module.scss';
import cc from 'classcat';

function EnvelopeOpen({ className, ...props }) {
  return (
    <svg viewBox="0 0 256 256" {...props} className={cc([className, style.stroke, style.small])}>
      <path
        d="M224,96V200a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V96l96-64Z"
        fill="none"
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="14"
      />
      <line
        x1="110.5"
        y1="152"
        x2="34.5"
        y2="205.7"
        fill="none"
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="14"
      />
      <line
        x1="221.5"
        y1="205.7"
        x2="145.5"
        y2="152"
        fill="none"
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="14"
      />
      <polyline
        points="224 96 145.5 152 110.5 152 32 96"
        fill="none"
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="14"
      />
    </svg>
  );
}

export default EnvelopeOpen;
