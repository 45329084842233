import { Redirect } from '@reach/router';
import Data from '../containers/data';
import { connect } from '../containers/storeContext';
import mitt from 'mitt';
import React, { Component } from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Card from '../components/dashboard/card';
import Header from '../components/header';
import Page from '../components/page';
import Paragraph from '../components/paragraph';
import { ThemeProvider } from '../containers/themeContext';
import { T } from '../containers/translationContext';
import { actions } from '../store';
import { set } from '../utils/storage';
import store from '../store';
import DigitalConsentDialog from '../components/dialogBoxes/digitalConsent';
import { endpoints } from '../config/data';

class Dashboard extends Component {
  /**
   * Constructor
   */
  yPos = 0;
  lock = false;
  cards = [];
  emitter = mitt();

  state = {
    cardIndex: (localStorage.dashboardIndex && JSON.parse(localStorage.dashboardIndex)) || {},
    year: new Date().getFullYear().toString(),
    showConsentModal: false,
  };

  componentDidMount() {
    const getUserConsent = async () => {
      let { userId } = store.getState().data.user.value;
      await endpoints
        .userGetDigitalConsent(this.props.authToken, userId)
        .then(res => {
          if (res.reasonCode === '00' && res.success) {
            this.setState({ showConsentModal: res.data.showConsent });
          }
        })
        .catch(err => {
          this.renderError();
        });
    };

    if (sessionStorage.getItem('isAdminMasterLogin') !== 'Y') {
      getUserConsent();
    } else {
      this.setState({ showConsentModal: false });
    }
  }

  getCardIndex = () => {
    const { contractId } = this.props.activePolicy;
    return this.state.cardIndex[contractId] || 0;
  };

  updateCardIndex = index => {
    const newIndexState = {
      ...this.state.cardIndex,
      [this.props.activePolicy.contractId]: index,
    };

    set('dashboardIndex', JSON.stringify(newIndexState));
    this.setState({
      cardIndex: newIndexState,
    });
  };

  /**
   * Handle changing pages
   * @param changeToIndex
   */
  changePage = changeToIndex => {
    this.updateCardIndex(changeToIndex);
  };

  changePageFromButtons = (oldIndex, newIndex, next) => {
    if (this.lock) return;

    // to prevent spamming while animating
    this.lock = true;
    setTimeout(() => {
      this.lock = false;
    }, 1000);

    this.updateCardIndex(newIndex);
    this.emitter.emit('navigate', { oldIndex: oldIndex, newIndex: newIndex, next: next });
  };

  renderError = () => {
    return (
      <Page color="darkPetrol" layer={2}>
        <NarrowColumn>
          <Header left="menu" />
          <Paragraph color="white">
            <T id="policy.dashboard.missingData" />
          </Paragraph>
        </NarrowColumn>
      </Page>
    );
  };

  /**
   * Render
   * @returns {*}
   */
  render() {
    if (!this.props.activePolicy) return <Redirect to="/" noThrow />;

    const { activePolicy, authToken } = this.props;
    const { contractId } = activePolicy;
    const { year, showConsentModal } = this.state;
    const activeIndex = this.getCardIndex();

    return (
      <div style={{ zIndex: 20, position: 'absolute' }} ref={c => (this.carousel = c)}>
        <Data
          keys={[`projectedValues:${contractId}`, `policy:${contractId}`]}
          renderErrors={e => (
            <Page color="darkPetrol" layer={2}>
              <NarrowColumn>
                <Paragraph color="white" size="small">
                  {e.filter(Boolean)[0]}
                </Paragraph>
              </NarrowColumn>
            </Page>
          )}
        >
          {([projectedValues, policy]) => {
            const { pages: dashboardPages, indexes: dashboardIndexes, years } = projectedValues;

            if (dashboardPages === null || dashboardIndexes.length === 0) {
              return this.renderError();
            }

            const currency = policy.contract._currency.value;
            const yearValues = years[year];
            if (!yearValues) {
              return null;
            }

            return dashboardIndexes.map((page, i) => {
              const projected = { ...yearValues[page], valueDate: yearValues.valueDate.values[0] };

              // Don't render this card if it's not visible
              if (!projected.visibility) {
                return '';
              }

              const previousIndex = (i - 1 + dashboardIndexes.length) % dashboardIndexes.length;
              const nextIndex = (i + 1) % dashboardIndexes.length;
              const prev = dashboardIndexes[previousIndex];
              const next = dashboardIndexes[nextIndex];
              const prevLabel = prev && yearValues[prev].displayLabel;
              const nextLabel = next && yearValues[next].displayLabel;

              const name = dashboardIndexes[activeIndex] || null;
              let color = 'darkPetrol';
              let right;
              if (name !== null) {
                color = dashboardPages[name].color;
                //right = 'yearSelector'; // Do not show year selector
              }

              return (
                <React.Fragment key={i}>
                  <ThemeProvider value={{ color }}>
                    <Header left="menu" right={right} dashboardPage={name} />
                  </ThemeProvider>

                  <Card
                    ref={c => (this.card = c)}
                    active={activeIndex === i}
                    activeIndex={activeIndex}
                    color={dashboardPages[page].color}
                    projected={projected}
                    currency={currency}
                    name={page}
                    nextLabel={nextLabel}
                    prevLabel={prevLabel}
                    onNext={() => this.changePageFromButtons(activeIndex, nextIndex, true)}
                    onPrev={() => this.changePageFromButtons(activeIndex, previousIndex, false)}
                    onChange={index => this.changePage(index)}
                    index={i}
                    key={page}
                    emitter={this.emitter}
                    totalCards={dashboardIndexes.length}
                  />
                </React.Fragment>
              );
            });
          }}
        </Data>
        {showConsentModal && (
          <DigitalConsentDialog isShow={showConsentModal} authToken={authToken} />
        )}
      </div>
    );
  }
}

export default connect('authToken, activePolicy', actions)(Dashboard);
