import { pick } from '@reach/router/lib/utils';
import routes from '../config/routes';
import { prefetch } from './prefetcher';
import store, { boundActions } from '../store';

function prefetchPageComponent(path) {
  const match = pick(routes, path);
  if (match && match.route.Comp.prefetch) {
    prefetch(match.route.Comp.prefetch);
  }
}

function preloadDashboard() {
  return Promise.resolve(boundActions.getData('user')).then(() => {
    return Promise.resolve(boundActions.getData('policies')).then(() => {
      const state = store.getState();
      if (state.activePolicy) {
        const { contractId } = state.activePolicy;
        return Promise.all([
          boundActions.getData('projectedValues', null, contractId),
          boundActions.getData('policy', null, contractId),
          boundActions.getData('payments', null, contractId),
          boundActions.getData('unreadMessageCount'),
        ]).catch(e => {
          // Specific message to indicate preloading policy data failed
          throw e;
        });
      }

      return null;
    });
  });
}

function slug(i) {
  return i.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
}

export { prefetchPageComponent, slug, preloadDashboard };
