import React, { Component } from 'react';
import CloseButton from '../components/button/close';
import Header from '../components/header';
import Heading from '../components/heading';
import ProjectedValues from '../components/projectedValues';
import Data from '../containers/data';
import routeContext from '../containers/routeContext';
import { connect } from '../containers/storeContext';
import { ThemeProvider } from '../containers/themeContext';

let currentColor = 'darkPetrol';

class PolicyProjectedValues extends Component {
  static contextType = routeContext;

  handleDataLoad = () => {
    // Handle a deep-link on the dashboard
    const hash = window.location.hash;
    if (hash) {
      const type = hash.replace('#', '');
      // Set the current color if the type exists
      if (this._dashboardPages.hasOwnProperty(type)) {
        currentColor = this._dashboardPages[type].color;
      }
    }
  };

  render() {
    let { year } = this.context;
    const { activePolicy } = this.props;

    // Ensure the year is not in the future
    const currentYear = new Date().getFullYear();
    if (parseInt(year) > currentYear) {
      year = currentYear.toString();
    }

    return (
      <ThemeProvider value={{ color: currentColor }}>
        <Header
          left={
            <Heading size="h5" variant="sans" weight="bold">
              {year}
            </Heading>
          }
          right={<CloseButton color="darkPetrol" />}
        />
        <Data keys={[`projectedValues:${activePolicy.contractId}`]} onSuccess={this.handleDataLoad}>
          {([projectedValues]) => {
            this._dashboardPages = projectedValues.pages;
            return (
              <ProjectedValues
                year={year}
                dashboardPages={this._dashboardPages}
                activePolicy={activePolicy}
              />
            );
          }}
        </Data>
      </ThemeProvider>
    );
  }
}

export default connect('activePolicy')(PolicyProjectedValues);
