import React from 'react';
import Icon from '../../image/icon';
import style from './style.module.scss';

function InputIcon({ type, hasInput, hasError }) {
  return (
    <span className={style.label}>
      <Icon className={style.icon} type={type} hasInput={hasInput} hasError={hasError} />
    </span>
  );
}

export default InputIcon;
