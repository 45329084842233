import React from 'react';
import { animateReverse } from '../../utils/animation';
import anime from 'animejs';
import style from './style.module.scss';
import cc from 'classcat';
import { getUserAgent } from 'dpdk-devicedetection/src/main';

class ConnectedCardTransition extends React.Component {
  componentWillEnter(from, to) {
    if (this.props.animates) {
      if (getUserAgent() !== 'IE11') this.node.scrollIntoView({ block: 'nearest' });
      const fromBounds = from.node.getBoundingClientRect();
      const toBounds = to.node.getBoundingClientRect();
      animateReverse(
        this.node,
        {
          translateX: fromBounds.left - toBounds.left,
          translateY: fromBounds.top - toBounds.top,
          scaleX: fromBounds.width / toBounds.width,
          scaleY: fromBounds.height / toBounds.height,
        },
        {
          duration: 500,
          easing: 'easeInOutQuart',
        }
      );
    }
  }

  componentWillLeave(from, to) {
    if (this.props.animates) {
      const fromBounds = from.node.getBoundingClientRect();
      const toBounds = to.node.getBoundingClientRect();
      from.node.firstChild.style.borderRadius = '0';
      anime({
        targets: this.node,
        translateX: toBounds.left - fromBounds.left,
        translateY: toBounds.top - fromBounds.top,
        scaleX: toBounds.width / fromBounds.width,
        scaleY: toBounds.height / fromBounds.height,
        duration: 500,
        easing: 'easeInOutQuart',
      });
    }
  }

  getTransitionData() {
    return {
      // Transfering node only because rect is for some reason just 0's frst time round
      // bounds: this.node.getBoundingClientRect(),
      node: this.node,
    };
  }

  render() {
    const { className } = this.props;

    return (
      <div className={cc([style.transition, className && className])} ref={c => (this.node = c)}>
        {this.props.children}
      </div>
    );
  }
}

export default ConnectedCardTransition;
