import React, { Component } from 'react';
import InputGroup from '../../inputGroup';
import Select from '../../inputUnits/select';
import Heading from '../../../../components/heading';
import translationContext from '../../../../containers/translationContext';
import Data from '../../../../containers/data';
import Paragraph from '../../../paragraph';
import Link from '../../../link';
import Icon from '../../../image/icon';
import style from './step1.module.scss';
import portalLanguages from '../../../../config/languages';
import Placeholder from '../../../placeholder';
import TextUnit from '../../inputUnits/text';

class Step1 extends Component {
  static contextType = translationContext;
  english = 'en';
  state = {
    language: this.props.values.language,
  };
  inputRefs = [];

  componentDidMount = () => {
    if (this.props.values.marketId) {
      const marketLanguages = [this.props.values.marketId.slice(-2).toLowerCase(), this.english];
      const configuredLanguage = this.props.values.language;

      if (!marketLanguages.includes(configuredLanguage)) {
        this.changeMarket(this.props.values.marketId);
      }
    }

    let isArgsExists = document.location.search.includes('args');

    if (isArgsExists) {
      let argsURL = document.location.search.split('=')[1];
      try {
        let urlData = atob(argsURL);

        if (urlData) {
          let splitURLData = urlData.split('~');
          if (splitURLData.length === 4) {
            this.changeLanguage(splitURLData[3].toLowerCase());
          }
        }
      } catch (err) {}
    }
  };

  changeLanguage = async e => {
    const { onChangeLanguage } = this.props.changeHandler;
    const { invalidateAllData } = this.props;

    await onChangeLanguage(e);
    Promise.resolve(invalidateAllData());
    this.setState({ language: e });
  };

  changeMarket = e => {
    const { onChangeMarket } = this.props.changeHandler;

    onChangeMarket(e);

    const marketLanguages = e.slice(-2).toLowerCase();
    const possibleLanguages = portalLanguages.map(data => data.key.toLowerCase());
    if (possibleLanguages.includes(marketLanguages)) {
      this.changeLanguage(marketLanguages);
    } else {
      this.changeLanguage(this.english);
    }
  };

  isValid() {
    return this.inputRefs.every(input => input.isValid());
  }

  render() {
    const { t } = this.context;

    const propValues = this.props.values;
    propValues.language = this.state.language;

    const inputProps = {
      values: propValues,
      setError: this.props.setError,
      errors: this.props.errors,
      setValueState: this.props.setValueState,
    };

    const privacyLinks = {
      it: 'https://forwardyou.com/it/protezione-dei-dati',
      en: 'https://forwardyou.com/en/data-protection',
      es: 'https://www.forwardyou.com/es/politica-de-privacidad/',
      de: 'https://www.forwardyou.com/de/datenschutzerklaerung/',
      fr: 'https://www.forwardyou.com/fr/donnees-personnelles/',
      nl: 'https://www.forwardyou.com/nl-be/privacyverklaring/',
    };

    return (
      <>
        <Heading size="h4" component="h2">
          {t('register.heading')}
        </Heading>
        <Heading size="h6" component="h3" variant="light" color="black">
          {t('register.subheading')}
        </Heading>

        <div>
          <div className={style.left}>
            <InputGroup heading={t('register.marketHeading')} marginTop={40}>
              <Data keys={['markets']} fallback={<Placeholder />}>
                {([markets]) => {
                  return (
                    <Select
                      key="marketId"
                      name="marketId"
                      items={markets}
                      width="auto"
                      onChange={this.changeMarket}
                      openTowards="top"
                      itemToString={market => market && market.marketDesc}
                      itemToKey={market => market && market.marketId}
                      ref={c => (this.inputRefs[0] = c)}
                      {...inputProps}
                    />
                  );
                }}
              </Data>
            </InputGroup>
          </div>

          <div className={style.right}>
            {this.props.values.marketId ? (
              <>
                <InputGroup heading={t('register.languageHeading')} marginTop={40}>
                  <Data
                    keys={[`languages:${inputProps.values.marketId}:${inputProps.values.language}`]}
                    fallback={<Placeholder />}
                  >
                    {([languages]) => {
                      return (
                        <Select
                          name="language"
                          items={languages}
                          width="auto"
                          onChange={this.changeLanguage}
                          openTowards="top"
                          //ref={c => (this.inputRefs[1] = c)}
                          {...inputProps}
                        />
                      );
                    }}
                  </Data>
                </InputGroup>
              </>
            ) : null}
          </div>
        </div>

        <div className={style.clear}>
          <InputGroup heading="" marginTop={60}>
            <TextUnit
              color="petrol"
              name="policyId"
              icon="document"
              label={t('policy.detail.general.policyNumber')}
              tooltip={
                this.props.values.language === 'en'
                  ? this.props.values.marketId === 'FLL-ES'
                    ? t('policy.detail.general.policyNumber_toolTip_en_es')
                    : t('policy.detail.general.policyNumber_toolTip_en_it')
                  : t('policy.detail.general.policyNumber_toolTip')
              }
              // label={t('policy.detail.general.register_policyNumber')}
              ref={c => (this.inputRefs[2] = c)}
              {...inputProps}
            />

            {/* <p className={style.text_helper}>{t('policy.detail.general.policyNumber_helper')}</p> */}

            <TextUnit
              name="taxId"
              icon="policy"
              label={t('form.label.taxId')}
              tooltip={
                this.props.values.language === 'en'
                  ? this.props.values.marketId === 'FLL-ES'
                    ? t('form.label.taxId_toolTip_en_es')
                    : t('form.label.taxId_toolTip_en_it')
                  : t('form.label.taxId_toolTip')
              }
              ref={c => (this.inputRefs[3] = c)}
              {...inputProps}
            />

            {/* <p className={style.text_helper}>{t('policy.detail.beneficiaries.taxId_helper')}</p> */}
          </InputGroup>
        </div>

        <InputGroup heading="" marginTop={60}>
          <Paragraph size="small">{t('register.privacyInfo')}</Paragraph>
          <Link
            href={privacyLinks[inputProps.values.language]}
            target="_blank"
            className={style.link}
          >
            {t('register.privacyLink')}
            <Icon type="more" color="darkPetrol" className={style.moreIcon} />
          </Link>
        </InputGroup>
      </>
    );
  }
}

export default Step1;
