import React, { Component } from 'react';
import { connect } from '../../../containers/storeContext';
import { navigate } from '@reach/router';
import Request from '../../../containers/request';
import { endpoints } from '../../../config/data';
import Placeholder from '../../../components/placeholder';
import Thumb from '../../image/thumb';
import Hybrid from '../../image/hybrid';
import Heading from '../../heading';
import Paragraph from '../../paragraph';
import { translate } from '../../../containers/translationContext';
import { actions } from '../../../store';
import TextLink from '../../textLink';

class ChangeEmailConfirm extends Component {
  onSuccess = () => {
    setTimeout(() => {
      this.props.clearUser();
      navigate('/');
    }, 3000);
  };

  renderError = (t, message) => (
    <>
      <Heading>{message}</Heading>
      <Hybrid />
      <Paragraph size="small">
        {t('form.error.invalidLink')}{' '}
        <TextLink href="/account/email">{t('form.button.resend')}</TextLink>
      </Paragraph>
    </>
  );

  renderSuccess = (t, message) => (
    <>
      <Heading size="h1">{message}</Heading>
      <Thumb />
      <Paragraph>{t('activate.body')}</Paragraph>
    </>
  );

  render() {
    const { t } = this.props;

    return (
      <Request
        api={endpoints.activate}
        params={{ activationToken: this.props.code }}
        onSuccess={this.handleSuccess}
      >
        {({ response, error, fetching }) => {
          // Display loader while fetching
          if (fetching) return <Placeholder />;

          if (error) return <Paragraph>{t('form.error.technical')}</Paragraph>;

          if (response && response.success) {
            return this.renderSuccess(t, response.message);
          }
          return this.renderError(t, response.message);
        }}
      </Request>
    );
  }
}

export default connect(null, actions)(translate(ChangeEmailConfirm));
