import React, { Component } from 'react';
import Form from '../index';
import Button from '../../button/submit';
import { endpoints } from '../../../config/data';
import Heading from '../../heading';
import { translate } from '../../../containers/translationContext';
import TextLink from '../../textLink';

class AccountDeleteConfirmForm extends Component {
  renderError = (t, error) => (
    <>
      <Heading size="h5" variant="light" align="center">
        {error}
      </Heading>

      <Heading size="h6" variant="light" align="center">
        <TextLink href="/account">{t('deleteConfirm.backToSettings')}</TextLink>
      </Heading>
    </>
  );

  render() {
    const { t, deleteUserToken } = this.props;

    return (
      <Form
        api={endpoints.deleteConfirm}
        onSuccess={this.props.onSuccess}
        initialValues={{ deleteUserToken }}
      >
        {({ submit, isSending, sendError, ...inputProps }) =>
          (sendError && this.renderError(t, sendError)) || (
            <>
              <Heading size="h5" variant="light" align="center" style={{ marginTop: `35vh` }}>
                {t('deleteConfirm.formBody')}
              </Heading>
              <Button onClick={submit} disabled={isSending} noMenu>
                {t('form.button.confirm')}
              </Button>
            </>
          )
        }
      </Form>
    );
  }
}

export default translate(AccountDeleteConfirmForm);
