import React, { Component, createRef, memo } from 'react';
import cc from 'classcat';
import ConnectedTransition from 'react-connected-transition';
import ConnectedCardTransition from '../connectedCardTransition';
import transitionContext from '../../containers/transitionContext';
import Link from '../../components/link';
import style from './style.module.scss';
import Icon from '../image/icon';

const CardNav = memo(({ children }) => {
  return <nav className={style.nav}>{children}</nav>;
});

class Card extends Component {
  static contextType = transitionContext;

  ref = createRef();

  handleEnter = () => {
    if (this.ref.current) {
      this.ref.current.style.zIndex = 2;
    }
  };

  handleLeave = () => {
    if (this.ref.current) {
      this.ref.current.style.zIndex = 3;
    }
  };

  render() {
    const {
      href,
      arrow,
      icon,
      children,
      className,
      simple,
      dontTransition,
      isCallBack,
      handleCallBack,
      ...rest
    } = this.props;
    const transitionState = this.context;
    const iconType = icon || (arrow && 'more');
    const props = rest;
    let Comp = 'div';
    if (isCallBack === undefined) {
      if (href) {
        props.href = href;
        props.innerRef = this.ref;
        Comp = Link;
      } else {
        props.ref = this.ref;
      }
    }

    return (
      <Comp
        className={cc([style.card, simple && style.simple, className])}
        {...props}
        onClick={() => {
          if (isCallBack) {
            handleCallBack();
          }
        }}
      >
        {!simple && (
          <ConnectedTransition
            name={href || 'card'}
            passive={!href || dontTransition}
            exit={transitionState === 'exiting' || transitionState === 'exited'}
            onEnter={this.handleEnter}
            onLeave={this.handleLeave}
          >
            <ConnectedCardTransition animates className={style.transition}>
              <div className={style.background} />
            </ConnectedCardTransition>
          </ConnectedTransition>
        )}
        {children}
        {iconType && (
          <Icon type={iconType} color="darkPetrol" className={cc([style.icon, style[iconType]])} />
        )}
      </Comp>
    );
  }
}

CardNav.Card = Card;

export { Card };
export default CardNav;
