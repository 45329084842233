import React from 'react';
import { Card as NavCard } from '../cardNav';
import style from './card.module.scss';

function Card({ children, type, href, ...rest }) {
  return (
    <NavCard className={type === 'addPolicy' ? style.addPolicy : style.card} href={href} {...rest}>
      {children}
    </NavCard>
  );
}

export default Card;
