import React from 'react';
import Heading from '../heading';
import CardNav from '../cardNav';
import { slug } from '../../utils/routing';

function Questions({ items, category, section }) {
  return (
    <>
      {items.map((questions, j) => (
        <div key={j}>
          {/* display questions in section */}
          <Heading variant="sans" size="h4">
            {questions.title}
          </Heading>

          <CardNav>
            {questions.questions.map((answer, h) => (
              <CardNav.Card
                key={h}
                href={`/service/faq/${category}/${section}/${slug(answer.question)}`}
                arrow
                simple
              >
                <Heading
                  color="darkPetrol"
                  size="h6"
                  variant="light"
                  style={{ 'max-width': 'calc(100% - 40px)' }}
                >
                  {answer.question}
                </Heading>
              </CardNav.Card>
            ))}
          </CardNav>
        </div>
      ))}
    </>
  );
}

export default Questions;
