import React from 'react';
import { translate } from '../../containers/translationContext';
import Form from '../form';
import Data from '../../containers/data';
import { connect } from '../../containers/storeContext';
import Placeholder from '../placeholder';
import Heading from '../heading';
import Header from '../header';
import CloseButton from '../button/close';
import InputGroup from '../form/inputGroup';
import TextUnit from '../form/inputUnits/text';
import formatMoney from '../../utils/formatMoney';

function TechnicalPaymentData({ t, activePolicy }) {
  const nonEmptyText = (mainText, alternateText) =>
    mainText.length === 0 ? alternateText : mainText;

  const createField = (field, fieldName, fallbackLabel) => {
    return {
      name: fieldName,
      label: nonEmptyText(field.displayLabel, fallbackLabel),
      value: nonEmptyText(field.value, ' '),
      visibility: field.visibility,
    };
  };

  return (
    <Data keys={[`policy:${activePolicy.contractId}`]} fallback={<Placeholder />}>
      {([policy]) => {
        const { contract, paymentMethods } = policy;
        const paymentMethod = paymentMethods.length > 0 ? paymentMethods[0] : null;

        const premiumMoney = formatMoney(
          contract._actualContribution.value,
          contract._currency.value
        );

        const fields = [
          createField(
            contract._actualFrequency,
            'frequency',
            t('policy.detail.payments.paymentFrequency')
          ),
          createField(
            contract._dynamicActual,
            'dynamicActual',
            t('policy.detail.general.dynamicOption')
          ),
          createField(
            {
              displayLabel: t('policy.detail.payments.premium'),
              value: `${premiumMoney.v} ${premiumMoney.symbol}`,
              visibility: contract._actualContribution.visibility,
            },
            'premiumDisplay'
          ),
          createField(
            paymentMethod.paymentType,
            'paymentType',
            t('policy.detail.payments.paymentMethod')
          ),
          createField(paymentMethod.ibanNo, 'iban', t('policy.detail.payments.iban')),
        ];

        const initialValues = fields.reduce((acc, field) => {
          const { name, value, visibility } = field;
          return visibility ? { ...acc, [name]: value } : acc;
        }, {});

        return (
          <Form initialValues={initialValues}>
            {inputProps => (
              <>
                <Header right={<CloseButton href="/payments/technical" color="darkPetrol" />} />
                <Heading size="h3">{t('policy.payments.technical.paymentData')}</Heading>
                <InputGroup>
                  {(() =>
                    fields.map((field, i) => {
                      const { name, label, visibility } = field;
                      return visibility ? (
                        <TextUnit
                          key={i}
                          name={name}
                          label={label}
                          {...inputProps}
                          noIcon
                          readonly
                        />
                      ) : null;
                    }))()}
                </InputGroup>
              </>
            )}
          </Form>
        );
      }}
    </Data>
  );
}

export default connect('activePolicy')(translate(TechnicalPaymentData));
