import React from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import TechnicalPaymentPayer from '../components/payments/technicalPaymentPayer';

function DetailTechnicalPayer() {
  return (
    <NarrowColumn>
      <TechnicalPaymentPayer />
    </NarrowColumn>
  );
}

export default DetailTechnicalPayer;
