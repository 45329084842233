import React, { Component } from 'react';
import { translate } from '../../../../containers/translationContext';
import Form from '../../index';
import SubmitButton from '../../../../components/button/submit';
import { endpoints } from '../../../../config/data';
import { connect } from '../../../../containers/storeContext';
import { actions } from '../../../../store';
import { navigate } from '@reach/router';
import Icon from '../../../image/icon';
import TextUnit from '../../inputUnits/text';
import Paragraph from '../../../paragraph';
import Heading from '../../../heading';
import Thumb from '../../../image/thumb';
import TextLink from '../../../textLink';
import SendError from '../../sendError';
import formatTime from '../../../../utils/formatTime';
import Placeholder from '../../../placeholder';
import Header from '../../../header';
import BackButton from '../../../button/back';
import CloseButton from '../../../button/close';
import PhoneUnit from '../../inputUnits/phone';
import EmailUnit from '../../inputUnits/email';
import Data from '../../../../containers/data';

class ChangeOfPersonalData extends Component {
  eventName = 'UPDATE_POLICY_HOLDER_DATA';

  step = {
    INPUT: 'Input',
    VERIFY: 'Verify',
    SUCCESS: 'Success',
  };
  inputRefs = [];
  state = {
    email: this.props.policyHolderDetails.email.value,
    emailTouched: false,
    phoneCountryCode: '',
    phoneNo: '',
    touched: false,
    success: false,
    isValid: false,
    step: 'Input',
    lastCodeSent: null,
    debugToken: '',
  };

  handleChangeAddress = () => {
    this.setState({
      isValid: this.inputRefs.every(input => (input == null ? true : input.isValid())),
      success: false,
      touched: true,
    });
  };

  handleChangeVerify = () => {
    this.setState({
      isValid: this.inputRefs.every(input => (input == null ? true : input.isValid())),
      success: false,
      touched: true,
    });
  };

  renderInput = () => {
    const { t /*, user, policyHolderDetails*/ } = this.props;
    const { touched, isValid, email, phoneCountryCode, phoneNo } = this.state;

    return (
      <Data keys={['phoneCountryCodes']}>
        {([phoneCountryCodes]) => {
          return (
            <>
              <Header right={<CloseButton href="/alteration" color="darkPetrol" />} />
              <Heading size="h4">{t('alteration.policyHolderData')}</Heading>
              <Paragraph>{t('alteration.policyHolderData.description')}</Paragraph>
              <Form
                api={endpoints.generateSmsToken}
                onValueChange={() => {
                  this.handleChangeAddress();
                }}
                onSuccess={response => {
                  const update = () => {
                    this.setState({
                      step: this.step.VERIFY,
                      isValid: false,
                      touched: false,
                      lastCodeSent: new Date(),
                      debugToken: response.data.smsText || '',
                    });

                    this.inputRefs.length = 0;
                  };

                  update();
                }}
                initialValues={{
                  eventName: this.eventName,
                  email: email,
                  phoneCountryCode: phoneCountryCode,
                  phoneNo: phoneNo,
                }}
              >
                {({ submit, isSending, sendError, ...inputProps }) => {
                  return (
                    <>
                      <EmailUnit
                        name="email"
                        label={t('form.label.email')}
                        ref={c => (this.inputRefs[0] = c)}
                        {...inputProps}
                      />

                      <PhoneUnit
                        name="phoneNo"
                        label={t('form.label.phoneNumber')}
                        countryCodeLabel={t('form.label.countryCode')}
                        ref={c => (this.inputRefs[1] = c)}
                        phoneCountryCodes={phoneCountryCodes}
                        {...inputProps}
                      />

                      <SendError message={sendError} />

                      <div style={{ paddingTop: '65px' }}>
                        <SubmitButton
                          onClick={() => {
                            this.setState({
                              ...inputProps.values,
                              isValid: false,
                            });
                            submit();
                          }}
                          disabled={isSending || !isValid}
                          hidden={!touched}
                        >
                          {t('form.button.save')}
                        </SubmitButton>
                      </div>
                    </>
                  );
                }}
              </Form>
            </>
          );
        }}
      </Data>
    );
  };

  renderVerify = () => {
    const { t, activePolicy } = this.props;
    const { touched, success, isValid } = this.state;

    return (
      <>
        <Header
          left={
            <BackButton
              color="darkPetrol"
              onClick={() => {
                this.setState({
                  step: this.step.INPUT,
                  isValid: true,
                  touched: true,
                });
              }}
            />
          }
          right={<CloseButton href="/alteration" color="darkPetrol" />}
        />
        <Form
          autoComplete="off"
          api={endpoints.updatePersonalData}
          onValueChange={() => this.handleChangeVerify()}
          onSuccess={response => {
            const update = () => {
              this.inputRefs.length = 0;
              this.setState({
                step: this.step.SUCCESS,
                isValid: false,
                success: true,
              });

              setTimeout(() => {
                navigate('/alteration');
              }, 3000);
            };

            update();
          }}
          initialValues={{
            email: this.state.email,
            emailTouched: this.state.emailTouched,
            phoneCountryCode: this.state.phoneCountryCode,
            phoneCountryCodeTouched: this.state.phoneCountryCodeTouched,
            phoneNo: this.state.phoneNo,
            phoneNoTouched: this.state.phoneNoTouched,
            contractId: activePolicy.contractId,
          }}
        >
          {({ submit, isSending, sendError, ...inputProps }) => {
            return (
              <>
                <Heading size="h4">{t('general.sms.verifyHeader')}</Heading>
                <Paragraph>{t('general.sms.verifyBody')}</Paragraph>

                <br />

                <Paragraph>
                  {t('general.sms.requestTime')} {formatTime('en', this.state.lastCodeSent / 1000)}
                </Paragraph>

                <br />
                <TextUnit
                  name="smsToken"
                  label={t('general.sms.verifyCode')}
                  pattern={'^[0-9]{6}$'}
                  ref={c => (this.inputRefs[0] = c)}
                  {...inputProps}
                />

                {isSending ? <Placeholder /> : <SendError message={sendError} />}

                <br />
                <br />

                <SubmitButton
                  onClick={() => {
                    submit();
                  }}
                  disabled={isSending || !isValid}
                  hidden={!touched}
                >
                  {success ? (
                    <>
                      {t('form.button.saved')}
                      <Icon
                        type="check"
                        color="white"
                        style={{ marginTop: '-5px', marginLeft: '5px' }}
                      />
                    </>
                  ) : (
                    t('alteration.complete')
                  )}
                </SubmitButton>
              </>
            );
          }}
        </Form>

        <Form
          api={endpoints.generateSmsToken}
          onSuccess={response => {
            const update = () => {
              this.setState({
                step: this.step.VERIFY,
                isValid: false,
                lastCodeSent: new Date(),
                debugToken: response.data.smsText || '',
              });
            };
            update();
          }}
          initialValues={{
            eventName: this.eventName,
          }}
        >
          {({ submit, isSending, sendError }) => {
            return isSending ? (
              <Placeholder />
            ) : (
              <>
                <Paragraph>
                  {t('general.sms.resendToken')}{' '}
                  <TextLink onClick={submit}>{t('form.button.resend')}</TextLink>
                </Paragraph>
                {['localhost', 'test-cp.forwardyou.com', 'feature-cp.forwardyou.com'].includes(
                  window.location.hostname
                ) ? (
                  <Paragraph>
                    <span style={{ color: '#00879b' }}>
                      <span>
                        Debugging on FWU Test Systems. SMS Code: <b>{this.state.debugToken}</b>
                      </span>
                      <br />
                      <span style={{ fontSize: '0.75em' }}>(localhost, test-cp, feature-cp)</span>
                    </span>
                  </Paragraph>
                ) : (
                  ''
                )}
              </>
            );
          }}
        </Form>
      </>
    );
  };

  renderSuccess = () => {
    const { t } = this.props;

    return (
      <>
        <Header right={<CloseButton href="/alteration" color="darkPetrol" />} />
        <Heading size="h2" align="center">
          {t('alteration.complete')}
        </Heading>
        <Thumb />
        <Paragraph style={{ marginBottom: '40px', textAlign: 'center' }}>
          {t('general.thankYou')}
        </Paragraph>
      </>
    );
  };

  render() {
    switch (this.state.step) {
      case this.step.INPUT:
        return this.renderInput();
      case this.step.VERIFY:
        return this.renderVerify();
      case this.step.SUCCESS:
        return this.renderSuccess();
      default:
        return 'ERROR';
    }
  }
}

export default connect(null, actions)(translate(ChangeOfPersonalData));
