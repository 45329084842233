const breakpoints = {
  small: 320,
  medium: 600,
  large: 1100,
  xlarge: 1400,
  xxlarge: 1600,
  desktop: 900,
};

const mediaRanges = {
  // Ranges starting form a breakpoint
  smallUp: { min: 'small' },
  mediumUp: { min: 'medium' },
  largeUp: { min: 'large' },
  xlargeUp: { min: 'xlarge' },
  xxlargeUp: { min: 'xxlarge' },

  desktopUp: { min: 'desktop' },

  // Ranges between breakpoints
  smallToMedium: { min: 'small', max: 'medium' },
  mediumToLarge: { min: 'medium', max: 'large' },

  // Ranges up to a breakpoint
  smallDown: { max: 'small' },
  mediumDown: { max: 'medium' },
  largeDown: { max: 'large' },
  xlargeDown: { max: 'xlarge' },
  desktopDown: { max: 'desktop' },
};

export { breakpoints, mediaRanges };
