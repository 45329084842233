import React from 'react';
import BackButton from '../components/button/back';
import Header from '../components/header';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Logo from '../components/image/logo';
import Heading from '../components/heading';
import Box from '../components/box';
import Paragraph from '../components/paragraph/index';
import { T } from '../containers/translationContext';

function NotFound() {
  return (
    <NarrowColumn>
      <Header left={<BackButton color="darkPetrol" href="/" />} />
      <Box justify="center" width="50%" style={{ maxWidth: '160px' }}>
        <Logo fullWidth />
      </Box>
      <Heading size="h2">
        <T id="notFound.heading" />
      </Heading>
      <Paragraph>
        <T id="notFound.body" />
      </Paragraph>
    </NarrowColumn>
  );
}

export default NotFound;
