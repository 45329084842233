import React, { Component } from 'react';
import style from './style.module.scss';
import Heading from '../heading';
import Money from '../money';
import Data from '../../containers/data';
import Link from '../link';
import ContentGrid from '../contentGrid';
import WhatDoISee from '../whatDoISee';
import NarrowColumn from '../contentGrid/narrowColumn';
import Icon from '../image/icon';
import { translate } from '../../containers/translationContext';
import Time from '../time';

class ProjectedValues extends Component {
  render() {
    const { activePolicy, dashboardPages, t } = this.props;
    let { year } = this.props;

    return (
      <>
        <Data
          keys={[`policy:${activePolicy.contractId}`, `projectedValues:${activePolicy.contractId}`]}
        >
          {([policy, projectedValues]) => {
            // Ensure the year is not too early
            const years = Object.keys(projectedValues.years);
            if (years.indexOf(year) === -1) {
              year = years[0];
            }

            const yearValues = projectedValues.years[year];
            const currency = policy.contract._currency.value;
            const { projectedEndValue } = yearValues;

            return (
              <>
                <NarrowColumn>
                  <Money value={projectedEndValue.values[0].value} currency={currency}>
                    {({ symbol, big, small }) => (
                      <>
                        <Heading
                          variant="sans"
                          weight="bold"
                          size="h1"
                          color="petrol"
                          className={style.projectedValue}
                        >
                          {symbol} {big}
                          <span className={style.smallNumber}>{small}</span>
                        </Heading>
                        <Heading variant="light" color="petrol" size="h6">
                          {projectedEndValue.values[0].displayLabel}
                        </Heading>
                      </>
                    )}
                  </Money>
                </NarrowColumn>
                <ContentGrid>
                  {({ getFullContentProps, getNarrowContentProps }) => (
                    <div {...getFullContentProps()}>
                      <WhatDoISee
                        body={t(
                          'projectedValues.descriptionBody',
                          <Time
                            key="129319230"
                            timestamp={new Date(`${year}-12-31`).getTime() / 1000}
                            year
                            month
                            day
                          />
                        )}
                      />
                    </div>
                  )}
                </ContentGrid>
                <NarrowColumn>
                  <div className={style.main}>
                    {Object.keys(dashboardPages).map(key =>
                      renderItem(key, yearValues[key], currency)
                    )}
                  </div>
                </NarrowColumn>
              </>
            );
          }}
        </Data>
      </>
    );
  }
}

function renderItem(key, card, currency) {
  if (key === 'projectedEndValue') return '';

  const { values, visibility } = card;

  // Don't render values of this card if not supposed to be visible
  if (!visibility) return '';

  return (
    <>
      {values.map((policyValue, i) => {
        const { value, displayLabel, visibility } = policyValue;

        // Don't render this value if not supposed to be visible
        if (!visibility) return '';

        return (
          <div className={style.item} key={i.toString()}>
            <Money value={value} currency={currency}>
              {({ symbol, big, small }) => (
                <>
                  <Heading variant="sans" weight="bold" size="h4">
                    {symbol} {big}
                    {small}
                  </Heading>
                  <Link href={`/dashboard/explanation/${key}`} className={style.link}>
                    <Heading variant="light" size="h6" className={style.heading}>
                      <span className={style.label}>{displayLabel}</span>
                      <Icon
                        type="back"
                        stroke
                        color="darkPetrol"
                        className={style.icon}
                        style={{
                          position: 'relative',
                          top: '1px',
                          height: '10px',
                          strokeWidth: '6px',
                        }}
                      />
                    </Heading>
                  </Link>
                </>
              )}
            </Money>
          </div>
        );
      })}
    </>
  );
}

export default translate(ProjectedValues);
