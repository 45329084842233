import React from 'react';

function Duration({ ...props }) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <path
        d="M25,6.8C15,6.8,6.8,15,6.8,25C6.8,35,15,43.2,25,43.2S43.2,35,43.2,25C43.2,15,35,6.8,25,6.8z M25,41.2
		c-8.9,0-16.2-7.3-16.2-16.2S16.1,8.8,25,8.8S41.2,16.1,41.2,25S33.9,41.2,25,41.2z"
      />
      <path
        d="M31.5,13.7c-0.5-0.3-1.1-0.1-1.4,0.4l-6,10.9c-0.2,0.4-0.1,0.9,0.2,1.2c0,0,0,0,0.1,0.1l5.8,5.8c0.2,0.2,0.5,0.3,0.7,0.3
		s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-5.3-5.3l5.6-10.2C32.1,14.6,32,14,31.5,13.7z"
      />
    </svg>
  );
}

export default Duration;
