import React from 'react';
import { connect } from '../containers/storeContext';
import { translate } from '../containers/translationContext';
import { actions } from '../store';
import Header from '../components/header';
import Heading from '../components/heading';
import Payments from '../components/payments';
import Data from '../containers/data';
import Money from '../components/money';
import InvalidPayment from '../components/payments/invalidPayment';
import ContentGrid from '../components/contentGrid/index';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Paragraph from '../components/paragraph';
import ToolTip from '../components/form/tooltip/index';
import formatMoney from '../utils/formatMoney';
import Placeholder from '../components/placeholder';

function PaymentsOverview({ t, activePolicy }) {
  return (
    <>
      <NarrowColumn>
        <Header left="menu" />
        <Heading color="white" size="h4">
          {t('policy.payments')}
        </Heading>
      </NarrowColumn>
      <Data
        keys={[`payments:${activePolicy.contractId}`]}
        renderErrors={e => (
          <NarrowColumn>
            <Paragraph color="white" size="small">
              {e[0]}
            </Paragraph>
          </NarrowColumn>
        )}
      >
        {([payments, yearWiseTotalAmount]) => {
          return (
            <>
              {payments.invalidPayment && (
                <ContentGrid>
                  {({ getFullContentProps }) => (
                    <div {...getFullContentProps()}>
                      <InvalidPayment />
                    </div>
                  )}
                </ContentGrid>
              )}
              <NarrowColumn>
                <Heading
                  color="white"
                  variant="sans"
                  weight="bold"
                  size="h3"
                  style={{ marginBottom: '10px', position: 'relative' }}
                >
                  <Money value={payments.totalAmount} currency={payments.currency}>
                    {({ symbol }) => (
                      <>
                        {formatMoney(payments.totalAmount, payments.currency).v} {symbol}
                      </>
                    )}
                  </Money>
                  <ToolTip white="true" inline="true" html="true">
                    {t('policy.payments.disclaimer')}
                  </ToolTip>
                </Heading>
                <Heading variant="sans" size="h6" color="white">
                  {t('policy.payments.total')}
                </Heading>
                <Data
                  keys={[`translateScreen:${activePolicy.contractId}:${'mypayments'}`]}
                  fallback={<Placeholder color="white" />}
                >
                  {screenTranslations => (
                    <Payments yearly={payments.yearly} screenTranslations={screenTranslations[0]} />
                  )}
                </Data>
              </NarrowColumn>
            </>
          );
        }}
      </Data>
    </>
  );
}

export default connect('activePolicy', actions)(translate(PaymentsOverview));
