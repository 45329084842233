import React from 'react';
import cc from 'classcat';
import style from './style.module.scss';
import { oneOf } from 'prop-types';

Hybrid.propTypes = {
  color: oneOf(['darkPetrol', 'purple', 'petrol', 'yellow', 'mint', 'white']),
  gender: oneOf(['M', 'F', 'logo']),
};

Hybrid.defaultProps = {
  color: 'white',
  gender: 'F',
};

const HYBRIDS = {
  FdarkPetrol: require('../../../assets/hybrid/F/darkPetrol.png'),
  Fmint: require('../../../assets/hybrid/F/mint.png'),
  Fpetrol: require('../../../assets/hybrid/F/petrol.png'),
  Fpurple: require('../../../assets/hybrid/F/purple.png'),
  Fwhite: require('../../../assets/hybrid/F/white.png'),
  Fyellow: require('../../../assets/hybrid/F/yellow.png'),
  MdarkPetrol: require('../../../assets/hybrid/M/darkPetrol.png'),
  Mmint: require('../../../assets/hybrid/M/mint.png'),
  Mpetrol: require('../../../assets/hybrid/M/petrol.png'),
  Mpurple: require('../../../assets/hybrid/M/purple.png'),
  Mwhite: require('../../../assets/hybrid/M/white.png'),
  Myellow: require('../../../assets/hybrid/M/yellow.png'),
  anonymous: require('../../../assets/hybrid/anonymous.png'),
  logowhite: require('../../../assets/hybrid/logowhite.svg'),
};

function Hybrid({ innerRef, className, color, gender, ...rest }) {
  const src = HYBRIDS[`${gender}${color}`] || HYBRIDS.anonymous;
  const imgStyle = gender === 'logo' ? style.fwuLogo : style.hybrid;

  return (
    <div className={cc([style.wrapper, className])} {...rest}>
      <img className={imgStyle} src={gender === 'logo' ? src.default : src} alt="" ref={innerRef} />
    </div>
  );
}

export default Hybrid;
