import React, { Component } from 'react';
import routeContext from '../containers/routeContext';
import AccountDeleteConfirmForm from '../components/form/forms/deleteAccountConfirm';
import Heading from '../components/heading';
import { actions } from '../store';
import Hybrid from '../components/image/hybrid';
import { translate } from '../containers/translationContext';
import Header from '../components/header';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Paragraph from '../components/paragraph';
import { navigate } from '@reach/router';
import { connect } from '../containers/storeContext';
import ReplaceTransition from '../components/transition/replace';
import InPageTransition from '../components/transition/inPage';

class DeleteConfirm extends Component {
  static contextType = routeContext;

  state = {
    completed: false,
  };

  displayComplete = response => {
    if (response.success) {
      // Transition to success
      this.setState({ completed: true });

      setTimeout(() => {
        // Clear the user & redirect to home
        this.props.clearUser();
        navigate('/');
      }, 3000);
    }
  };

  render() {
    const { t } = this.props;
    const { deleteToken } = this.context;

    return (
      <NarrowColumn>
        <Header />
        <ReplaceTransition in={!this.state.completed}>
          <InPageTransition leave="left">
            <AccountDeleteConfirmForm
              onSuccess={this.displayComplete}
              deleteUserToken={deleteToken}
            />
          </InPageTransition>
          <InPageTransition enter="right">
            <Hybrid />
            <Heading>{t('deleteConfirm.completeHeading')}</Heading>
            <Paragraph>{t('deleteConfirm.completeBody')}</Paragraph>
          </InPageTransition>
        </ReplaceTransition>
      </NarrowColumn>
    );
  }
}

export default connect('activePolicy', actions)(translate(DeleteConfirm));
