import React from 'react';
import { translate } from '../containers/translationContext';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import PolicyDetailsHolderForm from '../components/form/forms/policyDetailsHolder';

function PolicyDetailsHolder({ t }) {
  return (
    <NarrowColumn>
      <PolicyDetailsHolderForm />
    </NarrowColumn>
  );
}

export default translate(PolicyDetailsHolder);
