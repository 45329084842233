import cc from 'classcat';
import Heading from '../../heading';
import Icon from '../../image/icon';
import React from 'react';
import style from './style.module.scss';

function MenuLink({ next, onClick, children }) {
  return (
    <Heading
      className={cc([style.link, next && style.next])}
      component="button"
      type="button"
      variant="sans"
      weight="light"
      size="h6"
      color="white"
      onClick={onClick}
    >
      {children}
    </Heading>
  );
}

function DashboardNavigation({ nextLabel, onNext, prevLabel, onPrev }) {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        {nextLabel ? (
          <MenuLink next onClick={onNext}>
            <Icon type="more" color="white" />
            <span>{nextLabel}</span>
          </MenuLink>
        ) : (
          <span />
        )}
        {prevLabel ? (
          <MenuLink onClick={onPrev}>
            <span>{prevLabel}</span>
            <Icon type="more" color="white" />
          </MenuLink>
        ) : (
          <span />
        )}
      </div>
    </div>
  );
}

export default DashboardNavigation;
