import React from 'react';
import { connect } from '../../containers/storeContext';
import { bool, number, string } from 'prop-types';
import cc from 'classcat';
import Paragraph from '../paragraph';
import CardNav from '../cardNav';
import Heading from '../heading';
import Time from '../time';
import style from './style.module.scss';
import { translate } from '../../containers/translationContext';
import Icon from '../image/icon/index';
import MessageIcon from './messageIcon';

Message.propTypes = {
  text: string.isRequired,
  title: string.isRequired,
  read: bool.isRequired,
  date: number.isRequired,
};

function Message({
  messageId,
  type,
  title,
  date,
  read,
  contractId,
  contractLabel,
  documentId,
  errorType,
  t,
  authToken,
}) {
  return (
    <CardNav.Card
      href={getMessageHref(messageId, type, contractId, documentId, authToken)}
      className={cc([style.card, style[type], !read && style.unread])}
      dontTransition={type === 'download' || type === 'Policy document'}
      external={type === 'download' || type === 'Policy document'}
    >
      <Paragraph size="xSmall" color="mediumGray" className={style.paragraph}>
        <Time timestamp={date} year month="short" day />
      </Paragraph>
      <Heading component="h3" variant="sans" size="h6" className={style.title}>
        <MessageIcon type={type} read={read} /> {title}
      </Heading>
      <Paragraph size="xSmall" className={style.paragraph}>
        {contractLabel}
      </Paragraph>
      <Paragraph size="xSmall" className={style.contractId}>
        {t('policy.detail.general.policyNumber')}: {contractId}
      </Paragraph>
      <MessageLink type={type} errorType={errorType} t={t} />
    </CardNav.Card>
  );
}

function MessageLink({ type, t, errorType, documentId }) {
  switch (type) {
    case 'download':
    case 'Policy document':
      return (
        <div className={style.link}>
          {t('messages.download')} <Icon type="more" color="darkPetrol" />
        </div>
      );
    default:
      return (
        <div className={style.link}>
          {t('read')} <Icon type="more" color="darkPetrol" className={style.arrow} />
        </div>
      );
  }
}

function getMessageHref(messageId, type, contractId, documentId, authToken) {
  switch (type) {
    case 'download':
    case 'Policy document':
      return `/document?documentId=${documentId}&contractId=${contractId}&jwt=${authToken}`;
    case 'error':
    default:
      return `/messages/${messageId}`;
  }
}

export default connect('authToken,activePolicy')(translate(Message));
