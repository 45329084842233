import React from 'react';
import { translate } from '../containers/translationContext';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import PolicyDetailsGeneralForm from '../components/form/forms/policyDetailsGeneral';

function PolicyDetailsGeneral({ t }) {
  return (
    <NarrowColumn>
      <PolicyDetailsGeneralForm />
    </NarrowColumn>
  );
}

export default translate(PolicyDetailsGeneral);
