import React from 'react';
import { bool, func, node, oneOf, oneOfType, string } from 'prop-types';
import cc from 'classcat';
import style from './style.module.scss';

Heading.propTypes = {
  variant: oneOf(['cursive', 'sans', 'light']),
  size: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  weight: oneOf(['bold', 'regular', 'light']),
  // cut: oneOf(['normal', 'italic']),
  color: oneOf(['petrol', 'darkPetrol', 'white', 'black', 'transparent', 'mediumGray']),
  align: oneOf(['left', 'center', 'right']),
  children: node.isRequired,
  component: oneOfType([string, func]),
  className: string,
  transparent: bool,
  innerRef: func,
};

function Heading({
  variant = 'cursive',
  size = 'h4',
  weight = 'regular',
  // cut = 'normal',
  align = 'left',
  color = 'darkPetrol',
  component: Comp = 'h1',
  children,
  transparent,
  className = '',
  innerRef,
  ieFlexGrow,
  ...rest
}) {
  const classNames = cc([
    style.heading,
    style[variant],
    style[size],
    style[align],
    style[color],
    style[weight],
    transparent && style.transparent,
    className,
    ieFlexGrow && style.ieFlexGrow,
  ]);

  return (
    <Comp className={classNames} ref={innerRef} {...rest}>
      {children}
    </Comp>
  );
}

export default Heading;
