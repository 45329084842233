import React, { useContext } from 'react';
import cc from 'classcat';
import { node, oneOf, oneOfType } from 'prop-types';
import routeContext from '../../containers/routeContext';
import transitionContext from '../../containers/transitionContext';
import ResizeChecker from '../../containers/resizeChecker';
import Link from '../link';
import Logo from '../image/logo';
import Portal from '../portal';
import Menu from '../menu';
import ContentGrid from '../contentGrid';
import style from './style.module.scss';
import RegisterButton from '../button/register';
import YearSelector from '../yearSelector';

Header.propTypes = {
  left: oneOfType([node, oneOf(['logo', 'menu'])]),
  right: oneOfType([node, oneOf(['register', 'yearSelector'])]),
};

function Header({ left, right, dashboardPage }) {
  const { layer, isProtected } = useContext(routeContext);
  const transitionState = useContext(transitionContext);

  return (
    <Portal>
      <ResizeChecker breakpoints={['desktop']}>
        {widthState => (
          <ContentGrid
            className={cc([
              style.wrapper,
              isProtected && style.hasMenu,
              style[`layer${layer}`],
              style[transitionState],
            ])}
          >
            {({ getWideContentProps }) => (
              <header {...getWideContentProps({ className: style.header })}>
                <div className={style.left}>
                  {widthState > 0 && isProtected && left !== 'menu' && render('menu')}
                  {render(left, dashboardPage)}
                </div>
                <div className={style.right}>{render(right, dashboardPage)}</div>
              </header>
            )}
          </ContentGrid>
        )}
      </ResizeChecker>
    </Portal>
  );
}

function render(what, dashboardPage) {
  if (typeof what !== 'string') {
    return what;
  }

  switch (what) {
    case 'logo':
      return (
        <Link href="/">
          <Logo />
        </Link>
      );
    case 'logoImage':
      return <Logo />;
    case 'menu':
      return <Menu />;
    case 'register':
      return <RegisterButton />;
    case 'yearSelector':
      return <YearSelector dashboardPage={dashboardPage} />;
    default:
      return null;
  }
}

export default Header;
