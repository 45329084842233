import React, { Component } from 'react';
import { connect } from '../containers/storeContext';
import { actions } from '../store';
import Paragraph from '../components/paragraph/index';

class Data extends Component {
  static defaultProps = {
    fallback: null,
    onSuccess: () => {},
  };

  state = {
    errors: [],
  };

  mounted = true;

  componentDidMount() {
    this.getData();
    window.addEventListener('online', this.getData);
  }

  componentDidUpdate(prevProps) {
    const prevKeys = prevProps.keys;
    const prevLanguage = prevProps.language;
    const prevMarketId = prevProps.marketId;
    const { keys, language, marketId } = this.props;

    // TODO: potentially check all keys for a future use case
    // Compare the first key to determine whether the parameters have changed
    if (prevKeys[0] !== keys[0]) {
      // Get the data again since the parameters have changed
      this.getData();
    } else if (prevLanguage !== language || prevMarketId !== marketId) {
      const { invalidateData } = this.props;
      invalidateData(keys[0]);
      this.getData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.getData);
  }

  getData = () => {
    const { keys, getData, onSuccess } = this.props;

    this.setState({
      errors: [],
    });

    const promises = keys.map((key, i) => {
      const [dataKey, ...params] = key.split(':');
      return getData(dataKey, null, ...params).catch(e => {
        this.setState(prev => {
          const errors = [...prev.errors];
          errors[i] = e.message;
          return {
            errors,
          };
        });
      });
    });

    Promise.all(promises).then(() => onSuccess());
  };

  render() {
    const { keys, children, data, fallback, renderErrors } = this.props;
    const { errors } = this.state;

    const existingData = keys.map(key => data[key] && data[key].value);
    const hasAllData = existingData.every(value => value !== undefined);
    const hasErrors = errors.some(value => value !== undefined);

    if (hasErrors)
      return renderErrors
        ? renderErrors(errors)
        : errors.filter(Boolean).map((error, i) => (
            <Paragraph color="alert" size="small" key={`dataError${i}`}>
              {error}
            </Paragraph>
          ));

    if (!hasAllData) {
      return fallback;
    }

    const childFunc = typeof children === 'function';
    return childFunc ? children(existingData, errors) : children || null;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    marketId: state.marketId,
    language: state.language,
    data: state.data,
  };
}

export default connect(mapStateToProps, actions)(Data);
