import React from 'react';
import { translate } from '../../containers/translationContext';
import SubmitButton from '../../components/button/submit';
import Paragraph from '../../components/paragraph';

const Broker = ({ t, broker }) => {
  return (
    <>
      <Paragraph>
        {t('contact.agentDetail')}
        <br />
        {broker.brokerName.value}
      </Paragraph>

      <Paragraph>
        {broker.brokerStreet.value}
        <br />
        {broker.brokerCity.value} {broker.brokerPostalCode.value}
      </Paragraph>
      <Paragraph>
        {t('contact.phone')}:{' '}
        <a href={`tel:${broker.brokerPhone.value}`}>{broker.brokerPhone.value}</a>
      </Paragraph>
      <SubmitButton href={`tel:${broker.brokerPhone.value}`} external>
        {`${t('contact.call')} ${broker.brokerName.value}`}
      </SubmitButton>
    </>
  );
};

export default translate(Broker);
