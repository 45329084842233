import React from 'react';
import Link from '../../link';
import Icon from '../../image/icon';
import style from './style.module.scss';

function CloseButton({ href, onClick, innerRef, color, ...rest }) {
  if (href) {
    return (
      <Link
        tabIndex={1} // Button in the header have the highest priority for keyboard navigation
        href={href}
        onClick={onClick}
        className={style.button}
        ref={innerRef}
        {...rest}
      >
        <Icon type="close" color={color} className={style.icon} alt="back" />
      </Link>
    );
  }
  return (
    <button
      type="button"
      tabIndex={1}
      onClick={
        onClick ||
        (() => {
          window.history.go(-1);
        })
      }
      className={style.button}
      ref={innerRef}
      {...rest}
    >
      <Icon type="close" color={color} className={style.icon} alt="back" />
    </button>
  );
}

export default CloseButton;
