import React, { Component } from 'react';
import { connect } from '../../../containers/storeContext';
import { actions } from '../../../store';
import Form from '../index';
import { endpoints } from '../../../config/data';
import EmailUnit from '../inputUnits/email';
import PasswordUnit from '../inputUnits/password';
import ResizeChecker from '../../../containers/resizeChecker';
import TextLink from '../../textLink';
import SubmitButton from '../../button/submit';
import Paragraph from '../../paragraph';
import { translate } from '../../../containers/translationContext';
import { navigate } from '@reach/router';
import { preloadDashboard } from '../../../utils/routing';
import AnimationItem from '../../animation/item';
import SendError from '../sendError';
import ResendEmail from '../../button/resendEmail';
import style from './style.module.scss';

class LoginForm extends Component {
  state = {
    isValid: false,
    isLoadingData: false,
    invalidCombination: false,
    preloadError: false,
    resendLink: false,
  };

  handleSuccess = ({ data }) => {
    this.props.setUser(data.bearer, data.expiresAt, data.user);

    if (data.user.isAdminMasterLogin === 'Y') {
      sessionStorage.setItem('isAdminMasterLogin', data.user.isAdminMasterLogin);
    }

    this.setState({
      isLoadingData: true,
    });

    preloadDashboard()
      .then(() => {
        navigate('/', true);
      })
      .catch(e => {
        if (e.message === 'policy') {
          // Loggin in itself was succesful, but loading policy data failed.
          // Something might be wrong with the active policy. Redirect to policy overview.
          navigate('/policy/overview');
        }

        this.setState({
          preloadError: true,
          isLoadingData: false,
        });
      });
  };

  handleChange = () => {
    this.setState({
      isValid: this.email.isValid() && this.password.isValid(),
      invalidCombination: false,
    });
  };

  handleError = res => {
    if (res.reasonCode === '01') {
      this.setState({
        invalidCombination: true,
      });
    } else if (res.reasonCode === '03') {
      this.setState({
        resendLink: true,
      });
    } else {
      this.setState({
        resendLink: false,
      });
    }
  };

  renderError = t => (
    <Paragraph color="alert" size="small">
      {t}
    </Paragraph>
  );

  renderResendLink = email => (
    <Paragraph size="small">
      <ResendEmail email={email} />
    </Paragraph>
  );

  render() {
    const { t } = this.props;
    const { isValid, isLoadingData, invalidCombination, preloadError, resendLink } = this.state;

    return (
      <Form
        api={endpoints.login}
        onSuccess={this.handleSuccess}
        onError={this.handleError}
        onValueChange={this.handleChange}
      >
        {({ submit, isSending, sendError, ...inputProps }) => (
          <>
            <EmailUnit
              name="loginId"
              label={t('form.label.email')}
              ref={c => (this.email = c)}
              {...inputProps}
            />
            <PasswordUnit
              name="password"
              label={t('form.label.password')}
              ref={c => (this.password = c)}
              {...inputProps}
            />
            {invalidCombination && this.renderError(t('form.error.invalidCombination'))}
            {preloadError && this.renderError(t('form.error.technical'))}

            <AnimationItem type="fade">
              <TextLink className={style.textColor} href="/account/password/reset">
                {t('reset.link')}
              </TextLink>
            </AnimationItem>

            <SendError message={sendError} />

            {resendLink && this.renderResendLink(inputProps.values.loginId)}

            <ResizeChecker breakpoints={['desktop']}>
              {widthState => {
                const button = (
                  <SubmitButton
                    onClick={submit}
                    disabled={isSending || !isValid || isLoadingData}
                    innerRef={this.props.buttonRef}
                    noMenu
                  >
                    {isLoadingData ? t('form.button.loading') : t('form.button.login')}
                  </SubmitButton>
                );
                return widthState === 0 ? (
                  button
                ) : (
                  <AnimationItem type="slide">{button}</AnimationItem>
                );
              }}
            </ResizeChecker>
          </>
        )}
      </Form>
    );
  }
}

export default connect(null, actions)(translate(LoginForm));
