import React from 'react';
import { translate } from '../../../containers/translationContext';
import Form from '../index';
import Data from '../../../containers/data';
import { connect } from '../../../containers/storeContext';
import Placeholder from '../../placeholder';
import Heading from '../../heading';
import Header from '../../header';
import CloseButton from '../../button/close';
import InputGroup from '../inputGroup';
import { uiElements, prepareInitialFormValues } from '../../../utils/uiFormFunctions';

function PolicyDetailsInsuredPersonForm({ t, formatTime, activePolicy }) {
  return (
    <Data keys={[`policy:${activePolicy.contractId}`]} fallback={<Placeholder />}>
      {([policy]) => {
        const { insuredPersons } = policy;
        const initialValues = {
          ...(insuredPersons.length > 0
            ? prepareInitialFormValues(insuredPersons[0], formatTime)
            : null),
        };

        return (
          <Form initialValues={initialValues}>
            {({ submit, isSending, sendError, ...inputProps }) => (
              <>
                <Header right={<CloseButton href="/policy/details" color="darkPetrol" />} />
                <Heading size="h3">{t('policy.detail.insuredPerson')}</Heading>
                <InputGroup>
                  {uiElements(insuredPersons.length > 0 ? insuredPersons[0] : {}, inputProps)}
                </InputGroup>
              </>
            )}
          </Form>
        );
      }}
    </Data>
  );
}

export default connect('activePolicy')(translate(PolicyDetailsInsuredPersonForm));
