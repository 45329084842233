import React, { Component } from 'react';
import TextUnit from './text';
import translationContext from '../../../containers/translationContext';

class EmailUnit extends Component {
  static contextType = translationContext;
  isValid() {
    let value = this.props.values[this.props.name];

    if (this.props.optional && !value) return true;

    return this.comp.isValid();
  }
  render() {
    const { t } = this.context;
    return (
      <TextUnit
        {...this.props}
        type="email"
        pattern={/.+@.+\..+/}
        errorMessage={t('form.error.email')}
        ref={c => (this.comp = c)}
      />
    );
  }
}

export default EmailUnit;
