import React, { Component } from 'react';
import routeContext from '../containers/routeContext';
import ChangeEmailConfirm from '../components/form/forms/changeEmailConfirm';
import NarrowColumn from '../components/contentGrid/narrowColumn';

class AccountEmailConfirm extends Component {
  static contextType = routeContext;
  render() {
    const { code } = this.context;
    return (
      <NarrowColumn>
        <ChangeEmailConfirm code={code} />
      </NarrowColumn>
    );
  }
}

export default AccountEmailConfirm;
