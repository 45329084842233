import React, { Component } from 'react';
import { func, string, object } from 'prop-types';
import { request } from '../../utils/request';
import { navigate } from '@reach/router';
import { connect } from '../../containers/storeContext';
import { translate } from '../../containers/translationContext';

/**
 * Form component that handles sending input to the backend.
 * Requires a function as a child, which will get called with a callback function to submit the form
 * and response and send information.
 */
class Form extends Component {
  static propTypes = {
    children: func.isRequired, // (submit, response, isSending, sendError) => void
    url: string,
    api: func,
    onSubmit: func,
    onError: func,
    onSuccess: func,
    onValueChange: func,
    onErrorChange: func,
    method: string,
    initialValues: object,
    extraParams: object,
    autoComplete: string,
  };

  static defaultProps = {
    onSuccess: noop,
    onError: noop,
    onValueChange: noop,
    onErrorChange: noop,
    method: 'post',
    autoComplete: 'on',
  };

  state = {
    errors: {},
    values: {
      ...this.props.initialValues,
    },
    isSending: false,
    sendError: false,
    isPrefil: true,
  };

  componentDidUpdate() {
    if (this.props.initialValues !== undefined) {
      if (this.state.isPrefil && this.props.initialValues.isPrefil) {
        let tempValue = {
          language: this.props.initialValues.language.toLowerCase(),
          marketId: this.props.initialValues.marketId,
          policyId: this.props.initialValues.policyId,
          taxId: this.props.initialValues.taxId,
        };
        this.setState({ values: tempValue, isPrefil: false });
      }
    }
  }

  handleSubmit = e => {
    // accidental submit?
    if (e) e.preventDefault();
  };

  handleCallbacks = response => {
    const { redirect, onSuccess, onError } = this.props;
    if (response.success) {
      if (redirect) navigate(redirect, true);
      onSuccess(response, this.state.values);
    } else {
      onError(response, this.state.values);
    }
  };

  submit = e => {
    if (e) e.preventDefault();

    const { url, method, api, authToken, extraParams, t } = this.props;
    const body = { ...this.state.values, ...extraParams };

    this.setState({ isSending: true });

    (api ? api(authToken, body) : request(url, body, method, authToken))
      .then(response => {
        const responseErrors = {};
        if (response.errors && response.errors.length > 0) {
          for (let i = 0; i < response.errors.length; i++) {
            responseErrors[response.errors[i].param] = response.errors[i].msg;
          }
        }

        this.setState(
          state => ({
            isSending: false,
            sendError: response.success ? null : response.message,
            errors: this.mergeErrors(state, responseErrors, true),
          }),
          () => this.handleCallbacks(response)
        );

        return true;
      })
      .catch(e => {
        let sendError;
        if (navigator.onLine) {
          sendError = t('form.error.send');
        } else {
          sendError = t('form.error.offline');
        }
        this.setState({ isSending: false, sendError }, () =>
          this.props.onError(e, this.state.values)
        );
      });
  };

  mergeErrors = (state, errors, replace) => {
    if (replace) return errors || {};

    return {
      ...state.errors,
      ...errors,
    };
  };

  setError = (key, error) => {
    this.setState(
      state => ({
        errors: this.mergeErrors(state, { [key]: error }),
      }),
      this.props.onErrorChange
    );
  };

  setValueState = (updateState, callback) => {
    this.setState(
      state => ({
        values: {
          ...state.values,
          ...updateState,
        },
      }),
      () => {
        if (callback) {
          callback();
        }
        this.props.onValueChange();
      }
    );
  };

  render() {
    const { autoComplete } = this.props;
    const { isSending, sendError, errors, values } = this.state;
    const { submit, setError, setValueState } = this;

    return (
      <form onSubmit={this.handleSubmit} autoComplete={autoComplete}>
        {this.props.children({
          submit,
          isSending,
          sendError,
          errors,
          setError,
          setValueState,
          values,
        })}
      </form>
    );
  }
}

function noop() {}

function mapStateToProps(state, ownProps) {
  return {
    authToken: state.authToken,
  };
}

export default connect(mapStateToProps)(translate(Form));
