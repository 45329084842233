import React from 'react';
import { T } from '../../containers/translationContext';
import Heading from '../heading';
import Card from './card';

function AddPolicy({ index }) {
  return (
    <Card type="addPolicy" href="/policy/connect">
      <Heading variant="sans" size="h5">
        {index === 0 ? <T id="policy.overview.connect" /> : <T id="policy.overview.add" />}
      </Heading>
    </Card>
  );
}

export default AddPolicy;
