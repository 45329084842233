import React from 'react';
import cc from 'classcat';
import style from './style.module.scss';

function Logo({ fullWidth, ...props }) {
  return (
    <svg
      viewBox="0 0 155 100"
      className={cc([style.logo, fullWidth && style.fullWidth])}
      {...props}
    >
      <path
        className={style.black}
        d="M30.228,13.702H2.449c-0.827,0-1.499,0.673-1.499,1.499v43.791c0,0.827,0.673,1.501,1.499,1.501h2.578 c0.799,0,1.499-0.701,1.499-1.501V41.177h20.296c0.799,0,1.499-0.702,1.499-1.501V37.76c0-0.799-0.7-1.5-1.499-1.5H6.526V18.619 h23.701c0.827,0,1.5-0.673,1.5-1.499v-1.919C31.728,14.375,31.055,13.702,30.228,13.702z"
      />
      <path
        className={style.black}
        d="M94.299,14.25c-0.267-0.354-0.707-0.549-1.239-0.549h-2.779c-0.666,0-1.274,0.571-1.426,1.108l-8.905,33.915 L69.005,14.595c-0.173-0.518-0.743-0.894-1.355-0.894h-1.587c-0.642,0-1.175,0.351-1.356,0.898L53.971,48.723l-9.103-33.919 c-0.152-0.532-0.76-1.103-1.423-1.103h-2.78c-0.532,0-0.971,0.195-1.237,0.548s-0.333,0.832-0.186,1.349l12.318,43.796 c0.159,0.633,0.772,1.11,1.428,1.11h1.439c0.526,0,1.192-0.377,1.355-0.898l10.982-34.929l11.19,34.945 c0.193,0.518,0.816,0.862,1.342,0.862h1.448c0.656,0,1.268-0.478,1.423-1.103l12.316-43.782 C94.631,15.082,94.566,14.604,94.299,14.25z"
      />
      <path
        className={style.petrol}
        d="M133.141,55.807c-0.252,24.887,8.422,32.572,15.314,33.713c5.511,0.913,7.339-1.648,4.996-2.406 c-5.564-1.801-8.852-7.396-10.073-17.5c-0.629-5.205-1.14-10.831-0.64-18.524c0.95-14.612,3.998-28.819,4.766-32.333 c1.267-5.803-0.422-8.005-2.953-8.23c-1.079-0.096-3.583,0.186-5.149,3.218c-1.701,3.292-4.153,16.279-6.428,25.467 c-0.673,2.717-1.418,5.52-2.456,8.411c-4.539,12.637-11.989,25.524-15.645,25.524c-3.567,0-3.716-8.655-3.726-17.966 c-0.027-25.416,5.938-39.412,8.26-47.371c0.793-2.72,0.681-5.64-2.436-5.799c-2.411-0.123-4.384,0.901-6.42,4.392 c-2.01,3.447-3.379,7.176-3.968,9.191c-3.599,12.315-6.171,23.753-6.171,39.587c0,18.075,2.847,28.481,10.517,28.481 C121.4,83.661,130.292,64.463,133.141,55.807z"
      />
      <g className={style.forward}>
        <path d="M7.451,69.886H1.439c-0.27,0-0.49,0.22-0.49,0.49v10.098c0,0.27,0.22,0.49,0.49,0.49h0.595c0.261,0,0.49-0.229,0.49-0.49 v-3.962h4.176c0.263,0,0.493-0.229,0.493-0.49v-0.444c0-0.261-0.23-0.49-0.493-0.49H2.525V71.31h4.926c0.27,0,0.49-0.22,0.49-0.49 v-0.444C7.941,70.106,7.721,69.886,7.451,69.886z" />
        <path d="M17.017,69.735c-3.131,0-5.678,2.56-5.678,5.707c0,3.183,2.494,5.676,5.678,5.676c3.191,0,5.691-2.493,5.691-5.676 C22.708,72.295,20.155,69.735,17.017,69.735z M21.239,75.442c0,2.32-1.894,4.206-4.222,4.206c-2.319,0-4.206-1.887-4.206-4.206 c0-2.338,1.887-4.239,4.206-4.239C19.345,71.203,21.239,73.104,21.239,75.442z" />
        <path d="M34.941,73.337c0-1.903-1.582-3.451-3.526-3.451h-3.95c-0.271,0-0.491,0.22-0.491,0.49v10.098c0,0.27,0.22,0.49,0.491,0.49 h0.594c0.266,0,0.491-0.225,0.491-0.49v-3.688h2.589l1.927,3.838c0.083,0.169,0.209,0.341,0.514,0.341h0.734 c0.186,0,0.343-0.085,0.431-0.232c0.089-0.151,0.09-0.346,0.003-0.508l-1.985-3.679C34.089,75.928,34.941,74.677,34.941,73.337z M33.334,73.351c0,1.117-0.895,2.025-1.996,2.025h-2.759v-3.975h2.759C32.401,71.401,33.334,72.312,33.334,73.351z" />
        <path d="M51.525,69.886h-0.64c-0.208,0-0.414,0.159-0.467,0.362l-2.073,7.346l-2.417-7.553c-0.058-0.181-0.244-0.307-0.452-0.307 h-0.273c-0.213,0-0.391,0.12-0.451,0.307l-2.373,7.546l-1.971-7.342c-0.057-0.202-0.263-0.36-0.467-0.36h-0.494 c-0.169,0-0.312,0.065-0.402,0.184c-0.089,0.117-0.112,0.273-0.066,0.437l2.734,10.245c0.055,0.212,0.253,0.366,0.47,0.366h0.244 c0.184,0,0.36-0.117,0.452-0.309l2.44-7.766l2.496,7.796c0.08,0.165,0.261,0.279,0.44,0.279h0.242c0.216,0,0.417-0.156,0.469-0.36 l3.028-10.25c0.047-0.165,0.024-0.321-0.065-0.438C51.837,69.951,51.694,69.886,51.525,69.886z" />
        <path d="M59.629,70.015l-0.001-0.003c-0.088-0.181-0.244-0.28-0.437-0.28h-0.153c-0.112,0-0.318,0.036-0.444,0.285l-4.637,10.266 c-0.072,0.161-0.063,0.33,0.025,0.464c0.088,0.139,0.241,0.218,0.418,0.218h0.61c0.225,0,0.389-0.15,0.444-0.287l1.062-2.399h5.153 l1.071,2.393c0.06,0.146,0.22,0.294,0.446,0.294h0.611c0.179,0,0.332-0.079,0.421-0.219c0.086-0.136,0.094-0.305,0.023-0.462 L59.629,70.015z M61.076,76.934H57.12l1.978-4.447c0.34,0.758,0.677,1.517,1.012,2.279L61.076,76.934z" />
        <path d="M76.086,73.337c0-1.903-1.582-3.451-3.527-3.451h-3.95c-0.271,0-0.492,0.22-0.492,0.49v10.098 c0,0.27,0.221,0.49,0.492,0.49h0.594c0.266,0,0.491-0.225,0.491-0.49v-3.688h2.589l1.927,3.838c0.083,0.168,0.21,0.341,0.517,0.341 h0.732c0.185,0,0.343-0.084,0.432-0.232c0.089-0.15,0.09-0.344,0.002-0.508l-1.985-3.679 C75.234,75.928,76.086,74.677,76.086,73.337z M74.48,73.351c0,1.117-0.896,2.025-1.998,2.025h-2.757v-3.975h2.757 C73.547,71.401,74.48,72.312,74.48,73.351z" />
        <path d="M84.596,69.886h-3.418c-0.258,0-0.476,0.225-0.476,0.49v10.098c0,0.266,0.218,0.49,0.476,0.49h3.418 c3.062,0,5.554-2.492,5.554-5.554C90.15,72.364,87.658,69.886,84.596,69.886z M88.514,75.41c0,2.414-1.668,4.101-4.056,4.101 h-2.193v-8.185h2.193C86.847,71.326,88.514,73.006,88.514,75.41z" />
      </g>
    </svg>
  );
}

export default Logo;
