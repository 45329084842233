import React from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import TechnicalPaymentData from '../components/payments/technicalPaymentData';

function DetailTechnicalPaymentData() {
  return (
    <NarrowColumn>
      <TechnicalPaymentData />
    </NarrowColumn>
  );
}

export default DetailTechnicalPaymentData;
