import React from 'react';
import Header from '../components/header';
import Data from '../containers/data';
import BackButton from '../components/button/back';
import Explanation from '../components/explanation';
import Placeholder from '../components/placeholder';
import { translate } from '../containers/translationContext';
import { connect } from '../containers/storeContext';

function DetailRiskType({ t, activePolicy }) {
  return (
    <>
      <Header left={<BackButton color="darkPetrol" />} />
      <Data keys={[`policy:${activePolicy.contractId}`]} fallback={<Placeholder />}>
        {([policy]) => (
          <Data keys={[`explanation:risk-type`]} fallback={<Placeholder />}>
            {([explanation]) => {
              if (!explanation || !explanation.title || !explanation.sections)
                return <span>Explanation not found</span>;

              const button = [
                {
                  type: 'link',
                  value: t('form.button.contactAgent'),
                  href: '/service/contact',
                },
              ];

              const sections = explanation.sections.concat(button);

              return <Explanation title={explanation.title} sections={sections} policy={policy} />;
            }}
          </Data>
        )}
      </Data>
    </>
  );
}

export default connect('activePolicy')(translate(DetailRiskType));
