import React from 'react';
import Header from '../components/header';
import BackButton from '../components/button/back';
import { translate } from '../containers/translationContext';
import { connect } from '../containers/storeContext';
import Data from '../containers/data';
import Explanation from '../components/explanation';
import Placeholder from '../components/placeholder';

const PaymentsPayPremium = ({ activePolicy }) => {
  return (
    <>
      <Header left={<BackButton color="darkPetrol" />} />

      <Data
        keys={[`explanation:unpaid-premium:${activePolicy.contractId}`]}
        fallback={<Placeholder />}
      >
        {([explanation]) => {
          if (!explanation || !explanation.title || !explanation.sections) {
            return <span>Explanation not found</span>;
          }

          return <Explanation title={explanation.title} sections={explanation.sections} />;
        }}
      </Data>
    </>
  );
};

function mapStateToProps(state) {
  return {
    activePolicy: state.activePolicy,
  };
}

export default connect(mapStateToProps)(translate(PaymentsPayPremium));
