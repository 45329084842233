import { createContext, createElement, useContext, useEffect, useState } from 'react';
import { mapActions, select } from 'unistore/src/util';

const storeContext = createContext({});
const { Provider, Consumer } = storeContext;

/** Wire a component up to the store. Passes state as props, re-renders on change.
 *  @param {Function|Array|String} mapStateToProps  A function mapping of store state to prop values, or an array/CSV of properties to map.
 *  @param {Function|Object} [actions] 				Action functions (pure state mappings), or a factory returning them. Every action function gets current state as the first parameter and any other params next
 *  @returns {Component} ConnectedComponent
 *  @example
 *    const actions = { someAction }
 *    const Foo = connect('foo,bar', actions)( ({ foo, bar, someAction }) => <div /> )
 */
function connect(mapStateToProps, actions) {
  if (typeof mapStateToProps !== 'function') {
    // eslint-disable-next-line
    mapStateToProps = select(mapStateToProps || []);
  }
  return Child =>
    function Wrapper(props, context) {
      const store = useContext(storeContext);
      const [state, setState] = useState(mapStateToProps(store ? store.getState() : {}, props));
      const boundActions = actions ? mapActions(actions, store) : { store };

      function update() {
        let mapped = mapStateToProps(store ? store.getState() : {}, props);
        for (let i in mapped)
          if (mapped[i] !== state[i]) {
            return setState(mapped);
          }
        for (let i in state)
          if (!(i in mapped)) {
            return setState(mapped);
          }

        return null;
      }

      useEffect(() => {
        store.subscribe(update);
        return () => store.unsubscribe(update);
      });

      return createElement(Child, { ...boundActions, ...props, ...state });
    };
}

export default storeContext;
export { Consumer as Store, Provider, connect };

// import {Provider, connect} from 'unistore/react'

// export {Provider, connect}
