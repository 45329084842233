import React from 'react';
import { oneOf } from 'prop-types';
import cc from 'classcat';
import Facebook from './facebook';
import Instagram from './instagram';
import LinkedIn from './linkedIn';
import style from './style.module.scss';

const icons = {
  facebook: Facebook,
  instagram: Instagram,
  linkedIn: LinkedIn,
};

Social.propTypes = {
  type: oneOf(Object.keys(icons)).isRequired,
};

function Social(type, url, ...rest) {
  const IconComp = icons[type];

  return (
    <div>
      <IconComp
        onClick={() => {
          window.open(url, '_blank').focus();
        }}
        className={cc([
          style.icon,
          //color && style[color],
          //hasInput && style.hasInput,
          //hasError && style.hasError,
          //className,
        ])}
        {...rest[0]}
      />
    </div>
  );
}

export default Social;
