import React from 'react';
import RegisterForm from '../components/form/forms/register';
import NarrowColumn from '../components/contentGrid/narrowColumn';

function Register() {
  return (
    <NarrowColumn>
      <RegisterForm />
    </NarrowColumn>
  );
}

export default Register;
