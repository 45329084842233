import React from 'react';
import ReactDOM from 'react-dom';
import { addDeviceClasses } from 'dpdk-devicedetection';
import addFocusClasses from './utils/addFocusClasses';
import './style/global.module.scss';
import Root from './components/root';
import * as serviceWorker from './utils/serviceWorker';
import { respondToConnectionChange } from './utils/connection';
import { loaded } from './store';
import { testCSSProp } from './utils/dom';

addDeviceClasses(document.documentElement);
addFocusClasses(document.documentElement);
respondToConnectionChange();

if (testCSSProp('grid-template-rows', 'none')) {
  document.documentElement.classList.add('supports-grid');
} else {
  document.documentElement.classList.add('no-grid');
}

function render() {
  // ReactDOM.createRoot(document.getElementById('root')).render(<Root />);
  ReactDOM.render(<Root />, document.getElementById('root'));
}

// Render after the store is initialized
loaded.then(render);

serviceWorker.init();
