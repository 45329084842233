import React from 'react';
import cc from 'classcat';
import style from './style.module.scss';

function InputLabel({ children, /* htmlFor, */ hasInput, hasIcon, moves }) {
  // Not using <label> here because this is already wrapped in a label
  return (
    <span
      // htmlFor={htmlFor}
      className={cc([
        style.label,
        hasIcon && style.hasIcon,
        hasInput && style.hasInput,
        moves && style.moves,
      ])}
    >
      {children}
    </span>
  );
}

export default InputLabel;
