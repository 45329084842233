import React, { Component } from 'react';
import Form from '../index';
import Button from '../../button/submit';
import { endpoints } from '../../../config/data';
import PasswordUnit from '../inputUnits/password';
import Heading from '../../heading';
import { translate } from '../../../containers/translationContext';
import Paragraph from '../../paragraph';
import InputGroup from '../inputGroup';
import SendError from '../sendError';
import styles from './style.module.scss';

class AccountDeleteForm extends Component {
  state = {
    isValid: false,
    touched: true,
  };

  handleChange = () => {
    this.setState({
      isValid: this.password.isValid(),
      touched: true,
    });
  };

  render() {
    const { t, user } = this.props;
    return (
      <Form
        api={endpoints.delete}
        onSuccess={this.props.onSuccess}
        onValueChange={this.handleChange}
      >
        {({ submit, isSending, sendError, ...inputProps }) => (
          <>
            <Heading>{t('delete.heading')}</Heading>
            <Paragraph>{t('delete.body')}</Paragraph>
            <InputGroup heading={t('delete.passwordHeading')}>
              <PasswordUnit
                name="password"
                label={t('form.label.password')}
                ref={c => (this.password = c)}
                {...inputProps}
              />
            </InputGroup>

            {user.isAdmin ? (
              <div className={styles.adminMessage}>
                Admin is logged in - You cannot save changes!
              </div>
            ) : (
              <>
                <SendError message={sendError} />
                <Button
                  onClick={submit}
                  disabled={isSending || !this.state.isValid}
                  hidden={!this.state.touched}
                >
                  {t('form.button.delete')}
                </Button>
              </>
            )}
          </>
        )}
      </Form>
    );
  }
}

export default translate(AccountDeleteForm);
