import emitter from './emitter';

function showOfflineToast() {
  emitter.emit('toast', {
    messageId: 'app.offline',
  });
}

function respondToConnectionChange() {
  if (!navigator.onLine) {
    showOfflineToast();
  }

  window.addEventListener('offline', showOfflineToast);
  window.addEventListener('online', () => emitter.emit('removeToast', 'app.offline'));
}

export { respondToConnectionChange };
