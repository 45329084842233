import React from 'react';
import style from './style.module.scss';
import cc from 'classcat';

function Mail({ className, ...props }) {
  return (
    <svg viewBox="0 0 55 55" className={cc([className, style.stroke, style.small])} {...props}>
      <g>
        <path d="M46.7,47.6h-41c-2.4,0-4.3-2-4.3-4.3V14.5c0-2.4,2-4.3,4.3-4.3h41c2.4,0,4.3,2,4.3,4.3v28.8   C51,45.7,49,47.6,46.7,47.6z" />
        <line x1="1.3" y1="15.9" x2="26.2" y2="34.4" />
        <line x1="51" y1="15.9" x2="26.1" y2="34.4" />
      </g>
    </svg>
  );
}

export default Mail;
