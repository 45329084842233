import React, { Component } from 'react';
import { translate } from '../../../../containers/translationContext';
import Form from '../../index';
import SubmitButton from '../../../../components/button/submit';
import { endpoints } from '../../../../config/data';
import { connect } from '../../../../containers/storeContext';
import { actions } from '../../../../store';
import { navigate } from '@reach/router';
import Icon from '../../../image/icon';
import TextUnit from '../../inputUnits/text';
import Paragraph from '../../../paragraph';
import Heading from '../../../heading';
import Thumb from '../../../image/thumb';
import TextLink from '../../../textLink';
import SendError from '../../sendError';
import formatTime from '../../../../utils/formatTime';
import Placeholder from '../../../placeholder';
import Header from '../../../header';
import BackButton from '../../../button/back';
import CloseButton from '../../../button/close';

class ChangeOfAddress extends Component {
  eventName = 'UPDATE_POLICY_HOLDER_ADDRESS';

  step = {
    INPUT: 'Input',
    VERIFY: 'Verify',
    SUCCESS: 'Success',
  };
  inputRefs = [];
  state = {
    streetName: this.props.policyHolderDetails.streetName.value,
    streetNameTouched: false,
    houseNo: this.props.policyHolderDetails.houseNo.value,
    houseNoTouched: false,
    postalCode: this.props.policyHolderDetails.postalCode.value,
    postalCodeTouched: false,
    city: this.props.policyHolderDetails.city.value,
    cityTouched: false,

    province: this.props.policyHolderDetails.province.value,
    provinceTouched: false,

    district: '', //this.props.policyHolderDetails.district.value,
    districtTouched: false,

    touched: false,
    success: false,
    isValid: false,
    step: 'Input',
    lastCodeSent: null,
    debugToken: '',
  };

  componentDidMount = () => {
    /*
    const { getData, activePolicy } = this.props;

    var options = {
      onSuccess: data => {
        const paymentFrequencies = data.map(paymentFrequency => {
          return {
            key: `${paymentFrequency.id}`,
            label: `${paymentFrequency.description}`,
          };
        });

        const selectedPaymentFrequency = paymentFrequencies[0];
        this.setState({
          paymentFrequencies,
          selectedPaymentFrequency,
        });
      },
    };

    getData(`paymentFrequencies`, options, activePolicy.contractId);*/
  };

  handleChangeAddress = () => {
    this.setState({
      isValid: this.inputRefs.every(input => (input == null ? true : input.isValid())),
      success: false,
      touched: true,
    });
  };

  handleChangeVerify = () => {
    this.setState({
      isValid: this.inputRefs.every(input => (input == null ? true : input.isValid())),
      success: false,
      touched: true,
    });
  };

  renderInput = () => {
    const { t } = this.props;
    const {
      touched,
      isValid,
      streetName,
      houseNo,
      postalCode,
      city,
      province,
      district,
    } = this.state;

    return (
      <>
        <Header right={<CloseButton href="/alteration" color="darkPetrol" />} />
        <Heading size="h4">{t('alteration.policyHolderAddress')}</Heading>
        <Paragraph>{t('alteration.policyHolderAddress.description')}</Paragraph>
        <Form
          api={endpoints.generateSmsToken}
          onValueChange={() => {
            this.handleChangeAddress();
          }}
          onSuccess={response => {
            const update = () => {
              this.setState({
                step: this.step.VERIFY,
                isValid: false,
                touched: false,
                lastCodeSent: new Date(),
                debugToken: response.data.smsText || '',
              });

              this.inputRefs.length = 0;
            };

            update();
          }}
          initialValues={{
            eventName: this.eventName,
            streetName: streetName,
            houseNo: houseNo,
            postalCode: postalCode,
            province: province,
            district: district,
            city: city,
          }}
        >
          {({ submit, isSending, sendError, ...inputProps }) => {
            return (
              <>
                <TextUnit
                  name="streetName"
                  label={t('form.label.street')}
                  noIcon
                  ref={c => (this.inputRefs[0] = c)}
                  {...inputProps}
                />

                <TextUnit
                  name="houseNo"
                  label={t('form.label.houseNumber')}
                  noIcon
                  ref={c => (this.inputRefs[1] = c)}
                  {...inputProps}
                />

                <TextUnit
                  name="postalCode"
                  label={t('form.label.postalCode')}
                  noIcon
                  ref={c => (this.inputRefs[2] = c)}
                  {...inputProps}
                />

                <TextUnit
                  name="province"
                  label={t('form.label.province')}
                  noIcon
                  //ref={c => (this.inputRefs[4] = c)}
                  {...inputProps}
                />

                <TextUnit
                  name="city"
                  label={t('form.label.city')}
                  noIcon
                  ref={c => (this.inputRefs[3] = c)}
                  {...inputProps}
                />

                <TextUnit
                  name="district"
                  label={t('form.label.district')}
                  noIcon
                  //ref={c => (this.inputRefs[4] = c)}
                  {...inputProps}
                />

                {/*
                <TextUnit
                  name="province"
                  label={t('form.label.province')}
                  noIcon
                  ref={c => (this.inputRefs[4] = c)}
                  {...inputProps}
                />
                */}

                <SendError message={sendError} />

                <SubmitButton
                  onClick={() => {
                    this.setState({
                      ...inputProps.values,
                      isValid: false,
                    });
                    submit();
                  }}
                  disabled={isSending || !isValid}
                  hidden={!touched}
                >
                  {t('form.button.save')}
                </SubmitButton>
              </>
            );
          }}
        </Form>
      </>
    );
  };

  renderVerify = () => {
    const { t, activePolicy } = this.props;
    const { touched, success, isValid } = this.state;

    return (
      <>
        <Header
          left={
            <BackButton
              color="darkPetrol"
              onClick={() => {
                this.setState({
                  step: this.step.INPUT,
                  isValid: true,
                  touched: true,
                });
              }}
            />
          }
          right={<CloseButton href="/alteration" color="darkPetrol" />}
        />
        <Form
          autoComplete="off"
          api={endpoints.updateAddress}
          onValueChange={() => this.handleChangeVerify()}
          onSuccess={response => {
            const update = () => {
              this.inputRefs.length = 0;
              this.setState({
                step: this.step.SUCCESS,
                isValid: false,
                success: true,
              });

              setTimeout(() => {
                navigate('/alteration');
              }, 3000);
            };

            update();
          }}
          initialValues={{
            streetName: this.state.streetName,
            streetNameTouched: this.state.streetNameTouched,
            houseNo: this.state.houseNo,
            houseNoTouched: this.state.houseNoTouched,
            postalCode: this.state.postalCode,
            postalCodeTouched: this.state.postalCodeTouched,
            city: this.state.city,
            cityTouched: this.state.cityTouched,
            province: this.state.province,
            provinceTouched: this.state.provinceTouched,
            district: this.state.district,
            districtTouched: this.state.districtTouched,
            contractId: activePolicy.contractId,
          }}
        >
          {({ submit, isSending, sendError, ...inputProps }) => {
            return (
              <>
                <Heading size="h4">{t('general.sms.verifyHeader')}</Heading>
                <Paragraph>{t('general.sms.verifyBody')}</Paragraph>

                <br />

                <Paragraph>
                  {t('general.sms.requestTime')} {formatTime('en', this.state.lastCodeSent / 1000)}
                </Paragraph>

                <br />
                <TextUnit
                  name="smsToken"
                  label={t('general.sms.verifyCode')}
                  pattern={'^[0-9]{6}$'}
                  ref={c => (this.inputRefs[0] = c)}
                  {...inputProps}
                />

                {isSending ? <Placeholder /> : <SendError message={sendError} />}

                <br />
                <br />

                <SubmitButton
                  onClick={() => {
                    submit();
                  }}
                  disabled={isSending || !isValid}
                  hidden={!touched}
                >
                  {success ? (
                    <>
                      {t('form.button.saved')}
                      <Icon
                        type="check"
                        color="white"
                        style={{ marginTop: '-5px', marginLeft: '5px' }}
                      />
                    </>
                  ) : (
                    t('alteration.complete')
                  )}
                </SubmitButton>
              </>
            );
          }}
        </Form>

        <Form
          api={endpoints.generateSmsToken}
          onSuccess={response => {
            const update = () => {
              this.setState({
                step: this.step.VERIFY,
                isValid: false,
                lastCodeSent: new Date(),
                debugToken: response.data.smsText || '',
              });
            };
            update();
          }}
          initialValues={{
            eventName: this.eventName,
          }}
        >
          {({ submit, isSending, sendError }) => {
            return isSending ? (
              <Placeholder />
            ) : (
              <>
                <Paragraph>
                  {t('general.sms.resendToken')}{' '}
                  <TextLink onClick={submit}>{t('form.button.resend')}</TextLink>
                </Paragraph>
                {['localhost', 'test-cp.forwardyou.com', 'feature-cp.forwardyou.com'].includes(
                  window.location.hostname
                ) ? (
                  <Paragraph>
                    <span style={{ color: '#00879b' }}>
                      <span>
                        Debugging on FWU Test Systems. SMS Code: <b>{this.state.debugToken}</b>
                      </span>
                      <br />
                      <span style={{ fontSize: '0.75em' }}>(localhost, test-cp, feature-cp)</span>
                    </span>
                  </Paragraph>
                ) : (
                  ''
                )}
              </>
            );
          }}
        </Form>
      </>
    );
  };

  renderSuccess = () => {
    const { t } = this.props;

    return (
      <>
        <Header right={<CloseButton href="/alteration" color="darkPetrol" />} />
        <Heading size="h2" align="center">
          {t('alteration.complete')}
        </Heading>
        <Thumb />
        <Paragraph style={{ marginBottom: '40px', textAlign: 'center' }}>
          {t('general.thankYou')}
        </Paragraph>
      </>
    );
  };

  render() {
    /*
    const { paymentFrequencies } = this.state;

    if (paymentFrequencies.length === 0) {
      return (
        <>
          <Header right={<CloseButton href="/alteration" color="darkPetrol" />} />
          <Placeholder />
        </>
      );
    }*/

    switch (this.state.step) {
      case this.step.INPUT:
        return this.renderInput();
      case this.step.VERIFY:
        return this.renderVerify();
      case this.step.SUCCESS:
        return this.renderSuccess();
      default:
        return 'ERROR';
    }
  }
}

export default connect(null, actions)(translate(ChangeOfAddress));
