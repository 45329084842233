import React from 'react';
import { oneOf } from 'prop-types';

AnimationItem.propTypes = {
  type: oneOf(['slide', 'scale', 'fade']),
};

function AnimationItem({ type, children }) {
  return (
    <div style={{ position: 'relative' }} data-animation={type}>
      {children}
    </div>
  );
}

export default AnimationItem;
