import React from 'react';
import style from './style.module.scss';
import cc from 'classcat';

function Language({ className, ...props }) {
  return (
    <svg viewBox="0 0 55 55" className={cc([className, style.stroke])} {...props}>
      <path d="M49,25.7c0,11.7-10.7,21.2-23.9,21.2c-4,0-7.7-0.9-11-2.4C13,44,2.2,52.7,1.2,52.1C-0.1,51.2,8.4,41,7.3,39.9  c-1.1-1.1-2.1-2.3-2.9-3.5c-2.1-3.1-3.3-6.8-3.3-10.7C1.1,14,11.8,4.5,25,4.5S49,13.9,49,25.7z" />
    </svg>
  );
}

export default Language;
