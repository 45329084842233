import React from 'react';
import InputError from './inputError';

function SendError({ message }) {
  return message ? (
    <InputError hasIcon style={{ marginTop: '20px' }}>
      {message}
    </InputError>
  ) : null;
}

export default SendError;
