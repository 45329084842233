import { navigate } from '@reach/router';
import Button from '../../../components/button/submit';
import { endpoints } from '../../../config/data';
import Data from '../../../containers/data';
import { connect } from '../../../containers/storeContext';
import { translate } from '../../../containers/translationContext';
import React, { Component } from 'react';
import { actions } from '../../../store';
import Form from '..';
import Icon from '../../image/icon';
import Placeholder from '../../placeholder';
import InputGroup from '../inputGroup';
import Select from '../inputUnits/select';
import TextUnit from '../inputUnits/text';
import SendError from '../sendError';
import Paragraph from '../../paragraph';
import TextLink from '../../textLink';

class ConnectPolicy extends Component {
  state = {
    isValid: false,
    policyFieldsValid: false,
    selectedMarket: this.props.market,
    success: false,
    isLoadingData: false,
    endpoint: endpoints.policyActivate,
    showActivationKeyFields: false,
    chooseDeliveryMethod: false,
  };

  inputRefs = [];
  activationKeyInputRef = null; // Can either be the activation key
  methodInputRef = null; // Can either be the activation key
  reasonCode = null;

  componentDidMount() {
    // Remove all previous activationKey data
    // To start the flow with a clean slate
    Promise.all([this.props.invalidateDataOfPattern('policyActivationKey')]);
  }

  /**
   * Handle a change in any of the form inputs
   */
  handleChange = () => {
    const { chooseDeliveryMethod } = this.state;
    const policyFieldsValid = this.inputRefs.every(input => input.isValid && input.isValid());

    const activationKeyValid =
      (!chooseDeliveryMethod || (this.reasonCode !== null && this.reasonCode === '62')) &&
      this.activationKeyInputRef !== null &&
      this.activationKeyInputRef.isValid &&
      this.activationKeyInputRef.isValid();

    const methodValid =
      chooseDeliveryMethod &&
      this.reasonCode !== null &&
      (this.reasonCode === '60' || this.reasonCode === '62') &&
      this.methodInputRef !== null &&
      this.methodInputRef.isValid &&
      this.methodInputRef.isValid();

    // Complete form is only valid & submittable if:
    // Market field is valid
    // Policy fields (contractId & taxId) are valid
    // And either the activation key field OR choose method field is valid
    this.setState({
      policyFieldsValid,
      isValid: policyFieldsValid && (activationKeyValid || methodValid),
      endpoint: methodValid ? endpoints.policyActivationCodeSendMethod : endpoints.policyActivate,
    });
  };

  /**
   * Handle blurring the policy fields
   */
  handleBlur = () => {
    // Only show policy activation key fields
    // when the policyId & taxId fields are valid
    this.setState({
      showActivationKeyFields: this.state.policyFieldsValid,
    });
  };

  handleRequestClick = () => {
    // Force choosing a delivery method
    this.setState({
      chooseDeliveryMethod: true,
      isValid: false,
    });
  };

  /**
   * Handle a successful form submit
   */
  handleSuccess = () => {
    this.setState({ isLoadingData: true });

    // Get the user
    Promise.all([
      this.props.invalidateData('policies'),
      this.props.invalidateData('policy'),
      this.props.getData('policies'),
    ]).then(() => {
      this.setState({ isLoadingData: false, success: true });

      setTimeout(() => {
        navigate('/policy/overview');
      }, 1000);
    });
  };

  /**
   * Render the submit button
   * @param sendError
   * @param submit
   * @param isSending
   * @param isLoadingData
   * @param success
   * @param buttonCopy
   */
  renderSubmitButton = (sendError, submit, isSending, isLoadingData, success, buttonCopy) => {
    const { t } = this.props;
    const { chooseDeliveryMethod } = this.state;

    return (
      <>
        <SendError message={sendError} />
        <Button onClick={submit} disabled={isSending || !this.state.isValid || isLoadingData}>
          {success && chooseDeliveryMethod && this.reasonCode !== '62'
            ? t('policy.connect.keySent')
            : ''}
          {success ? <Icon type="check" color="white" /> : buttonCopy}
        </Button>
      </>
    );
  };

  /**
   * Render an error
   * @param message
   * @param error
   */
  renderMessage = (message, error) => {
    if (error) {
      return <SendError message={message} />;
    }

    return <Paragraph style={{ marginTop: '2rem' }}>{message}</Paragraph>;
  };

  /**
   * Render contractId & taxId (depends on market)
   * Render activation key input
   * Render link to request activation key
   * @param selectedMarket
   * @param inputProps
   * @param sendError
   * @param submit
   * @param isSending
   * @param isLoadingData
   * @param success
   * @returns {XML}
   */
  renderActivationFields = (
    selectedMarket,
    inputProps,
    sendError,
    submit,
    isSending,
    isLoadingData,
    success
  ) => {
    const { t } = this.props;
    const { chooseDeliveryMethod } = this.state;

    return (
      <InputGroup heading={t('policy.connect.activationHeading')}>
        {Object.values(selectedMarket.fields).map((field, i) => (
          <TextUnit
            icon="policy"
            name={field.name}
            label={field.label}
            ref={c => (this.inputRefs[i] = c)}
            {...inputProps}
            tooltip={field.toolTip}
            key={`field${i}`}
            onBlur={this.handleBlur}
          />
        ))}
        {!chooseDeliveryMethod &&
          this.renderActivationKeyInput(
            inputProps,
            t,
            sendError,
            submit,
            isSending,
            isLoadingData,
            success,
            false
          )}
        {chooseDeliveryMethod &&
          this.renderChooseMode(
            inputProps,
            t,
            sendError,
            submit,
            isSending,
            isLoadingData,
            success
          )}
      </InputGroup>
    );
  };

  /**
   * Render the input for activation key
   * @param inputProps
   * @param t
   * @param sendError
   * @param submit
   * @param isSending
   * @param isLoadingData
   * @param success
   * @param noRequestLink
   */
  renderActivationKeyInput = (
    inputProps,
    t,
    sendError,
    submit,
    isSending,
    isLoadingData,
    success,
    noRequestLink
  ) => (
    <>
      <TextUnit
        icon="activationKey"
        name="activationKey"
        label={t('policy.connect.activationKey')}
        ref={c => (this.activationKeyInputRef = c)}
        {...inputProps}
      />
      {!noRequestLink && (
        <TextLink onClick={this.handleRequestClick}>
          {t('policy.connect.invalidKey')} {t('form.button.resend')}
        </TextLink>
      )}
      {this.renderSubmitButton(
        sendError,
        submit,
        isSending,
        isLoadingData,
        success,
        t('form.button.activate')
      )}
    </>
  );

  /**
   * Render the input for selecting a delivery mode
   * @param inputProps
   * @param t
   * @param sendError
   * @param submit
   * @param isSending
   * @param isLoadingData
   * @param success
   */
  renderChooseMode = (inputProps, t, sendError, submit, isSending, isLoadingData, success) => {
    const { selectedMarket, showActivationKeyFields } = this.state;
    let policyActivationKeyParameters = false;
    const { values } = inputProps;

    // We can gather the values for the policy activation key
    if (showActivationKeyFields && selectedMarket) {
      const { fields } = selectedMarket;
      const fieldKeys = Object.keys(fields);

      policyActivationKeyParameters = {};
      fieldKeys.forEach(key => {
        if (values.hasOwnProperty(key)) {
          policyActivationKeyParameters[key] = values[key];
        }
      });

      // We need just as many parameters as we have fields
      if (Object.keys(policyActivationKeyParameters).length === fieldKeys.length) {
        policyActivationKeyParameters = encodeURIComponent(
          JSON.stringify(policyActivationKeyParameters)
        );
      } else {
        policyActivationKeyParameters = false;
      }
    }

    return (
      <>
        {policyActivationKeyParameters && (
          <Data
            keys={[
              `policyActivationKey:${policyActivationKeyParameters}:${selectedMarket.marketId}`,
            ]}
            fallback={<Placeholder />}
          >
            {([generateKeyResponse]) => {
              const generateReasonCode = generateKeyResponse.reasonCode;
              this.reasonCode = generateReasonCode;

              // Activation Key has been generated or user wants to resend the key
              // > choose delivery mode
              if (generateReasonCode === '60') {
                return this.renderDeliveryMethodField(
                  false,
                  t,
                  inputProps,
                  sendError,
                  submit,
                  isSending,
                  isLoadingData,
                  success
                );
              } else if (generateReasonCode === '61') {
                return this.renderMessage(generateKeyResponse.message, true);
              } else if (generateReasonCode === '62') {
                return this.renderDeliveryMethodField(
                  false,
                  t,
                  inputProps,
                  sendError,
                  submit,
                  isSending,
                  isLoadingData,
                  success
                );
              } else {
                return this.renderMessage(generateKeyResponse.message, true);
              }
            }}
          </Data>
        )}
        {!policyActivationKeyParameters &&
          this.renderDeliveryMethodField(
            true,
            t,
            inputProps,
            sendError,
            submit,
            isSending,
            isLoadingData,
            success
          )}
      </>
    );
  };

  /**
   * Render the delivery method field
   * @param disabled
   * @param t
   * @param inputProps
   * @param sendError
   * @param submit
   * @param isSending
   * @param isLoadingData
   * @param success
   */
  renderDeliveryMethodField = (
    disabled,
    t,
    inputProps,
    sendError,
    submit,
    isSending,
    isLoadingData,
    success
  ) => (
    <>
      {disabled && this.renderMessage(t('policy.connect.pleaseFillFields'), true)}
      {!disabled && (
        <InputGroup heading={t('policy.connect.requestHeading')} marginTop={40}>
          <Select
            name="deliveryMode"
            items={[
              {
                key: 'email',
                label: t('policy.connect.mode.email'),
              },
            ]}
            ref={c => (this.methodInputRef = c)}
            placeholder={t('policy.connect.mode.deliveryMode')}
            width="100%"
            {...inputProps}
          />
        </InputGroup>
      )}
      {this.renderSubmitButton(
        sendError,
        submit,
        isSending,
        isLoadingData,
        success,
        t('policy.connect.sendKey')
      )}
    </>
  );

  /**
   * Render this component
   * @returns {XML}
   */
  render() {
    const { selectedMarket, success, isLoadingData, endpoint } = this.state;

    return (
      <Form
        api={endpoint}
        onValueChange={this.handleChange}
        onSuccess={this.handleSuccess}
        initialValues={{ marketId: selectedMarket.marketId }}
      >
        {({ submit, isSending, sendError, ...inputProps }) => {
          return (
            <>
              {selectedMarket &&
                this.renderActivationFields(
                  selectedMarket,
                  inputProps,
                  sendError,
                  submit,
                  isSending,
                  isLoadingData,
                  success
                )}
            </>
          );
        }}
      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    market:
      state.data.markets && state.data.user
        ? state.data.markets.value.filter(
            market => market.marketId === state.data.user.value.marketId
          )[0]
        : {},
  };
};

export default connect(mapStateToProps, actions)(translate(ConnectPolicy));
