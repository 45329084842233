import React, { Component } from 'react';
import TextUnit from './text';
import { T } from '../../../containers/translationContext';
import Select from './select';
import InputCouple from '../inputCouple';

class PhoneUnit extends Component {
  inputRefs = [];
  phoneCountryCodePlaceholder = null;

  isValid() {
    return this.inputRefs.every(input => input.isValid());
  }

  render() {
    const { errors, name, values, countryCodeLabel, phoneCountryCodes } = this.props;
    const { phoneCountryCode } = values;

    return (
      <>
        <Select
          {...this.props}
          label={countryCodeLabel}
          name="phoneCountryCode"
          items={phoneCountryCodes}
          values={{ phoneCountryCode }}
          errors={{}}
          ref={c => (this.inputRefs[0] = c)}
        />
        <InputCouple>
          <InputCouple.Input width={25}>
            <TextUnit
              {...this.props}
              label=""
              name="phoneCountryCodePlaceholder"
              type="text"
              error=""
              errorMessage=""
              readonly
              values={{ phoneCountryCodePlaceholder: phoneCountryCode }}
            />
          </InputCouple.Input>
          <InputCouple.Input width={75}>
            <TextUnit
              {...this.props}
              type="tel"
              error={errors[name]}
              errorMessage={<T id="form.error.phone" />}
              pattern={/^\d{7,15}$/}
              noIcon
              ref={c => (this.inputRefs[1] = c)}
            />
          </InputCouple.Input>
        </InputCouple>
      </>
    );
  }
}

export default PhoneUnit;
