import React, { Component } from 'react';
import cc from 'classcat';
import { Link } from '@reach/router';
import { string, bool, oneOf, oneOfType, object, node, func } from 'prop-types';
import { prefetchPageComponent } from '../utils/routing';

class InternalLink extends Component {
  static propTypes = {
    activeClassName: string,
    activeStyle: object,
    href: string,
    children: node,
    preloadOn: oneOf(['viewportEnter']), // 'mount', 'hover',  just viewportEnter for now
    dontPreload: bool,
    innerRef: oneOfType([func, object]),
  };

  static defaultProps = {
    preloadOn: 'viewportEnter',
  };

  handleRef = ref => {
    const { preloadOn, dontPreload, href, innerRef } = this.props;
    if (!dontPreload && IOSupported && ref && preloadOn === 'viewportEnter') {
      // If IO supported and element reference found, setup Observer functionality
      handleIntersection(ref, () => {
        prefetchPageComponent(href);
      });
    }

    if (!IOSupported) prefetchPageComponent(href);

    if (innerRef) {
      if (typeof innerRef === 'function') {
        innerRef(ref);
      } else {
        innerRef.current = ref;
      }
    }
  };
  render() {
    const {
      href,
      innerRef,
      children,
      style,
      className,
      activeClassName,
      activeStyle,
      preloadOn,
      dontPreload,
      activeProps,
      ...rest
    } = this.props;
    return (
      <Link
        to={href}
        innerRef={this.handleRef}
        getProps={({ isCurrent }) =>
          isCurrent
            ? {
                style: { ...style, ...activeStyle },
                className: cc([className, activeClassName]),
                ...activeProps,
              }
            : { style, className }
        }
        {...rest}
      >
        {children}
      </Link>
    );
  }
}

// Check support for IntersectionObserver
const IOSupported = typeof window !== 'undefined' && window.IntersectionObserver;

// Set up IntersectionObserver
function handleIntersection(el, cb) {
  const io = new window.IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (el === entry.target) {
        // Check if element is within viewport, remove listener, destroy observer, and run link callback.
        // isInterecting isn't available everywhere
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
          io.unobserve(el);
          io.disconnect();
          cb();
        }
      }
    });
  });
  // Add element to the observer
  io.observe(el);
}

export default InternalLink;
