import React, { Suspense } from 'react';
import { Provider } from '../containers/storeContext';
import store from '../store';
import { Router, Location } from '@reach/router';
import routes from '../config/routes';
import TransitionGroup from '../components/transition/group';
import Transition from '../components/transition';
import Page from './page';
import ProtectedPage from '../containers/protectedPage';
import { TranslationProvider } from '../containers/translationContext';
import { RoutePropsProvider } from '../containers/routeContext';
import { TransitionProvider } from '../containers/transitionContext';
import Placeholder from './placeholder';
import ToastContainer from './toast/container';
import LinesSource from './lines/source';

function Root() {
  return (
    <Provider value={store}>
      <TranslationProvider>
        <ToastContainer />
        <LinesSource id={'linesSource'} seed={1} />
        <Suspense fallback={<Placeholder />}>
          <Location>
            {({ location }) => (
              <div className="perspectiveContainer">
                <TransitionGroup component={null}>
                  <Transition key={location.key} timeout={800}>
                    {(state, _, ref) => (
                      <div ref={ref}>
                        <TransitionProvider value={state}>
                          <Router location={location}>
                            {routes.map(
                              ({ Comp, path, default: isDefault, isProtected, ...pageProps }) => {
                                const PageComp = isProtected ? ProtectedPage : Page;
                                return (
                                  <RoutePropsProvider
                                    path={path}
                                    default={isDefault}
                                    key={path || '404'}
                                    isProtected={isProtected}
                                    {...pageProps}
                                    childs={
                                      <PageComp {...pageProps}>
                                        <Comp />
                                      </PageComp>
                                    }
                                  />
                                );
                              }
                            )}
                          </Router>
                        </TransitionProvider>
                      </div>
                    )}
                  </Transition>
                </TransitionGroup>
              </div>
            )}
          </Location>
        </Suspense>
      </TranslationProvider>
    </Provider>
  );
}

export default Root;
