import React from 'react';
import cc from 'classcat';
import { T } from '../../../containers/translationContext';
import InputLabel from '../inputLabel';
import InputError from '../inputError';
import style from './style.module.scss';
import AnimationItem from '../../animation/item';

function InputBox({
  children,
  label,
  name,
  hasInput,
  hasIcon,
  labelMoves,
  error,
  noUnderline,
  noMargin,
  width = '100%',
  optional,
  extraChild,
  readOnly,
  disableClickOnLabel,
  ...rest
}) {
  return (
    <AnimationItem type="slide">
      <label
        {...rest}
        style={{ width, display: 'block' }}
        onClick={e => disableClickOnLabel && e.preventDefault()}
      >
        <div
          className={cc([
            style.box,
            !noUnderline && !readOnly && style.underline,
            error && style.hasError,
            noMargin && style.noMargin,
            width === 'auto' && style.autoWidth,
          ])}
        >
          {children}
          {label && (
            <InputLabel
              // htmlFor={name}
              hasInput={hasInput}
              hasIcon={hasIcon}
              moves={labelMoves}
            >
              {label}
              {optional && (
                <em>
                  {' '}
                  (<T id="form.label.optional" />)
                </em>
              )}
            </InputLabel>
          )}
        </div>
        {extraChild}
        {error && <InputError hasIcon={hasIcon}>{error}</InputError>}
      </label>
    </AnimationItem>
  );
}

export default InputBox;
