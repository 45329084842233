import React from 'react';
import cc from 'classcat';
import style from './style.module.scss';

function HamburgerButton({ className, ...props }) {
  return (
    <button
      tabIndex={1} // Button in the header have the highest priority for keyboard navigation
      className={cc([style.hamburger, className])}
      {...props}
    >
      <span />
      <span />
      <span />
    </button>
  );
}

export default HamburgerButton;
