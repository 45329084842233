import React from 'react';

function Country({ ...props }) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <path
        d="M8,44.1c-0.2,0-0.4-0.1-0.6-0.2c-0.3-0.2-0.5-0.4-0.5-0.8C6.6,42.3,7.3,41,9.3,38c0.7-1.1,1.8-2.7,2.1-3.4
	c-0.7-0.7-1.3-1.5-1.9-2.4c-1.7-2.6-2.6-5.5-2.6-8.4c0-9.2,8.3-16.6,18.5-16.6C35.7,7.2,44,14.6,44,23.7c0,9.2-8.3,16.6-18.5,16.6
	c-2.9,0-5.6-0.6-8.2-1.7c-0.7,0.3-2.6,1.6-3.9,2.5C10.1,43.3,8.8,44.1,8,44.1z M25.4,9.2c-9.1,0-16.5,6.5-16.5,14.6
	c0,2.5,0.8,5,2.2,7.3c0.6,0.9,1.3,1.8,2,2.4c1,0.9,0,2.3-2.1,5.6c-0.4,0.6-0.9,1.4-1.3,2.1c0.8-0.5,1.8-1.2,2.6-1.7
	c3.9-2.7,4.8-3.2,5.6-2.7c2.4,1.1,5,1.6,7.7,1.6c9.1,0,16.5-6.5,16.5-14.6C42,15.7,34.6,9.2,25.4,9.2z M17,38.5
	C17,38.5,17,38.5,17,38.5C17,38.5,17,38.5,17,38.5z M11.7,34.9C11.7,34.9,11.7,34.9,11.7,34.9C11.7,34.9,11.7,34.9,11.7,34.9z"
      />
    </svg>
  );
}

export default Country;
