import React from 'react';
import style from './success.module.scss';
import Heading from '../../../heading';
import Paragraph from '../../../paragraph';
import { translate } from '../../../../containers/translationContext';
import TextLink from '../../../../components/textLink';

function Error({ t, message }) {
  return (
    <div className={style.container}>
      <Heading size="h1">{t('form.error.heading')}</Heading>
      <Paragraph>{t('form.error.technical')}</Paragraph>
      <Paragraph>{message}</Paragraph>
      <TextLink href="/">{t('form.error.backHome')}</TextLink>
    </div>
  );
}

export default translate(Error);
