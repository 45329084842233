import React from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import ChangeEmailForm from '../components/form/forms/changeEmail';
import CloseButton from '../components/button/close';
import Data from '../containers/data';
import Header from '../components/header';

function AccountEmail() {
  return (
    <NarrowColumn>
      <Header right={<CloseButton href="/account" color="darkPetrol" />} />
      <Data keys={['user']}>{([user]) => <ChangeEmailForm user={user} />}</Data>
    </NarrowColumn>
  );
}

export default AccountEmail;
