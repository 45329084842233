import React from 'react';
import cc from 'classcat';
import style from './style.module.scss';
import Heading from '../heading';
import { translate } from '../../containers/translationContext';

function Toast({ message, messageId, action, t, disallowDismiss, onDismiss, state }) {
  return (
    <div className={cc([style.toast, style[state]])}>
      <Heading variant="sans" size="h6" color="black">
        {messageId ? t(messageId) : message}
      </Heading>
      <div className={style.buttons}>
        {action && (
          <button
            className={style.button}
            onClick={() => {
              action.callback && action.callback();
              onDismiss();
            }}
          >
            {action.labelId ? t(action.labelId) : action.label}
          </button>
        )}
        {disallowDismiss || (
          <button className={style.button} onClick={onDismiss}>
            {t('dismiss')}
          </button>
        )}
      </div>
    </div>
  );
}

export default translate(Toast);
