/**
 * Config for potential dashboard pages
 * Define their color here
 * And endpoint for the explanation
 */
export default {
  projectedEndValue: {
    endpoint: 'projected-end-value',
    color: 'purple',
    weight: 1,
  },
  maturityGuarantee: {
    endpoint: 'maturity-guarantee',
    color: 'petrol',
    weight: 2,
  },
  revaluationGain: {
    endpoint: 'revaluation-gain',
    color: 'mint',
    weight: 3,
  },
  deathBenefit: {
    endpoint: 'death-benefit',
    color: 'mint',
    weight: 4,
  },
  allocatedBonus: {
    endpoint: 'terminal-bonus-reserve',
    color: 'yellow',
    weight: 5,
  },
  subscriptionBonus: {
    endpoint: 'subscription-bonus',
    color: 'yellow',
    weight: 6,
  },
  policyValue: {
    endpoint: 'policy-value',
    color: 'darkPetrol',
    weight: 7,
  },
  surrenderValue: {
    endpoint: 'surrender-value',
    color: 'purple',
    weight: 8,
  },
};
