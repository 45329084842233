import store from '../store';
import { translate } from './language';

function authenticateEndpoint(endpoint) {
  const { authToken } = store.getState();
  return (...args) => endpoint(authToken, ...args);
}

function request(url, params, meth = 'get', jwt) {
  const method = meth.toUpperCase();
  const body = params instanceof FormData ? formDataToJSON(params) : JSON.stringify(params);
  const language = store.getState().language;
  const options = {
    method,
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': language,
    },
    body,
  };
  if (jwt) {
    options.headers.Authorization = `Bearer ${jwt}`;
  }

  return fetch(url, options)
    .then(responseStatus)
    .then(responseJSON)
    .catch(() => {
      if (navigator.onLine) {
        throw new Error(translate(language, 'request.error'));
      } else {
        throw new Error(translate(language, 'request.error.offline'));
      }
    });
}

function get(url, params, jwt) {
  const urlWithParams = `${url}${params ? createUrlQuery(params) : ''}`;
  return request(urlWithParams, undefined, undefined, jwt);
}

function post(url, params, jwt) {
  return request(url, params, 'POST', jwt);
}

function del(url, params, jwt) {
  return request(url, params, 'DELETE', jwt);
}

function put(url, params, jwt) {
  return request(url, params, 'PUT', jwt);
}

function responseStatus(response) {
  if (response.ok) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  return Promise.reject(error);
}

function responseJSON(response) {
  if (
    response.url.includes('document') &&
    response.url.includes('documentId') &&
    response.url.includes('contractId')
  )
    return response;

  return response.json();
}

function createUrlQuery(params = {}) {
  const esc = encodeURIComponent;
  const joined = Object.keys(params)
    .map(k => `${esc(k)}=${esc(params[k])}`)
    .join('&');
  return `?${joined}`;
}

function formDataToJSON(formData) {
  const json = {};
  formData.forEach((value, key) => (json[key] = value));
  return JSON.stringify(json);
}

export { responseStatus, createUrlQuery, get, post, request, del, put, authenticateEndpoint };
