const queue = [];
let isFetching = false;

function update() {
  if (isFetching) return;

  const next = queue.shift();

  if (next) {
    isFetching = true;
    Promise.resolve(next())
      .then(() => {
        isFetching = false;
        update();
      })
      .catch(e => {
        // Just continue even if prefetch fails
        isFetching = false;
        update();
      });
  }
}

/**
 * @callback loadCallback
 * @returns {Promise}
 */

/**
 *
 * @param {loadCallback} cb
 */
function prefetch(cb) {
  queue.push(cb);
  update();
}

function getState() {
  return { queue, isFetching };
}

export { prefetch, getState };
