import React from 'react';
import { translate } from '../../containers/translationContext';
import NarrowColumn from '../../components/contentGrid/narrowColumn';
import PaymentFrequency from '../../components/form/forms/alteration/paymentFrequency';
import { actions } from '../../store';
import { connect } from '../../containers/storeContext';
import Placeholder from '../../components/placeholder';

class AlterationPaymentFrequency extends React.Component {
  state = {
    activePolicy: this.props.activePolicy,
    policyDetails: null,
  };

  componentDidMount() {
    this.loadPolicyDetails(this.state.activePolicy.contractId);
  }

  loadPolicyDetails = contractId => {
    const { getData } = this.props;
    var options = {
      onSuccess: data => {
        this.setState({ policyDetails: data });
      },
    };

    getData(`policy`, options, contractId);
  };

  render() {
    return this.state.policyDetails === null ? (
      <Placeholder />
    ) : (
      <NarrowColumn>
        <PaymentFrequency
          activePolicy={this.state.activePolicy}
          policyDetails={this.state.policyDetails}
        />
      </NarrowColumn>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activePolicy: state.activePolicy,
  };
};

export default connect(mapStateToProps, actions)(translate(AlterationPaymentFrequency));
