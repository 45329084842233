import React from 'react';
import { translate } from '../containers/translationContext';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import ConnectPolicyForm from '../components/form/forms/connectPolicy';
import Header from '../components/header';
import Heading from '../components/heading';
import CloseButton from '../components/button/close/index';
import Data from '../containers/data';
import Placeholder from '../components/placeholder';

function PolicyConnect({ t }) {
  return (
    <NarrowColumn>
      <Header right={<CloseButton color="darkPetrol" />} />
      <Heading>{t('policy.connect.heading')}</Heading>

      <Data keys={[`markets`]} fallback={<Placeholder />}>
        <ConnectPolicyForm />
      </Data>
    </NarrowColumn>
  );
}

export default translate(PolicyConnect);
