import React, { Component } from 'react';
import { connect } from '../../containers/storeContext';
import Link from '../link';
import Data from '../../containers/data';
import { translate } from '../../containers/translationContext';
import DropDown from './dropdown';
import { actions } from '../../store';
import style from './style.module.scss';

class PolicyDropdown extends Component {
  state = {};

  handlePolicyChange = policy => {
    this.props.setActivePolicy(policy);
  };

  render() {
    const { t, activePolicy } = this.props;
    const user = this.props.data.user.value;

    return activePolicy && (activePolicy.length || Object.keys(activePolicy).length) ? (
      <Data keys={['policies']}>
        {([policies]) => (
          <DropDown
            addLabel={t('policy.overview.connect')}
            items={policies}
            value={activePolicy}
            onChange={this.handlePolicyChange}
            isAdmin={user.isAdmin}
          />
        )}
      </Data>
    ) : (
      <Link className={style.connect} href="/policy/overview">
        {t('policy.overview.connect')} +
      </Link>
    );
  }
}

export default connect('activePolicy,data', actions)(translate(PolicyDropdown));
