import React from 'react';
import { translate } from '../containers/translationContext';
import Form from '../components/form';
import SubmitButton from '../components/button/submit';
import Header from '../components/header';
import Heading from '../components/heading';
import CardNav from '../components/cardNav';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Placeholder from '../components/placeholder';
import { connect } from '../containers/storeContext';
import { actions } from '../store';
import Paragraph from '../components/paragraph';

const alterationResourceMap = {
  policyHolderAddress: '/alteration/changeOfAddress',
  policyHolderData: '/alteration/changeOfPersonalData',
  bankData: '',
  paymentMethod: '/alteration/paymentMethod',
  paymentFrequency: '/alteration/paymentFrequency',
};

class AlterationList extends React.Component {
  state = { possibleAlterations: {} };

  componentDidMount() {
    const { activePolicy } = this.props;
    this.loadPossibleAlterations(activePolicy.contractId);
  }

  loadPossibleAlterations = contractId => {
    const { getData } = this.props;
    var options = {
      onSuccess: data => {
        const alterations = {};

        data.allowedAlterations.forEach(alteration => {
          if (alteration.allowedAlteration) {
            const group = alteration.group || 'general';
            alterations[group] = alterations[group] || [];

            alterations[group].push({
              name: alteration.name,
            });
          }
        });

        this.setState({ possibleAlterations: alterations });
      },
    };

    getData(`possibleAlterationList`, options, contractId);
  };

  renderNoAlterationsAllowed() {
    const { t } = this.props;

    return (
      <NarrowColumn>
        <Header left="menu" />
        <Heading color="white" size="h4">
          {t('policy.detail.alterations')}
        </Heading>
        <Paragraph color="white">{t('alteration.noAlterationsAllowed')}</Paragraph>
      </NarrowColumn>
    );
  }

  renderVerifyMobileNumber = () => {
    const { t } = this.props;

    return (
      <>
        <NarrowColumn>
          <Header left="menu" />
          <Heading color="white" size="h4">
            {t('phone.verifyHeader')}
          </Heading>
          <Paragraph color="white">{t('phone.verifyFirst')}</Paragraph>
          <Form>
            {({ submit, isSending, sendError, ...inputProps }) => {
              return (
                <>
                  <SubmitButton href="/account/phone">{t('phone.verifyHeader')}</SubmitButton>
                </>
              );
            }}
          </Form>
        </NarrowColumn>
      </>
    );
  };

  renderAlterationSelection() {
    const { t } = this.props;

    return (
      <>
        <NarrowColumn>
          <Header left="menu" />
          <Heading color="white" size="h4">
            {t('policy.detail.alterations')}
          </Heading>
          <CardNav>
            {Object.keys(this.state.possibleAlterations).length <= 0 ? (
              <Placeholder color="white" style={{ marginTop: '80px' }} />
            ) : (
              <>
                {Object.keys(this.state.possibleAlterations).map((group, indexGroup) => {
                  return (
                    <React.Fragment key={`header_${indexGroup}`}>
                      <Heading size="h5" variant="light" color="white">
                        {t(`alteration.header.${group}`)}
                      </Heading>
                      {this.state.possibleAlterations[group].map((alteration, indexAlteration) => {
                        return (
                          <CardNav.Card
                            href={alterationResourceMap[alteration.name]}
                            arrow
                            key={`alteration_${indexAlteration}`}
                          >
                            <Heading size="h6" variant="light" ieFlexGrow>
                              {t(`alteration.${alteration.name}`)}
                            </Heading>
                          </CardNav.Card>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </CardNav>
        </NarrowColumn>
      </>
    );
  }

  render() {
    const { activePolicy, user } = this.props;

    if (!activePolicy.alterationAllowed) {
      return this.renderNoAlterationsAllowed();
    }

    if (!user.value.isPhoneVerified) {
      return this.renderVerifyMobileNumber();
    }

    return this.renderAlterationSelection();
  }
}

const mapStateToProps = (state, ownProps) => {
  return { activePolicy: state.activePolicy, user: state.data.user };
};

export default connect(mapStateToProps, actions)(translate(AlterationList));
