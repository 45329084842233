import React from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Heading from '../components/heading';
import Data from '../containers/data';
import Header from '../components/header';
import { translate } from '../containers/translationContext';
import CardNav from '../components/cardNav';
import { slug } from '../utils/routing';
import Placeholder from '../components/placeholder';
import Paragraph from '../components/paragraph';
import { connect } from '../containers/storeContext';

function accountFaq({ t, hideFaq }) {
  return (
    <NarrowColumn>
      <Header left="menu" />
      <Heading weight="bold" color="white" size="h4">
        {t('service')}
      </Heading>

      {hideFaq ? null : (
        <Data
          keys={['faq']}
          fallback={<Placeholder color="white" />}
          renderErrors={e => (
            <Paragraph color="white" size="small">
              {e[0]}
            </Paragraph>
          )}
        >
          {([faq]) => (
            <CardNav>
              <Heading variant="sans" color="white" size="h6" weight="light">
                {faq.title}
              </Heading>
              {faq.categories.map((item, j) => (
                <CardNav.Card
                  key={j}
                  href={`/service/faq/${slug(faq.title)}/${slug(item.title)}`}
                  arrow
                >
                  <Heading size="h6" variant="light" ieFlexGrow>
                    {item.title}
                  </Heading>
                </CardNav.Card>
              ))}
            </CardNav>
          )}
        </Data>
      )}

      <CardNav>
        <Heading variant="sans" color="white" size="h6" weight="light">
          {t('account.otherQuestions')}
        </Heading>

        <CardNav.Card arrow href="/service/contact">
          <Heading size="h6" variant="light" ieFlexGrow>
            {t('account.getInContact')}
          </Heading>
        </CardNav.Card>
      </CardNav>
    </NarrowColumn>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    hideFaq: true,
  };
};

export default connect(mapStateToProps)(translate(accountFaq));
