import React, { Component } from 'react';
import { array, func, object, string } from 'prop-types';
import bindInputState from '../../../utils/bindInputState';
import ResizeChecker from '../../../containers/resizeChecker';
import Select from '../inputs/select';
import SimpleSelect from '../inputs/select/simple';

class SelectUnit extends Component {
  static propTypes = {
    name: string.isRequired,
    errors: object.isRequired,
    values: object.isRequired,
    setError: func.isRequired,
    setValueState: func.isRequired,
    placeholder: string,
    items: array.isRequired,
    onChange: func,
  };

  componentDidMount() {
    const { values, name, items, placeholder, setValueState, itemToKey } = this.props;
    if (!placeholder && !values[name] && items[0]) {
      setValueState({ [name]: items[0].key || itemToKey(items[0]) });
    }
  }

  isValid() {
    return !!this.props.values[this.props.name];
  }

  render() {
    const { setError, name, values, setValueState, ...rest } = this.props;

    return (
      <div>
        <ResizeChecker breakpoints={['desktop']}>
          {widthState => {
            const Comp = widthState === 0 ? SimpleSelect : Select;
            return (
              <Comp
                {...bindInputState(
                  values,
                  setValueState,
                  name,
                  e => e.key || this.props.itemToKey(e),
                  {
                    ...rest,
                  }
                )}
              />
            );
          }}
        </ResizeChecker>
      </div>
    );
  }
}

export default SelectUnit;
