import React from 'react';
import Data from '../../containers/data';
import { T } from '../../containers/translationContext';
import Policy from './policy';
import AddPolicy from './addPolicy';
import SubmitButton from '../button/submit';
import CardNav from '../cardNav';
import { connect } from '../../containers/storeContext';
import { actions } from '../../store';

function Policies({ setActivePolicy }) {
  return (
    <Data keys={['policies']}>
      {([policies]) => (
        <>
          <CardNav>
            {policies.map((policy, i) => (
              <Policy onClick={() => setActivePolicy(policy)} {...policy} key={i.toString()} />
            ))}
            <AddPolicy index={policies.length} />
          </CardNav>
          {policies.length > 0 && (
            <SubmitButton href="/dashboard" color="white">
              <T id="policy.overview.done" />
            </SubmitButton>
          )}
        </>
      )}
    </Data>
  );
}

export default connect(null, actions)(Policies);
