import React from 'react';
import Style from './style.module.scss';

const dialogButton = props => {
  return (
    <button
      className={Style.button}
      type="button"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.title}
    </button>
  );
};

export default dialogButton;
