import React from 'react';
import { T } from '../../../containers/translationContext';
import Style from './digitalConsent.module.scss';
import CheckboxInput from '../inputs/checkbox';
import Button from '../../button/submit';

const DigitalConsentForm = props => {
  return (
    <>
      <div className={Style.dialogHeader}>
        <p className={Style.dialogHeadingtext}>
          <T id="dialog.heading.digitalConsent" />
        </p>
      </div>
      <div className={Style.dialogBody}>
        <p className={Style.dialogParagraphtext}>
          <T id="dialog.paragraph.one" />
        </p>
        <span className={Style.optionContainer}>
          <p className={Style.optionNo}>
            <T id="dialog.option.no" />
          </p>
          <CheckboxInput
            id="digitalConsent"
            name="digitalConsent"
            value={props.digitalConsent}
            onChange={props.onChange}
            noIcon={true}
          />
          <p className={Style.optionYes}>
            <T id="dialog.option.yes" />
          </p>
        </span>
        <p className={Style.dialogParagraphtext}>
          <T id="dialog.paragraph.two" />
        </p>
        <ul className={Style.dialogPointContainer}>
          <li>
            <T id="dialog.paragraph.two.one" />
          </li>
          <li>
            <T id="dialog.paragraph.two.two" />
          </li>
          <li>
            <T id="dialog.paragraph.two.three" />
          </li>
        </ul>
        <span className={Style.optionContainer}>
          <p className={Style.optionNo}>
            <T id="dialog.option.no" />
          </p>
          <CheckboxInput
            id="marketingConsent"
            name="marketingConsent"
            value={props.marketingConsent}
            onChange={props.onChange}
            noIcon={true}
          />
          <p className={Style.optionYes}>
            <T id="dialog.option.yes" />
          </p>
        </span>
        <div className={Style.acceptance}>
          <input
            className={Style.checkboxAcceptance}
            type="checkbox"
            name="accept"
            value={props.accept}
            onChange={props.onChange}
          />
          <span
            className={Style.acceptanceText}
            style={{ color: `${!props.accept ? '#d63b1b' : '#3c3c3c'}` }}
          >
            <T id="dialog.paragraph.three" />
          </span>
        </div>
      </div>
      <div className={Style.dialogFooter}>
        <Button onClick={props.handleOnSubmit} disabled={!props.accept}>
          <T id="dialog.button.submit" />
        </Button>
      </div>
    </>
  );
};

export default DigitalConsentForm;
