import React, { Component } from 'react';
import Form from '../index';
import Button from '../../button/submit';
import EmailUnit from '../inputUnits/email';
import { endpoints } from '../../../config/data';
import { translate } from '../../../containers/translationContext';
import Paragraph from '../../paragraph';
import SendError from '../sendError';

class PasswordResetForm extends Component {
  static defaultProps = {
    onSuccess: () => {},
  };
  state = {
    showForm: true,
    isValid: false,
  };

  handleSuccess = e => {
    this.setState({ showForm: false });
    this.props.onSuccess(e);
  };

  handleChange = () => {
    this.setState({
      isValid: this.email.isValid(),
    });
  };

  render() {
    const { t } = this.props;
    return this.state.showForm ? (
      <Form
        api={endpoints.passwordForgotten}
        onSuccess={this.handleSuccess}
        onValueChange={this.handleChange}
      >
        {({ submit, isSending, sendError, ...inputProps }) => (
          <>
            <Paragraph>{t('reset.body')}</Paragraph>
            <EmailUnit
              name="email"
              label={t('form.label.email')}
              ref={c => (this.email = c)}
              {...inputProps}
            />
            <SendError message={sendError} />
            <Button onClick={submit} disabled={isSending || !this.state.isValid} noMenu>
              {t('form.button.resetPassword')}
            </Button>
          </>
        )}
      </Form>
    ) : (
      <Paragraph>{t('reset.sentBody')}</Paragraph>
    );
  }
}

export default translate(PasswordResetForm);
