import React, { Component } from 'react';
import emitter from '../../utils/emitter';
import Toast from './index';
import TransitionGroup from '../transition/group';
import Transition from '../transition';

class ToastContainer extends Component {
  state = {
    queue: [],
  };

  componentDidMount() {
    emitter.on('toast', this.addToast);
    emitter.on('removeToast', this.removeToastByMessage);
  }

  componentWillUnmount() {
    emitter.off('toast', this.addToast);
    emitter.on('removeToast', this.removeToastByMessage);
  }

  addToast = data => {
    this.setState(({ queue }) => {
      // Don't add the same message twice
      if (
        queue.filter(
          toast => (toast.messageId || toast.message) === (data.messageId || data.message)
        ).length
      ) {
        return null;
      }
      return { queue: [...queue, data] };
    });
  };

  removeToastByMessage = message => {
    this.setState(({ queue }) => ({
      queue: queue.filter(t => (t.messageId || t.message) !== message),
    }));
  };

  removeToast = toast => {
    this.setState(({ queue }) => ({
      queue: queue.filter(t => t !== toast),
    }));
  };

  render() {
    const toast = this.state.queue[0];
    return (
      <TransitionGroup>
        {toast && (
          <Transition key={toast.messageId || toast.message} timeout={500}>
            {state => <Toast {...toast} state={state} onDismiss={() => this.removeToast(toast)} />}
          </Transition>
        )}
      </TransitionGroup>
    );
  }
}

export default ToastContainer;
