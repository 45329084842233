import { Component } from 'react';
import { func, arrayOf, oneOfType, number, string } from 'prop-types';
import throttle from 'lodash/throttle';
import { breakpoints } from '../config/breakpoints';

class ResizeChecker extends Component {
  static propTypes = {
    breakpoints: arrayOf(oneOfType([number, string])).isRequired,
    children: func.isRequired,
  };

  constructor(props) {
    super();
    this.state = {
      breakpoints: [0, ...parseBreakpoints(props.breakpoints)],
    };

    this.state.widthState = this.getWidthState();
  }

  static getDerivedStateFromProps(props) {
    return {
      breakpoints: [0, ...parseBreakpoints(props.breakpoints)],
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = throttle(() => {
    if (!this.props.breakpoints) {
      this.setState({});
      return;
    }

    const widthState = this.getWidthState();

    this.setState(prev => {
      if (prev.widthState !== widthState) {
        return { widthState };
      }
      return null;
    });
  }, 200);

  getWidthState() {
    const { breakpoints } = this.state;
    for (let i = breakpoints.length; i >= 0; i--) {
      if (window.innerWidth >= breakpoints[i]) {
        return i;
      }
    }

    return null;
  }

  render() {
    return this.props.children(this.state.widthState);
  }
}

function parseBreakpoints(bps) {
  return bps.map(bp => (typeof bp === 'string' ? breakpoints[bp] : bp));
}

export default ResizeChecker;
