import React, { Component } from 'react';
import { endpoints } from '../../../config/data';
import Placeholder from '../../../components/placeholder';
import Thumb from '../../image/thumb';
import Hybrid from '../../image/hybrid';
import { T } from '../../../containers/translationContext';
import { translate } from '../../../containers/translationContext';
import { navigate } from '@reach/router';
import Heading from '../../heading';
import Paragraph from '../../paragraph';
import ResendEmailForm from './resendEmail';
import { connect } from '../../../containers/storeContext';
import { actions } from '../../../store';

class ActivateForm extends Component {
  mounted = false;
  state = {
    responseAction: () => <Placeholder />,
  };

  componentDidMount() {
    this.mounted = true;
    this.activateAccount();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateResponseAction = responseAction => {
    this.setState({
      responseAction: responseAction,
    });
  };

  activateAccount = async () => {
    try {
      const response = await endpoints.activate(null, { activationToken: this.props.code });
      if (response.reasonCode === '13') {
        this.updateResponseAction(() => {
          return this.renderError(response.message);
        });
      } else if (!response.success) {
        this.updateResponseAction(() => {
          return this.renderForm(response.message);
        });
      } else {
        await this.changeLanguage(response.data.languageId);
        this.updateResponseAction(() => {
          this.handleSuccess();
          return this.renderSuccess();
        });
      }
    } catch (e) {
      this.updateResponseAction(() => {
        return this.renderError(e.message);
      });
    }
  };

  changeLanguage = async language => {
    await this.props.changeLanguage(language);
  };

  handleSuccess = () => {
    setTimeout(() => {
      if (this.mounted) navigate('/');
    }, 5000);
  };

  renderError = message => (
    <>
      <Hybrid />
      <Heading>{message}</Heading>
    </>
  );

  renderForm = message => (
    <>
      <Hybrid />
      <Heading>{message}</Heading>
      <ResendEmailForm />
    </>
  );

  renderSuccess = () => (
    <>
      <Heading size="h1">
        <T id="activate.heading" />
      </Heading>
      <Thumb />
      <Paragraph align="center">
        <T id="activate.body" />
      </Paragraph>
    </>
  );

  render() {
    return <div style={{ textAlign: 'center' }}>{this.state.responseAction()}</div>;
  }
}

export default connect(null, actions)(translate(ActivateForm));
