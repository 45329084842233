import React, { Component } from 'react';
import routeContext from '../containers/routeContext';
import ChoosePasswordForm from '../components/form/forms/passwordChoose';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import { navigate } from '@reach/router';
import BackButton from '../components/button/back';
import Heading from '../components/heading';
import Hybrid from '../components/image/hybrid';
import Header from '../components/header';
import { translate } from '../containers/translationContext';
import Paragraph from '../components/paragraph';

class Reset extends Component {
  static contextType = routeContext;
  mounted = true;
  state = { completed: false };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSuccess = () => {
    this.setState({ completed: true });
    setTimeout(() => {
      if (this.mounted) {
        navigate('/', true);
      }
    }, 2000);
  };

  render() {
    const { t } = this.props;
    const { code } = this.context;

    return (
      <NarrowColumn>
        {!this.state.completed ? (
          <>
            <Header left={<BackButton href="/" color="darkPetrol" />} right="register" />
            <Hybrid />
            <Heading>{t('reset.heading')}</Heading>
            <ChoosePasswordForm onSuccess={this.handleSuccess} code={code} />
          </>
        ) : (
          <>
            <Hybrid />
            <Heading>{t('reset.successHeading')}</Heading>
            <Paragraph>{t('reset.successBody')}</Paragraph>
          </>
        )}
      </NarrowColumn>
    );
  }
}

export default translate(Reset);
