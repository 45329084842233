import React from 'react';
import { translate } from '../../containers/translationContext';
import SubmitButton from '../../components/button/submit';
import Heading from '../../components/heading';
import Paragraph from '../../components/paragraph';

const addresses = {
  'FLL-IT': {
    name: 'FWU Life Insurance LUX S.A.',
    office: 'Sede secondaria in Italia',
    street: 'Via Roberto Lepetit 8/10',
    place: '20124 Milano',
    phone: '+39 02 67 06 065',
    email: 'info-it@forwardyou.com',
  },
  'FLL-ES': {
    name: 'FWU Life Insurance Lux S.A.',
    office: 'Sucursal en España',
    street: 'Calle Cañada de la Carrera, 2',
    place: 'E - 28223 Pozuelo de Alarcon (Madrid)',
    phone: '+34 917 943 000',
    email: 'info-es@forwardyou.com',
  },
  default: {
    name: 'FWU Life Insurance Lux S.A.',
    office: 'Demo Branch',
    street: 'Demo Street',
    place: 'Demo Place',
    phone: '+00 000 000 000',
    email: 'demo-email@forwardyou.com',
  },
};

const Fwu = ({ t, marketId }) => {
  const address = addresses[marketId] ? addresses[marketId] : addresses['default'];
  return (
    <>
      <Heading size="h6" variant="sans">
        {address.name}
      </Heading>
      <Paragraph>
        {address.office}
        <br />
        {address.street}
        <br />
        {address.place}
      </Paragraph>
      <Paragraph>
        {t('contact.phone')}: <a href={`tel:${address.phone}`}>{address.phone}</a>
        <br />
        {t('contact.email')}: <a href={`mailto:${address.email}`}>{address.email}</a>
      </Paragraph>
      <SubmitButton href={`tel:${address.phone.replace(/\s+/g, '')}`} external>
        {`${t('contact.call')} FWU`}
      </SubmitButton>
    </>
  );
};

export default translate(Fwu);
