import React from 'react';
import Downshift from 'downshift';
import cc from 'classcat';
import style from './dropdown.module.scss';
import Link from '../link';

function DropDown({ items, value, onChange, addLabel, isAdmin }) {
  const activeItem = items.filter(i => itemToKey(i) === itemToKey(value))[0] || null;
  const activeIndex = items.indexOf(activeItem);

  return (
    <Downshift
      items={items}
      selectedItem={activeItem}
      defaultHighlightedIndex={activeIndex}
      itemToString={itemToString}
      onChange={onChange}
    >
      {({ getToggleButtonProps, getItemProps, isOpen, selectedItem, highlightedIndex }) => {
        return (
          <div className={style.container}>
            <button
              className={cc([style.button, isOpen && style.open])}
              {...getToggleButtonProps()}
              aria-haspopup="true"
              aria-expanded={isOpen}
            >
              {itemToString(selectedItem)}
              <br />
              {selectedItem.contractId}
            </button>
            {isOpen && (
              <div className={style.options}>
                {items.map(
                  (item, index) =>
                    selectedItem.contractId !== item.contractId && (
                      <Link
                        {...getItemProps({ item })}
                        className={cc([
                          style.item,
                          highlightedIndex === index && style.isFocussed,
                          selectedItem && selectedItem.key === item.key && style.isSelected,
                        ])}
                        key={itemToKey(item)}
                        href="/dashboard"
                      >
                        <div>{itemToString(item)}</div>
                        <div className={style.productName}>{itemToProductName(item)}</div>
                        <div className={style.productName}>{itemToKey(item)}</div>
                      </Link>
                    )
                )}
                {isAdmin ? (
                  ''
                ) : (
                  <Link href="/policy/overview" className={cc([style.item, style.connectPolicy])}>
                    {addLabel} +
                  </Link>
                )}
              </div>
            )}
          </div>
        );
      }}
    </Downshift>
  );
}

function itemToString(item) {
  return item && item.policyHolderName;
}

function itemToProductName(item) {
  return item && item.description;
}

function itemToKey(item) {
  return item && item.contractId;
}

export default DropDown;
