import React from 'react';
import { Redirect } from '@reach/router';
import Data from '../containers/data';

function Landing() {
  return (
    <Data keys={['policies']}>
      {([policies]) =>
        policies && policies.length ? (
          <Redirect to="/dashboard" noThrow />
        ) : (
          <Redirect to="/policy/overview" noThrow />
        )
      }
    </Data>
  );
}

export default Landing;
