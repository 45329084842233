import React, { Component } from 'react';
import Form from '../index';
import Button from '../../button/submit';
import ChoosePassword from '../inputUnits/choosePassword';
import { endpoints } from '../../../config/data';
import { translate } from '../../../containers/translationContext';
import InputGroup from '../inputGroup';
import SendError from '../sendError';
import TextLink from '../../textLink';

class ChoosePasswordForm extends Component {
  state = {
    isValid: false,
  };

  handleChange = () => {
    this.setState({
      isValid: this.password.isValid(),
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Form
        api={endpoints.passwordReset}
        onSuccess={this.props.onSuccess}
        initialValues={{ resetPasswordToken: this.props.code }}
        onValueChange={this.handleChange}
      >
        {({ submit, isSending, sendError, ...inputProps }) => (
          <>
            <InputGroup heading={t('reset.passwordHeading')}>
              <ChoosePassword
                label={t('form.label.passwordNew')}
                labelConfirm={t('form.label.passwordConfirm')}
                name="password"
                nameConfirm="passwordConfirm"
                ref={c => (this.password = c)}
                passwordChange="true"
                {...inputProps}
              />
            </InputGroup>
            {sendError && (
              <>
                <SendError message={sendError} />
                <TextLink href="/account/password/reset">{t('form.button.resendEmail')}</TextLink>
              </>
            )}
            <Button onClick={submit} disabled={isSending || !this.state.isValid} noMenu>
              {t('form.button.resetPassword')}
            </Button>
          </>
        )}
      </Form>
    );
  }
}

export default translate(ChoosePasswordForm);
