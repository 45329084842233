import React from 'react';
import CardNav from '../cardNav';
import Money from '../money';
import style from './style.module.scss';
import Time from '../time';
import Heading from '../heading';
import Icon from '../image/icon/index';
import Link from '../link';
import formatMoney from '../../utils/formatMoney';

function sequenceNumber(screenTranslations, sequenceNumber) {
  for (let index = 0; index < screenTranslations.length; index++) {
    const linkData = screenTranslations[index];
    if (linkData.sequenceNumber === sequenceNumber) {
      return linkData;
    }
  }
  return null;
}

function prepareArrowLink(path, linkData) {
  return linkData === null || linkData.visibility === false ? null : (
    <Link color="white" href={path} className={style.lumpSum}>
      <span>{linkData.displayLabel}</span>
      <Icon type="more" color="white" className={style.arrow} />
    </Link>
  );
}

function Payments({ yearly, screenTranslations }) {
  return (
    <CardNav>
      {prepareArrowLink('/payments/lumpSum', sequenceNumber(screenTranslations, '1'))}

      {Object.values(yearly).map(({ totalAmount, currency, monthly, year, lastPaymentDate }, i) => (
        <CardNav.Card key={i.toString()} href={`/payments/detail/${year}`} className={style.card}>
          <time className={style.time}>
            <Heading variant="sans" size="h5">
              {year}
            </Heading>
            <Heading variant="light" color="petrol" size="h6">
              <Time timestamp={lastPaymentDate} month="short" day />
            </Heading>
          </time>
          <Money value={totalAmount} currency={currency}>
            {({ symbol }) => (
              <div className={style.value}>
                <Heading variant="light" size="h6" color="petrol">
                  {formatMoney(totalAmount, currency).v} {symbol}
                </Heading>
                <Icon type="more" color="darkPetrol" className={style.arrow} />
              </div>
            )}
          </Money>
        </CardNav.Card>
      ))}

      {prepareArrowLink('/payments/technical', sequenceNumber(screenTranslations, '2'))}
      {prepareArrowLink('/payments/pay', sequenceNumber(screenTranslations, '3'))}
      {prepareArrowLink('/payments/plan', sequenceNumber(screenTranslations, '4'))}
    </CardNav>
  );
}

export default Payments;
