import React from 'react';
import cc from 'classcat';
import Link from '../link';
import style from './style.module.scss';

function TextLink({ href, children, onClick, className, ...rest }) {
  if (href) {
    return (
      <Link className={cc([style.link, className])} href={href} onClick={onClick} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <button type="button" className={cc([style.link, className])} onClick={onClick} {...rest}>
      {children}
    </button>
  );
}

export default TextLink;
