import React from 'react';

function Facebook(props) {
  return (
    <svg viewBox="0 0 350 350" {...props}>
      <path d="M291.25,47.62v224.65c0,10.43-8.44,18.93-18.75,18.93H47.44c-10.31,0-18.69-8.5-18.69-18.93V47.62c0-10.43,8.38-18.93,18.69-18.93h225.06c10.31,0,18.75,8.5,18.75,18.93ZM111.19,88.75c0-12.42-10.14-22.56-22.56-22.56s-22.56,10.14-22.56,22.56,10.08,22.56,22.56,22.56,22.56-10.08,22.56-22.56Zm-3.05,164.94v-125.27h-38.96v125.27h38.96Zm145.78-68.67c0-33.75-7.32-59.71-46.7-59.71-18.93,0-31.64,10.37-36.86,20.21h-.53v-17.11h-37.32v125.27h38.91v-61.99c0-16.35,3.11-32.17,23.38-32.17s20.21,18.69,20.21,33.22v60.94h38.91v-68.67Z" />
    </svg>
  );
}

export default Facebook;
