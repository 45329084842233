import React, { Component } from 'react';
import cc from 'classcat';
import Portal from '../portal';
import HamburgerButton from '../button/hamburger';
import Link from '../link';
import Heading from '../heading';
import BackButton from '../button/back';
import { connect } from '../../containers/storeContext';
import { translate } from '../../containers/translationContext';
import store, { actions } from '../../store';
import PolicyDropdown from '../policyDropdown';
import Data from '../../containers/data';
import Hybrid from '../image/hybrid';
import Swipable from '../../utils/swipable';
import themeContext from '../../containers/themeContext';
import style from './style.module.scss';
import Icon from '../image/icon/index';
import Social from '../image/social';
import { TransitionState } from '../../containers/transitionContext';

class Menu extends Component {
  static contextType = themeContext;

  socialUrls = {
    'FLL-IT': {
      linkedIn: 'https://www.linkedin.com/company/fwu-ag/',
      facebook: 'https://www.facebook.com/fwulife.it',
      instagram: 'https://www.instagram.com/fwuassicurazioni/',
    },

    'FLL-ES': {
      instagram: 'https://www.instagram.com/forwardyouspain/',
      linkedIn: 'https://www.linkedin.com/company/fwu-spain/ ',
    },
  };

  state = { on: false, handleIcon: '' };

  componentDidMount() {
    // just to be sure
    document.body.classList.remove('menuOpen');

    this.swipe = new Swipable([this.menu, this.swipeBar]);
    this.swipe.on('left', this.close);

    let _getPath = new URLSearchParams(window.location.pathname);
    let _urlResp = _getPath.has('/payments/technical');
    this.setState({ handleIcon: _urlResp });
  }

  componentWillUnmount() {
    document.body.classList.remove('menuOpen');
    this.swipe.off('left', this.close);
  }

  handleToggle = () => {
    if (this.state.on) {
      document.body.classList.add('menuOpen');
    } else {
      document.body.classList.remove('menuOpen');
    }
  };

  toggle = force => {
    this.setState(
      ({ on }) => ({ on: typeof force === 'boolean' ? force : !on }),
      this.handleToggle
    );
  };

  close = () => {
    this.toggle(false);
  };

  policyCommonSeparator = activePolicy => {
    return activePolicy ? <hr className={style.separator} /> : null;
  };

  render() {
    const user = store.getState().data.user.value;
    const { t, clearUser, activePolicy } = this.props;
    const { color } = this.context;
    const { on, handleIcon } = this.state;

    return (
      <>
        {!handleIcon && <HamburgerButton onClick={this.toggle} className={style.mobileOnly} />}
        <Portal>
          <div>
            <div
              className={cc([style.swipeBar, on && style.open, style.mobileOnly])}
              ref={c => (this.swipeBar = c)}
              onClick={this.toggle}
            />
            <TransitionState>
              {transitionState => (
                <div
                  className={cc([
                    style.menu,
                    on && style.open,
                    style[color],
                    style[transitionState],
                  ])}
                  ref={c => (this.menu = c)}
                >
                  <div className={style.content}>
                    <BackButton
                      color="white"
                      className={cc([style.close, style.mobileOnly])}
                      onClick={this.toggle}
                    />
                    <Data keys={['user']}>
                      {([user]) => {
                        return (
                          <>
                            <Hybrid
                              style={{ height: '120px', marginTop: '10px' }}
                              color="white"
                              gender="logo"
                            />
                            <Heading color="white" align="center" size="h3">
                              {user._firstName} {user._lastName}
                            </Heading>
                          </>
                        );
                      }}
                    </Data>
                    <nav className={style.nav}>
                      <MenuGroup>
                        <PolicyDropdown />
                        {activePolicy && (
                          <>
                            <MenuLink
                              activeProps={{ onClick: this.toggle }}
                              href="/dashboard"
                              large
                            >
                              {t('policy.dashboard')}
                            </MenuLink>

                            <MenuLink activeProps={{ onClick: this.toggle }} href="/payments" large>
                              {t('policy.payments')}{' '}
                              <Data
                                keys={[`payments:${activePolicy.contractId}`]}
                                renderErrors={() => null}
                              >
                                {([payments]) => {
                                  return payments.invalidPayment ? (
                                    <Icon type="warning" color="white" />
                                  ) : null;
                                }}
                              </Data>
                            </MenuLink>
                            <MenuLink
                              activeProps={{ onClick: this.toggle }}
                              href="/policy/details"
                              large
                            >
                              {t('policy.detail')}
                            </MenuLink>

                            {activePolicy.alterationAllowed && user.alterationEnabled ? (
                              <MenuLink
                                activeProps={{ onClick: this.toggle }}
                                href="/alteration"
                                large
                              >
                                {t('policy.detail.alterations')}
                              </MenuLink>
                            ) : (
                              ''
                            )}

                            <MenuLink
                              activeProps={{ onClick: this.toggle }}
                              href="/documents"
                              large
                            >
                              {t('policy.documents')}
                            </MenuLink>
                          </>
                        )}
                      </MenuGroup>

                      {this.policyCommonSeparator(activePolicy)}

                      <MenuGroup>
                        <MenuLink activeProps={{ onClick: this.toggle }} href="/messages">
                          {t('messages')}{' '}
                          <Data keys={['unreadMessageCount']} renderErrors={() => null}>
                            {([unreadMessageCount]) =>
                              unreadMessageCount > 0 && (
                                <span className={cc([style.messagesIndicator, style[color]])}>
                                  {unreadMessageCount}
                                </span>
                              )
                            }
                          </Data>
                        </MenuLink>
                        <MenuLink activeProps={{ onClick: this.toggle }} href="/account">
                          {t('settings')}
                          {(() => {
                            const indicatorChecks = [user.isPhoneVerified, user.isTaxIdVerified];
                            return indicatorChecks.every(value => value === true) ? (
                              ''
                            ) : user.alterationEnabled === false ? (
                              ''
                            ) : (
                              <>
                                {user.isTaxIdVerified === false &&
                                user.listOfContracts.length <= 0 ? (
                                  ''
                                ) : (
                                  <span className={cc([style.messagesIndicator, style[color]])}>
                                    !
                                  </span>
                                )}
                              </>
                            );
                          })()}
                        </MenuLink>
                        <MenuLink activeProps={{ onClick: this.toggle }} href="/service">
                          {t('service')}
                        </MenuLink>
                      </MenuGroup>

                      <MenuGroup>
                        <MenuLink onClick={clearUser}>{t('logout')}</MenuLink>
                      </MenuGroup>
                    </nav>

                    <div className={style.footer}>
                      {(() => {
                        let marketSocialPlatforms = this.socialUrls[
                          this.props.activePolicy &&
                            this.props.activePolicy.marketId &&
                            this.props.activePolicy.marketId
                        ];

                        if (!marketSocialPlatforms) {
                          return null;
                        }

                        return Object.keys(marketSocialPlatforms).map(socialPlatform => {
                          let url = marketSocialPlatforms[socialPlatform];
                          return (
                            <div className={style.socialIcon} key={socialPlatform}>
                              {Social(socialPlatform, url, { width: '75%' })}
                            </div>
                          );
                        });
                      })()}
                    </div>
                  </div>
                </div>
              )}
            </TransitionState>
          </div>
        </Portal>
      </>
    );
  }
}

function MenuGroup({ children }) {
  return <div className={style.group}>{children}</div>;
}

function MenuLink({ href, onClick, children, large, activeProps = {} }) {
  const props = {
    className: cc([style.link, large && style.linkLarge]),
    onClick,
  };
  const mergedActiveProps = {
    ...activeProps,
    className: cc([activeProps.className, props.className, style.active]),
  };

  function getProps(props) {
    return ({ isPartiallyCurrent }) =>
      isPartiallyCurrent ? { ...props, ...mergedActiveProps } : props;
  }

  const child = (
    <Heading variant="sans" weight="light" size="h6" color="white">
      {children}
    </Heading>
  );

  if (href) {
    return (
      <Link href={href} getProps={getProps(props)}>
        {child}
      </Link>
    );
  }

  return <button {...props}>{child}</button>;
}

export default connect('activePolicy', actions)(translate(Menu));
