import React, { Component } from 'react';
import translationContext from '../../../containers/translationContext';
import TextUnit from './text';

class PasswordUnit extends Component {
  static contextType = translationContext;
  isValid() {
    return this.comp.isValid();
  }

  render() {
    const { ...props } = this.props;
    const { t } = this.context;

    return (
      <TextUnit
        {...props}
        type="password"
        pattern={/^.{3,}$/}
        errorMessage={t('form.error.password')}
        ref={c => (this.comp = c)}
      />
    );
  }
}

export default PasswordUnit;
