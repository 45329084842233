import React from 'react';

function Facebook(props) {
  return (
    <svg viewBox="0 0 350 350" {...props}>
      <path d="M290.43,216.96c-1.05,21.04-5.86,39.67-21.21,55.02-15.35,15.41-33.98,20.21-55.02,21.21-21.68,1.23-86.66,1.23-108.34,0-21.04-1.05-39.61-5.86-55.02-21.21s-20.21-33.98-21.21-55.02c-1.23-21.68-1.23-86.66,0-108.34,1.05-21.04,5.8-39.67,21.21-55.02s34.04-20.16,55.02-21.15c21.68-1.23,86.66-1.23,108.34,0,21.04,1.05,39.67,5.86,55.02,21.21,15.41,15.35,20.21,33.98,21.21,55.02,1.23,21.68,1.23,86.6,0,108.28Zm-22.73-54.14c0-19.1,1.58-60.12-5.27-77.4-4.63-11.54-13.48-20.39-24.96-24.96-17.23-6.8-58.3-5.27-77.4-5.27s-60.12-1.58-77.4,5.27c-11.54,4.63-20.39,13.48-24.96,24.96-6.8,17.23-5.27,58.3-5.27,77.4s-1.58,60.12,5.27,77.4c4.63,11.54,13.48,20.39,24.96,24.96,17.23,6.8,58.3,5.27,77.4,5.27s60.12,1.58,77.4-5.27c11.54-4.63,20.39-13.48,24.96-24.96,6.86-17.23,5.27-58.3,5.27-77.4Zm-40.31,0c0,37.27-30.06,67.32-67.32,67.32s-67.32-30.06-67.32-67.32,30.06-67.32,67.32-67.32,67.32,30.06,67.32,67.32Zm-23.55,0c0-24.14-19.63-43.77-43.77-43.77s-43.77,19.63-43.77,43.77,19.69,43.77,43.77,43.77,43.77-19.63,43.77-43.77Zm26.31-85.78c-8.67,0-15.7,7.03-15.7,15.7s6.97,15.7,15.7,15.7,15.7-6.97,15.7-15.7-7.03-15.7-15.7-15.7Z" />
    </svg>
  );
}

export default Facebook;
