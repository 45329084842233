import React, { Component } from 'react';
import ConnectedTransition from 'react-connected-transition';
import transitionContext from '../containers/transitionContext';

class ConnectedPageTransition extends Component {
  static contextType = transitionContext;
  state = {
    from: null,
    to: null,
    state: 'pending',
  };
  mounted = true;

  componentWillUnmount() {
    this.mounted = false;
  }

  handleEnter = (from, to) => {
    this.setState({
      from,
      to,
      state: 'entering',
    });
  };

  handleLeave = (from, to) => {
    if (!this.mounted) return;
    this.setState({
      from,
      to,
      state: 'exiting',
    });
  };

  render() {
    const { children, onEnter, onLeave, ...rest } = this.props;
    const transitionState = this.context;
    return (
      <ConnectedTransition
        {...rest}
        onEnter={this.handleEnter}
        onLeave={this.handleLeave}
        exit={transitionState === 'exiting' || transitionState === 'exited'}
      >
        {children(this.state, this.context)}
      </ConnectedTransition>
    );
  }
}

export default ConnectedPageTransition;
