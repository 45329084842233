import React, { Component } from 'react';
import { T, translate } from '../../../containers/translationContext';
import Select from '../inputUnits/select';
import Form from '../../../components/form';
import SubmitButton from '../../button/submit';
import { endpoints } from '../../../config/data';
import TextUnit from '../inputUnits/text';
import InputCouple from '../inputCouple';
import Icon from '../../image/icon';
import { navigate } from '@reach/router';
import { connect } from '../../../containers/storeContext';
import { actions } from '../../../store';
import { preloadDashboard } from '../../../utils/routing';
import Data from '../../../containers/data';
import SendError from '../sendError';
import InputBox from '../inputBox';
import InputIcon from '../inputIcon';
import styles from './style.module.scss';

class UpdateProfileForm extends Component {
  inputRefs = [];
  state = {
    isValid: false,
    isLoadingData: false,
    touched: false,
    success: false,
  };

  handleChange = () => {
    this.setState({
      touched: true,
      success: false,
      isValid: this.inputRefs.every(input => input.isValid()),
    });
  };

  handleSuccess = (response, values) => {
    const { getData, invalidateData, invalidateAllData, changeLanguage } = this.props;
    this.setState({
      isLoadingData: true,
    });

    // If the language changes refetch all data, otherwise only the user
    const promises = values.languageTouched
      ? async () => {
          await changeLanguage(values.language);
          return [invalidateAllData(), preloadDashboard()];
        }
      : () => [invalidateData('user'), getData('user')];

    const update = () => {
      this.setState({
        success: true,
        isLoadingData: false,
      });

      setTimeout(() => {
        navigate('/account');
      }, 1000);
    };

    Promise.all(promises())
      .then(update)
      .catch(update);
  };

  render() {
    const { t, user } = this.props;
    const { touched, isValid, success, isLoadingData } = this.state;

    return (
      <Data keys={['phoneCountryCodes']}>
        {([phoneCountryCodes]) => {
          let phoneCountryCodeValue = '';
          if (user.phoneCountryCode) {
            phoneCountryCodeValue = user.phoneCountryCode;
          } else if (user.languageId) {
            // If there's a language, we should select the country code for that language by default
            const phoneCountryCode = phoneCountryCodes.filter(countryCode => {
              return countryCode.code === user.languageId.toLowerCase();
            });

            // There is, select this country code by default
            if (phoneCountryCode.length) phoneCountryCodeValue = phoneCountryCode[0].key;
          }

          // There isn't, just grab the first one
          if (!phoneCountryCodeValue.length) phoneCountryCodeValue = phoneCountryCodes[0].key;

          return (
            <Data keys={['salutations']}>
              {([salutations]) => {
                return (
                  <Form
                    api={endpoints.updateProfile}
                    initialValues={formatUserValues(user, phoneCountryCodeValue, salutations)}
                    onValueChange={this.handleChange}
                    onSuccess={this.handleSuccess}
                  >
                    {({ submit, isSending, sendError, ...inputProps }) => (
                      <>
                        <InputCouple>
                          <InputCouple.Input width={35}>
                            {
                              <TextUnit
                                name="salutation"
                                label={t('form.label.salutation')}
                                {...inputProps}
                                readonly
                              />
                            }
                          </InputCouple.Input>
                          <InputCouple.Input width={65}>
                            <TextUnit
                              name="firstName"
                              label={t('form.label.firstName')}
                              {...inputProps}
                              readonly
                            />
                          </InputCouple.Input>
                        </InputCouple>

                        <TextUnit
                          name="lastName"
                          label={t('form.label.lastName')}
                          {...inputProps}
                          readonly
                        />

                        <TextUnit
                          name="email"
                          label={t('form.label.email')}
                          {...inputProps}
                          readonly
                        />

                        <TextUnit
                          name="phone"
                          label={t('form.label.phoneNumber')}
                          {...inputProps}
                          readonly
                        />

                        <TextUnit
                          name="taxId"
                          icon="policy"
                          label={t('form.label.taxId')}
                          {...inputProps}
                          readonly
                        />

                        <div style={{ marginBottom: '65px' }}>
                          <Data
                            keys={[`languages:${user.marketId}`]}
                            placeholder={
                              <LanguagesPlaceholder ref={c => (this.inputRefs[4] = c)} />
                            }
                            renderErrors={([salError]) => (
                              <LanguagesPlaceholder
                                error={salError}
                                ref={c => (this.inputRefs[4] = c)}
                              />
                            )}
                          >
                            {([languages]) => {
                              return (
                                <Select
                                  name="language"
                                  label={t('register.languageHeading')}
                                  items={languages}
                                  ref={c => (this.inputRefs[0] = c)}
                                  {...inputProps}
                                />
                              );
                            }}
                          </Data>
                        </div>

                        {user.isAdmin ? (
                          <div className={styles.adminMessage}>
                            Admin is logged in - You cannot save changes!
                          </div>
                        ) : (
                          <>
                            <SendError message={sendError} />
                            <SubmitButton
                              onClick={submit}
                              disabled={isSending || !isValid || isLoadingData}
                              hidden={!touched}
                            >
                              {success ? (
                                <>
                                  {t('form.button.saved')}
                                  <Icon
                                    type="check"
                                    color="white"
                                    style={{ marginTop: '-5px', marginLeft: '5px' }}
                                  />
                                </>
                              ) : (
                                t('form.button.save')
                              )}
                            </SubmitButton>
                          </>
                        )}
                      </>
                    )}
                  </Form>
                );
              }}
            </Data>
          );
        }}
      </Data>
    );
  }
}

function formatUserValues(user, phoneCountryCodeValue, salutations) {
  return {
    salutation: salutations.filter(obj => obj.key === user.salCode)[0].label,
    salCode: user.salCode,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: `${phoneCountryCodeValue} ${user.phoneNo}`,
    phoneNo: user.phoneNo,
    phoneCountryCode: phoneCountryCodeValue,
    email: user.email,
    language: user.languageId.toLowerCase(),
    taxId: user.taxId,
  };
}

class LanguagesPlaceholder extends Component {
  // eslint-disable-next-line
  isValid() {
    return false;
  }

  render() {
    return (
      <InputBox name="language" {...this.props}>
        <InputIcon type="language" hasError={!!this.props.error} />
        {!this.props.error && <T id="loading" />}
      </InputBox>
    );
  }
}

export default connect(null, actions)(translate(UpdateProfileForm));
