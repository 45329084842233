import React from 'react';
import Downshift from 'downshift';
import cc from 'classcat';
import style from './style.module.scss';
import InputBox from '../../inputBox';
import InputIcon from '../../inputIcon';

function Select({
  items,
  value,
  onChange,
  placeholder,
  itemToString = defaultItemToString,
  itemToKey = defaultItemToKey,
  label,
  width = '100%',
  error,
  hasInput,
  noIcon,
  name,
}) {
  const openTowards = 'bottom'; // top, center
  const activeItem = items.filter(i => itemToKey(i) === value)[0] || null;
  const activeIndex = items.indexOf(activeItem);

  return (
    <Downshift
      items={items}
      selectedItem={activeItem}
      defaultHighlightedIndex={activeIndex}
      itemToString={itemToString}
      onChange={onChange}
    >
      {({ getToggleButtonProps, getItemProps, isOpen, selectedItem, highlightedIndex }) => (
        <div>
          <InputBox
            name={name}
            error={error}
            label={label}
            hasInput={hasInput}
            hasIcon={!noIcon}
            width={width}
            disableClickOnLabel
          >
            <button
              className={cc([style.button, isOpen && style.open])}
              id={name}
              {...getToggleButtonProps()}
              aria-haspopup="true"
              aria-expanded={isOpen}
            >
              {!noIcon && <InputIcon type={name} hasInput={hasInput} hasError={!!error} />}
              <span>{(selectedItem && itemToString(selectedItem)) || placeholder || label}</span>
            </button>
            {isOpen && (
              <div className={cc([style.options, style[openTowards]])}>
                {items.map((item, index) => (
                  <div
                    {...getItemProps({ item })}
                    className={cc([
                      style.item,
                      highlightedIndex === index && style.isFocussed,
                      selectedItem &&
                        itemToKey(selectedItem) === itemToKey(item) &&
                        style.isSelected,
                    ])}
                    key={itemToKey(item)}
                  >
                    {itemToString(item)}
                  </div>
                ))}
              </div>
            )}
          </InputBox>
        </div>
      )}
    </Downshift>
  );
}

function defaultItemToString(item) {
  return item && item.label;
}

function defaultItemToKey(item) {
  return item && item.key;
}

export default Select;
