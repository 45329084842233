import React from 'react';
import cc from 'classcat';
import style from './style.module.scss';

function InputError({ children, hasIcon, className, ...rest }) {
  return (
    <div className={cc([style.error, hasIcon && style.hasIcon, className])} {...rest}>
      {/* cross icon <Icon /> */}
      {children}
    </div>
  );
}

export default InputError;
