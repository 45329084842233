import { connect } from '../containers/storeContext';
import formatMoney from '../utils/formatMoney';

function Money({ value, currency, children, language }) {
  const formattedMoney = formatMoney(value, currency, language);

  const { v, big, small, decimals, symbol } = formattedMoney;

  return children({ symbol, value: v, big, small, decimals, rounded: `${big}${small}` });
}

const mapStateToProps = (state, ownProps) => {
  let { language } = state;

  if (language === 'es') {
    language = 'de';
  }

  return {
    language,
  };
};

export default connect(mapStateToProps)(Money);
