import React, { Component } from 'react';
import Form from '../index';
import { endpoints } from '../../../config/data';
import EmailUnit from '../inputUnits/email';
import Button from '../../button/submit';
import InputGroup from '../inputGroup';
import Paragraph from '../../paragraph';
import { translate } from '../../../containers/translationContext';
import SendError from '../sendError';

class ResendEmailForm extends Component {
  state = {
    success: false,
    isValid: false,
  };

  handleChange = () => {
    this.setState({
      isValid: this.email.isValid(),
    });
  };

  handleSuccess = () => {
    this.setState({ success: true });
  };

  renderForm = t => (
    <Form
      api={endpoints.resendEmail}
      onSuccess={this.handleSuccess}
      onValueChange={this.handleChange}
      initialValues={{
        processName: 'REGISTRATION',
      }}
    >
      {({ submit, isSending, sendError, ...inputProps }) => (
        <>
          <Paragraph>{t('activate.failed')}</Paragraph>
          <InputGroup>
            <EmailUnit
              name="loginId"
              label={t('form.label.email')}
              ref={c => (this.email = c)}
              {...inputProps}
            />
          </InputGroup>
          <SendError message={sendError} />
          <Button onClick={submit} disabled={isSending || !this.state.isValid} noMenu>
            {t('form.button.resend')}
          </Button>
        </>
      )}
    </Form>
  );

  renderSuccess = t => <Paragraph>{t('activate.resent')}</Paragraph>;

  render() {
    const { t } = this.props;
    return <div>{this.state.success ? this.renderSuccess(t) : this.renderForm(t)}</div>;
  }
}

export default translate(ResendEmailForm);
