import React from 'react';
import { oneOf } from 'prop-types';
import Arrow from './arrow';
import Back from './back';
import Calendar from './calendar';
import Check from './check';
import Close from './close';
import Country from './country';
import Document from './document';
import Download from './download';
import Duration from './duration';
import Warning from './warning';
import Language from './language';
import Mail from './mail';
import Market from './market';
import More from './more';
import Password from './password';
import Phone from './phone';
import Policy from './policy';
import Profile from './profile';
import EnvelopeOpen from './envelopeopen';
import DotsMenu from './dotsmenu';
import style from './style.module.scss';
import cc from 'classcat';

const icons = {
  smsToken: Password,
  activationKey: Password,
  arrow: Arrow,
  back: Back,
  calendar: Calendar,
  check: Check,
  close: Close,
  country: Country,
  nationality: Language,
  deliveryMode: Password,
  document: Document,
  download: Download,
  duration: Duration,
  email: Mail,
  emailConfirm: Mail,
  firstName: Profile,
  language: Language,
  lastName: Profile,
  loginId: Mail,
  mail: Mail,
  marketId: Market,
  mobileNo: Phone,
  more: More,
  newLoginId: Mail,
  newPassword: Password,
  newPasswordConfirm: Password,
  oldLoginId: Mail,
  oldPassword: Password,
  password: Password,
  passwordConfirm: Password,
  phone: Phone,
  phoneCountryCode: Market,
  phoneCountryCodePlaceholder: Phone,
  phoneNo: Phone,
  policy: Policy,
  profile: Profile,
  salCode: Profile,
  salutation: Profile,
  warning: Warning,
  beneficiarySelectInCaseOfDeath: Profile,
  beneficiarySelectInCaseOfSurvival: Profile,
  paymentMethod: Document,
  paymentFrequency: Document,
  readAll: EnvelopeOpen,
  dotsMenu: DotsMenu,
};

Icon.propTypes = {
  type: oneOf(Object.keys(icons)).isRequired,
};

function Icon({ type, className, color = 'none', hasInput, hasError, stroke, ...rest }) {
  if (!(type in icons)) {
    console.warn(`Invalid type ${type} supplied to <Icon />`);
  }
  const IconComp = icons[type];

  return (
    <IconComp
      {...rest}
      color={color}
      className={cc([
        style.icon,
        stroke && style.stroke,
        color && style[color],
        hasInput && style.hasInput,
        hasError && style.hasError,
        className,
      ])}
    />
  );
}

export default Icon;
