import React, { createContext } from 'react';

const routeContext = createContext({});
const { Provider, Consumer } = routeContext;

function RoutePropsProvider({ childs, ...props }) {
  return <Provider value={props}>{childs}</Provider>;
}

function withRouteProps(Wrapped) {
  function C() {
    return <Consumer>{props => <Wrapped {...this.props} {...props} />}</Consumer>;
  }

  return C;
}

export default routeContext;
export { withRouteProps, RoutePropsProvider, Consumer as RouteProps };
