import React from 'react';
import { Redirect } from '@reach/router';
import IsAuthorized from './isAuthorized';
import Page from '../components/page';

function ProtectedPage({ children, dontRenderPage, ...rest }) {
  return (
    <IsAuthorized
      renderYes={() => (dontRenderPage ? <>{children}</> : <Page {...rest}>{children}</Page>)}
      renderNo={() => <Redirect to="/login" noThrow />}
    />
  );
}
export default ProtectedPage;
