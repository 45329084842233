import React, { Component } from 'react';
import anime from 'animejs';
import LoginForm from '../components/form/forms/login';
import Header from '../components/header';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import { connect } from '../containers/storeContext';
import { actions } from '../store';
import Logo from '../components/image/logo';
import { T } from '../containers/translationContext';
import Heading from '../components/heading';
import Box from '../components/box';
import AnimationContainer from '../components/animation/container';
import AnimationItem from '../components/animation/item';

let animated = false;

class Login extends Component {
  state = { animated };
  constructor(props) {
    super();
    props.clearUser();
  }

  componentDidMount() {
    if (animated) return;
    const { height, top } = this.logo.getBoundingClientRect();
    const wh = window.innerHeight;
    this.logo.style.transform = `translateY(${wh / 2 - top - height / 2}px)`;
    anime({
      targets: this.logo,
      translateY: 0,
      easing: 'easeInOutQuart',
      duration: 600,
      delay: 400,
      begin: this.animation.animate,
      complete: () => {
        if (!this.logo) return;
        this.logo.style = '';
      },
    });
    animated = true;
  }

  render() {
    let greetingLangId = 'goodMorning';
    const currentHour = new Date().getHours();

    if (currentHour >= 12 && currentHour < 18) {
      greetingLangId = 'goodAfternoon';
    } else if (currentHour >= 18) {
      greetingLangId = 'goodEvening';
    } else if (currentHour < 6) {
      greetingLangId = 'goodNight';
    }

    return (
      <NarrowColumn>
        <AnimationContainer
          disabled={this.state.animated}
          waitForTrigger
          ref={c => (this.animation = c)}
        >
          <Header right="register" />
          <div ref={c => (this.logo = c)}>
            <Box justify="center" width="50%" style={{ maxWidth: '160px' }}>
              <Logo fullWidth />
            </Box>
          </div>
          <AnimationItem type="slide">
            <Heading size="h2">
              <T id={greetingLangId} />
            </Heading>
          </AnimationItem>
          <LoginForm buttonRef={c => (this.button = c)} />
        </AnimationContainer>
      </NarrowColumn>
    );
  }
}

export default connect(null, actions)(Login);
