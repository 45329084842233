import React from 'react';
import style from './success.module.scss';
import Heading from '../../../heading';
import Paragraph from '../../../paragraph';
import { translate } from '../../../../containers/translationContext';
import SubmitButton from '../../../button/submit';
import Header from '../../../header';
import BackButton from '../../../../components/button/back';

function ErrorCombinePolicies({ t, message, nextStep }) {
  return (
    <>
      <Header left={<BackButton href="/" color="darkPetrol" />} />
      <div className={style.container}>
        <Heading size="h1">{t('form.error.heading')}</Heading>
        <Paragraph>{message}</Paragraph>
      </div>
      <SubmitButton
        onClick={() => {
          nextStep();
        }}
        noMenu
      >
        {t('form.button.confirm')}
      </SubmitButton>
    </>
  );
}

export default translate(ErrorCombinePolicies);
