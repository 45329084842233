import React from 'react';
import style from './success.module.scss';
import Heading from '../../../heading';
import Paragraph from '../../../paragraph';
import { translate } from '../../../../containers/translationContext';
import SubmitButton from '../../../button/submit';

function ErrorIncompleteData({ t, message }) {
  return (
    <>
      <div className={style.container}>
        <Heading size="h1">{t('form.error.heading')}</Heading>
        <Paragraph>{message}</Paragraph>
      </div>
      <SubmitButton href="/" noMenu>
        {t('form.error.backHome')}
      </SubmitButton>
    </>
  );
}

export default translate(ErrorIncompleteData);
