import React from 'react';
import Heading from '../heading';
import HTML from '../html';
import style from './answer.module.scss';

function Answer({ question, answer }) {
  return (
    <>
      <Heading variant="sans">{question}</Heading>
      <HTML className={style.answer}>{answer}</HTML>
    </>
  );
}

export default Answer;
