import React, { Component } from 'react';
import AccountDeleteForm from '../components/form/forms/deleteAccount';
import Heading from '../components/heading';
import CloseButton from '../components/button/close';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Hybrid from '../components/image/hybrid';
import Header from '../components/header';
import { translate } from '../containers/translationContext';
import Paragraph from '../components/paragraph';
import ReplaceTransition from '../components/transition/replace';
import InPageTransition from '../components/transition/inPage';
import TextLink from '../components/textLink';
import Data from '../containers/data';

class Delete extends Component {
  state = { completed: false };

  render() {
    const { t } = this.props;

    return (
      <NarrowColumn>
        <ReplaceTransition in={!this.state.completed}>
          <InPageTransition leave="left" enter="left">
            <Header right={<CloseButton href="/account" color="darkPetrol" />} />
            <Data keys={['user']}>
              {([user]) => (
                <AccountDeleteForm
                  onSuccess={() => this.setState({ completed: true })}
                  user={user}
                />
              )}
            </Data>
          </InPageTransition>
          <InPageTransition enter="right" leave="right">
            <Header />
            <Hybrid />
            <Heading>{t('delete.confirmHeading')}</Heading>
            <Paragraph>{t('delete.confirmBody')}</Paragraph>
            <Paragraph>{t('register.successResend')}</Paragraph>
            <TextLink onClick={() => this.setState({ completed: false })}>
              {t('form.button.resend')}
            </TextLink>
          </InPageTransition>
        </ReplaceTransition>
      </NarrowColumn>
    );
  }
}

export default translate(Delete);
