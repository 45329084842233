import React from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import PasswordResetForm from '../components/form/forms/passwordReset';
import BackButton from '../components/button/back';
import Heading from '../components/heading';
import Hybrid from '../components/image/hybrid';
import Header from '../components/header';
import { translate } from '../containers/translationContext';

function Reset({ t }) {
  return (
    <NarrowColumn>
      <Header left={<BackButton href="/" color="darkPetrol" />} right="register" />
      <Hybrid />
      <Heading>{t('reset.link')}</Heading>
      <PasswordResetForm />
    </NarrowColumn>
  );
}

export default translate(Reset);
