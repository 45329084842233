import React from 'react';
import style from './style.module.scss';
import cc from 'classcat';

function Phone({ className, ...props }) {
  return (
    <svg viewBox="0 0 55 55" {...props} className={cc([className, style.stroke])}>
      <path d="M1.9,18.5c0,0,6.8,19.1,30,31.3c2.3,1.2,4.9,1.8,7.5,1.6c1.8-0.2,3.7-0.7,5.2-2.2l3.6-3.6c0,0,3-3-2.4-6  L41,35.9c0,0-3.6-2.4-6,0l-2.8,2.8c-0.8,0.8-2,1.1-3,0.8s-2.3-0.9-3.8-2.4c-2.6-2.6-7-7.6-9.3-10.3c-2-2.3-1.7-5.9,0.8-7.7L17,19  c4.9-3.5,0-8.4,0-8.4L13.4,7c0,0-3-3-6-1.2C4.4,7.6,0.1,13.1,1.9,18.5z" />
    </svg>
  );
}

export default Phone;
