import React, { Component } from 'react';
import { bool, func, node, object, oneOfType, string } from 'prop-types';
import bindInputState from '../../../utils/bindInputState';
import TextInput from '../inputs/text';

class TextUnit extends Component {
  static propTypes = {
    name: string.isRequired,
    errors: object.isRequired,
    values: object.isRequired,
    setError: func.isRequired,
    setValueState: func.isRequired,
    pattern: oneOfType([string, object]),
    errorMessage: oneOfType([string, node]),
    optional: bool,
    type: string,
    onChange: func,
    onBlur: func,
    tooltip: string,
  };

  static defaultProps = {
    type: 'text',
    onChange: () => {},
    pattern: /^.{1,}$/,
    onBlur: () => {},
  };

  state = {
    regex: new RegExp(this.props.pattern),
  };

  handleChange = () => {
    const { name, setError, onChange } = this.props;

    if (this.isValid()) {
      setError(name, null);
    }

    onChange();
  };

  handleBlur = () => {
    const { name, setError, onBlur } = this.props;

    if (this.isValid()) {
      setError(name, null);
    } else {
      setError(name, this.props.errorMessage);
    }

    onBlur();
  };

  isValid = () => {
    let value = this.props.values[this.props.name];
    value = value === undefined ? '' : value;

    if (this.props.optional && !value) {
      return true;
    }

    return this.state.regex.test(value);
  };

  render() {
    const { setError, name, values, setValueState, ...rest } = this.props;

    return (
      <TextInput
        {...bindInputState(values, setValueState, name, 'target.value', {
          ...rest,
          onChange: this.handleChange,
          onBlur: this.handleBlur,
        })}
      />
    );
  }
}

export default TextUnit;
