import delve from 'dlv';

export default function bindInputState(
  state,
  setState,
  key,
  eventKey,
  { onChange, errors = {}, error, ...otherProps }
) {
  return {
    name: key,
    value: state[key],
    hasInput: !!state[key] && state[key].length > 0,
    error: error || errors[key],
    onChange: e => {
      const value = typeof eventKey === 'function' ? eventKey(e) : delve(e, eventKey);
      setState(
        {
          [key]: value,
          // Used by the backend of some forms to see which values have changed.
          // Also used by some input units to see if a value has been changed.
          [`${key}Touched`]: true,
        },
        () => {
          if (onChange) {
            onChange(value, e);
          }
        }
      );
    },
    ...otherProps,
  };
}
