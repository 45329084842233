import React, { Component } from 'react';
import style from './style.module.scss';
import Chart from 'chart.js';
import { Bar } from 'react-chartjs-2';
import formatMoney from '../../utils/formatMoney';
import { connect } from '../../containers/storeContext';
import SYMBOLS from '../../config/currencySymbols';
import ContentGrid from '../contentGrid';
import { translate } from '../../containers/translationContext';
import cc from 'classcat';

let chartData = null;
let chartOptions = null;
let chartPlugins = null;

/**
 * Line chart for on the explanation pages
 */
class LineChart extends Component {
  state = {
    scrolled: false,
  };

  chartContainer = null;
  chartWrapper = null;

  constructor(props) {
    super();

    // Simply do not render the graph for the time being
    return; // TODO: remove this

    /**
     * DATA RETRIEVAL FOR GRAPH
     */
    // Get data from props
    const { policy, t, language, yearData, section } = props;
    const { contract } = policy;
    const { maturityGuarantee } = yearData;
    let { actualPolicyValue, currentYear, simulatedCurrentValue } = section.value;

    // Always increase the currentYear by 1
    // Since in the first year the actual currentYear is 0
    // But we want it to be 1
    currentYear++;

    // TODO: get this from the above section.value once it's ready on the API side
    // The simulated maturity value is in projected values
    let simulatedMaturityValue =
      maturityGuarantee.values[maturityGuarantee.values.length - 1].value;

    // Can't do anything without this data
    if (yearData === null || contract === null) return;

    // Get necessary data from contract & yearData
    let { _contractTermActual, _currency } = contract;
    let totalYears = _contractTermActual.value;

    // Last but not least: we need a symbol for the currency
    const currencySymbol = SYMBOLS[_currency.value];

    // At least the current year, simulated maturity value and total years
    // Must not to be 0 to make the graph work
    if (currentYear < 1 || totalYears < 1 || simulatedMaturityValue < 1) return;

    /**
     * DATA VALIDATION
     */
    // All of the figures must be a number for the graph to work
    if (
      isNaN(currentYear) ||
      isNaN(totalYears) ||
      isNaN(simulatedMaturityValue) ||
      isNaN(simulatedCurrentValue) ||
      isNaN(actualPolicyValue)
    )
      return;

    // Cast to ints or floats accordingly
    totalYears = parseInt(totalYears);
    actualPolicyValue = parseFloat(this.parseNumber(actualPolicyValue));
    simulatedCurrentValue = parseFloat(this.parseNumber(simulatedCurrentValue));
    simulatedMaturityValue = parseFloat(this.parseNumber(simulatedMaturityValue));

    /**
     * FINAL DATA FOR GRAPH
     */
    // Define some variables
    const colors = {
      actualPolicyValue: '#002f36',
      simulatedCurrentValue: '#e9e566',
      simulatedMaturityValue: '#00879b',
      white: '#f9f9f9',
    };
    const font = "'Brandon Text Light', sans-serif";

    // Determine the position of the figures drawn by the plugin
    const actualPolicyValueHigher = actualPolicyValue > simulatedCurrentValue;

    // Chart data
    // Defines the data that needs to be in the chart
    chartData = {
      datasets: [
        {
          label: t('chart.actualPolicyValue'),
          topFigure: actualPolicyValueHigher,
          type: 'line',
          data: [{ x: currentYear, y: actualPolicyValue }],
          pointBorderWidth: 5,
          borderWidth: 1,
          showLine: false,
          fill: false,
          borderColor: colors['actualPolicyValue'],
          backgroundColor: colors['actualPolicyValue'],
          yAxisID: 'y-axis',
        },
        {
          label: t('chart.simulatedCurrentValue'),
          topFigure: !actualPolicyValueHigher,
          type: 'line',
          data: [{ x: currentYear, y: simulatedCurrentValue }],
          pointBorderWidth: 5,
          borderWidth: 1,
          showLine: false,
          fill: false,
          backgroundColor: colors['simulatedCurrentValue'],
          borderColor: colors['simulatedCurrentValue'],
          yAxisID: 'y-axis',
        },
        {
          label: t('chart.simulatedMaturityValue'),
          type: 'line',
          data: [
            { x: 0, y: 0 },
            { x: totalYears, y: simulatedMaturityValue },
          ], // TODO: Maybe you need this Onno
          pointBorderWidth: 5,
          fill: false,
          borderWidth: 1,
          backgroundColor: colors['simulatedMaturityValue'],
          borderColor: colors['simulatedMaturityValue'],
          pointHoverBackgroundColor: ['transparent', colors['simulatedMaturityValue']],
          pointHoverBorderColor: ['transparent', colors['simulatedMaturityValue']],
          pointBackgroundColor: ['transparent', colors['simulatedMaturityValue']],
          pointBorderColor: ['transparent', colors['simulatedMaturityValue']],
          yAxisID: 'y-axis',
        },
      ],
    };

    // Chart options
    // Defines styling and functionality of the chart
    chartOptions = {
      responsive: true,
      hover: {
        mode: 'x',
      },
      tooltips: {
        enabled: false,
      },
      elements: {
        line: {
          fill: false,
        },
      },
      scales: {
        xAxes: [
          {
            type: 'linear',
            display: true,
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false,
              zeroLineColor: colors['white'],
              color: colors['white'],
              tickMarkLength: 13,
            },
            ticks: {
              display: true,
              stepSize: 5,
              maxTicksLimit: 1000,
              beginAtZero: true,
              fontColor: colors['white'],
              fontFamily: font,
              fontSize: 14,
              padding: 10,
              max: totalYears,
            },
            scaleLabel: {
              labelString: t('chart.xAxisLabel'),
              display: true,
              fontColor: colors['white'],
              fontFamily: font,
              fontSize: 14,
            },
          },
        ],
        yAxes: [
          {
            type: 'linear',
            display: true,
            color: colors['white'],
            position: 'left',
            id: 'y-axis',
            gridLines: {
              display: true,
              drawTicks: true,
              color: colors['white'],
              zeroLineColor: colors['white'],
              drawOnChartArea: false,
              tickMarkLength: 10,
            },
            ticks: {
              display: true,
              fontColor: colors['white'],
              fontFamily: font,
              fontSize: 14,
              padding: 8,
              // stepSize: 1000,  // TODO: Maybe you need this Onno
              // beginAtZero: true, // TODO: Maybe you need this Onno
              callback: function(value, index, values) {
                const string = `${value}`;
                return value > 999 ? string.substring(0, string.length - 3) : value;
              },
            },
            scaleLabel: {
              labelString: `${currencySymbol} ${t('chart.yAxisLabel')}`,
              display: true,
              fontColor: colors['white'],
              fontFamily: font,
              fontSize: 14,
            },
          },
        ],
      },
      legend: {
        position: 'bottom',
        onClick: function(e, legendItem) {},
        labels: {
          usePointStyle: true,
          fontColor: colors['white'],
          fontFamily: font,
          padding: 30,
          fontSize: 14,
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 80,
          top: 10,
          bottom: 10,
        },
      },
    };

    // Chart plugins
    // For features that aren't in the chart.js library by default
    chartPlugins = [
      {
        afterDatasetsDraw: function(chart) {
          let ctx = chart.ctx;

          // Draw the value of the points above the point
          chart.data.datasets.forEach(function(dataset, i) {
            let meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
              meta.data.forEach(function(element, index) {
                // If the Y value (money) is 0, no label needed
                // TODO: Maybe you need this Onno
                if (dataset.data[index].y === 0) return;
                // Color of the text
                ctx.fillStyle = colors['white'];

                // Set the font
                const fontSize = 14;
                const fontStyle = 'normal';
                ctx.font = Chart.helpers.fontString(fontSize, fontStyle, font);

                // Format the money
                const formattedMoney = formatMoney(
                  dataset.data[index].y,
                  _currency.value,
                  language
                );
                const { big, small, decimals, symbol } = formattedMoney;

                // The text to draw
                let string = `${symbol} ${big}${small}${
                  decimals !== '.00' && decimals !== ',00' ? decimals : ''
                }`;

                // Text alignment
                ctx.textAlign = 'left';
                ctx.textBaseline = 'middle';

                let position = element.tooltipPosition();

                // Base y on "topFigure" property
                let y = position.y;
                if (dataset.hasOwnProperty('topFigure')) {
                  y = dataset.topFigure ? y - 10 : y + 10;
                }

                ctx.fillText(string, position.x + 10, y);
              });
            }
          });
        },
      },
    ];
  }

  parseNumber(amount) {
    let result;

    // If number ends with .00 (1,500.00)
    if (/\.[0-9]{2}$/g.test(amount)) {
      result = amount.replace(/,/g, '');
    } else {
      // If number ends with ,00 (1.500,00)
      result = amount.replace(/\./g, '');
    }

    return result;
  }

  /**
   * Handle scrolling and show the "scroll indicator"
   * only when not fully scrolled to the right
   * @param e
   */
  scrollHandler = e => {
    const { scrolled } = this.state;

    const chartWrapperBounds = this.chartWrapper.getBoundingClientRect();
    const chartContainerBounds = this.chartContainer.getBoundingClientRect();
    const scrollRemaining =
      chartWrapperBounds.width -
      chartContainerBounds.width -
      (chartContainerBounds.left - chartWrapperBounds.left);

    // Check if we scrolled all the way to the right
    if (!scrolled && scrollRemaining >= -100) {
      this.setState({ scrolled: true });
    } else if (scrolled && scrollRemaining < -100) {
      this.setState({ scrolled: false });
    }
  };

  render() {
    const { scrolled } = this.state;

    if (chartData === null || chartOptions === null || chartPlugins === null) return '';

    return (
      <ContentGrid>
        {({ getFullContentProps, getNarrowContentProps }) => (
          <div {...getFullContentProps()}>
            <div className={style.scrollContainer}>
              <div
                className={style.chartWrapper}
                onScroll={this.scrollHandler}
                ref={c => (this.chartWrapper = c)}
              >
                <div className={style.chartContainer} ref={c => (this.chartContainer = c)}>
                  <Bar data={chartData} options={chartOptions} plugins={chartPlugins} />
                </div>
              </div>
              <div className={cc([style.scrollIndicator, scrolled && style.hide])} />
            </div>
          </div>
        )}
      </ContentGrid>
    );
  }
}

export default connect('language')(translate(LineChart));
