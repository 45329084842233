import React from 'react';

function Document({ ...props }) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <path
        d="M36,12.2h-3V9.4c0-0.6-0.4-1-1-1H13c-0.6,0-1,0.4-1,1v27.8c0,0.6,0.4,1,1,1h2.8V41c0,0.6,0.4,1,1,1h15.5
		c0.3,0,0.5-0.1,0.7-0.3l3.7-4c0.2-0.2,0.3-0.4,0.3-0.7V13.2C37,12.6,36.6,12.2,36,12.2z M14,36.2V10.4h17v1.8H16.8
		c-0.6,0-1,0.4-1,1v23H14z M17.8,14.2H35v22h-2.8c-0.6,0-1,0.4-1,1V40H17.8V14.2z"
      />
      <path d="M21.6,21.9h9.6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-9.6c-0.6,0-1,0.4-1,1S21,21.9,21.6,21.9z" />
      <path d="M21.6,27.6h9.6c0.6,0,1-0.4,1-1s-0.4-1-1-1h-9.6c-0.6,0-1,0.4-1,1S21,27.6,21.6,27.6z" />
      <path d="M31.2,33.4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-9.6c-0.6,0-1,0.4-1,1s0.4,1,1,1H31.2z" />
    </svg>
  );
}

export default Document;
