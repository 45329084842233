import React from 'react';
import ContentGrid from './index';

function NarrowColumn({ children, ...rest }) {
  return (
    <ContentGrid>
      {({ getNarrowContentProps }) => <div {...getNarrowContentProps(rest)}>{children}</div>}
    </ContentGrid>
  );
}

export default React.memo(NarrowColumn);
