import React from 'react';

function More({ ...props }) {
  return (
    <svg x="0px" y="0px" viewBox="0 0 180 55" {...props}>
      <g>
        <line x1="6.7" y1="28" x2="173.3" y2="28" />
      </g>
      <g>
        <line x1="154.2" y1="12" x2="174.1" y2="28" />
        <line x1="174.1" y1="28" x2="154.2" y2="44" />
      </g>
    </svg>
  );
}

export default More;
