import React, { Component, createRef } from 'react';
import anime from 'animejs';
import { bool, number } from 'prop-types';
import { setCSS, shouldDisableCoolStuff } from '../../utils/animation';

const types = {
  slide: {
    from: { translateX: '-105%' },
    to: { translateX: 0 },
  },
  scale: {
    from: { scale: 0 },
    to: { scale: 1 },
  },
  fade: {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
};

class AnimationContainer extends Component {
  static propTypes = {
    disabled: bool,
    waitForTrigger: bool,
    delay: number,
  };
  static defaultProps = {
    delay: 0,
  };

  ref = createRef();

  componentDidMount() {
    if (this.props.disabled || shouldDisableCoolStuff()) return;
    this.ref.current.style.overflow = 'hidden';
    const arr = Array.from(this.ref.current.querySelectorAll('[data-animation]'));
    arr.forEach(node => {
      const type = node.dataset.animation;
      setCSS(node, types[type].from);
    });

    if (this.props.waitForTrigger) return;
    this.animate();
  }

  animate = () => {
    if (!this.ref.current || this.props.disabled || shouldDisableCoolStuff()) return;
    const arr = Array.from(this.ref.current.querySelectorAll('[data-animation]'));

    const tl = anime.timeline();

    arr.forEach((node, i) => {
      const type = node.dataset.animation;
      tl.add({
        targets: node,
        easing: 'easeInOutQuart',
        ...types[type].to,
        offset: this.props.delay + i * 100,
      });
    });

    tl.play();
    tl.complete = () => {
      if (this.ref.current) this.ref.current.style.overflow = '';
    };
  };

  render() {
    const { children } = this.props;
    return <div ref={this.ref}>{children}</div>;
  }
}

export default AnimationContainer;
