import React from 'react';
import Icon from '../image/icon/index';

function MessageIcon({ type, read }) {
  switch (type) {
    case 'error':
      return <Icon type="warning" hasError={!read} />;
    case 'download':
    default:
      return <Icon type="email" color={read ? 'none' : 'petrol'} />;
  }
}

export default MessageIcon;
