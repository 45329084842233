import mitt from 'mitt';

class Swipable {
  static defaultOpts = {
    thresholdPixels: 1,
    thresholdPercent: null,
  };

  x = null;
  y = null;
  maxY = window.innerHeight;

  isScrolling = null;
  index = false;
  activeIndex = false;
  timer = null;
  isOwnEmitter = false;

  constructor(nodes, opts) {
    this.nodesArray = Array.isArray(nodes) ? nodes : [nodes];
    const bounds = this.nodesArray[0].getBoundingClientRect();
    this.opts = { ...Swipable.defaultOpts, ...opts, thresholdPixels: 0.2 * bounds.width, bounds };

    this.emitter = this.opts.emitter;

    if (this.opts.emitter) {
      this.index = this.opts.index;
      this.emitter = this.opts.emitter;
      this.isOwnEmitter = true;
    } else {
      this.emitter = mitt();
    }

    // this.opts = { ...Swipable.defaultOpts, ...opts };
    this.nodesArray.forEach(node => {
      node.addEventListener('touchstart', this.start, { passive: true });
      node.addEventListener('touchmove', this.move, { passive: true });
      node.addEventListener('touchend', this.end, { passive: true });
    });
  }

  on = (event, f) => {
    this.emitter.on(event, f);
  };

  off = (event, f) => {
    this.emitter.off(event, f);
  };

  start = e => {
    this.x = e.touches[0].pageX;
    this.y = e.touches[0].pageY;
    this.isScrolling = null;

    this.emitter.emit('start', this.index);
  };

  move = e => {
    if (e.touches.length > 1 || (e.scale && e.scale !== 1)) return;

    const touch = e.touches[0];

    const delta = {
      x: touch.pageX - this.x,
      y: touch.pageY - this.y,
    };

    if (delta.x > this.opts.thresholdPixels) this.emitter.emit('right');
    if (delta.x < -this.opts.thresholdPixels) this.emitter.emit('left');

    if (delta.y > this.opts.thresholdPixels && delta.y < this.maxY) {
      if (this.isOwnEmitter) this.emitter.emit('down', { y: delta.y, index: this.opts.index });
    }

    if (delta.y < -this.opts.thresholdPixels && delta.y > -this.maxY) {
      if (this.isOwnEmitter) this.emitter.emit('up', { y: delta.y, index: this.opts.index });
    }
  };

  end = e => {
    if (this.isOwnEmitter) this.emitter.emit('end', this.index);
  };
}

export default Swipable;
