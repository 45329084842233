import React, { Component } from 'react';
import { translate } from '../../../containers/translationContext';
import Form from '../../../components/form';
import SubmitButton from '../../../components/button/submit';
import { endpoints } from '../../../config/data';
import Icon from '../../image/icon';
import EmailUnit from '../inputUnits/email';
import PasswordUnit from '../inputUnits/password';
import Paragraph from '../../paragraph';
import Heading from '../../heading';
import Thumb from '../../image/thumb';
import TextLink from '../../textLink';
import SendError from '../sendError';
import styles from './style.module.scss';

class ChangeEmailForm extends Component {
  inputRefs = [];
  state = {
    isValid: false,
    touched: true,
    success: false,
  };

  handleChange = () => {
    this.setState({
      success: false,
      isValid: this.inputRefs.every(input => input.isValid()),
      touched: true,
    });
  };

  handleSuccess = () => {
    this.setState({
      success: true,
    });
  };

  resendHandler = () => {
    this.setState({
      success: false,
    });
  };

  renderForm = () => {
    const { t, user } = this.props;
    const { touched, isValid, success } = this.state;
    const initialValues = {
      oldLoginId: user._email,
      newLoginId: '',
      password: '',
    };

    return (
      <>
        <Heading size="h4">{t('settings.email')}</Heading>
        <Paragraph>{t('email.body')}</Paragraph>
        <Form
          api={endpoints.changeEmail}
          onValueChange={this.handleChange}
          onSuccess={this.handleSuccess}
          initialValues={initialValues}
        >
          {({ submit, isSending, sendError, ...inputProps }) => (
            <>
              <EmailUnit
                name="oldLoginId"
                label={t('form.label.currentEmail')}
                ref={c => (this.inputRefs[0] = c)}
                {...inputProps}
              />
              <EmailUnit
                name="newLoginId"
                label={t('form.label.newEmail')}
                ref={c => (this.inputRefs[1] = c)}
                {...inputProps}
              />
              <PasswordUnit
                name="password"
                label={t('form.label.password')}
                ref={c => (this.inputRefs[2] = c)}
                {...inputProps}
              />
              {user.isAdmin ? (
                <div className={styles.adminMessage}>
                  Admin is logged in - You cannot save changes!
                </div>
              ) : (
                <>
                  <SendError message={sendError} />
                  <SubmitButton onClick={submit} disabled={isSending || !isValid} hidden={!touched}>
                    {success ? (
                      <>
                        {t('form.button.saved')}
                        <Icon
                          type="check"
                          color="white"
                          style={{ marginTop: '-5px', marginLeft: '5px' }}
                        />
                      </>
                    ) : (
                      t('form.button.save')
                    )}
                  </SubmitButton>
                </>
              )}
            </>
          )}
        </Form>
      </>
    );
  };

  renderSuccess = () => {
    const { t } = this.props;

    return (
      <>
        <Heading size="h2" align="center">
          {t('email.successHeading')}
        </Heading>
        <Thumb />
        <Paragraph style={{ marginBottom: '40px' }}>{t('email.successBody')}</Paragraph>
        <Paragraph size="small">
          {t('register.successResend')}{' '}
          <TextLink onClick={this.resendHandler}>{t('form.button.resend')}</TextLink>
        </Paragraph>
      </>
    );
  };

  render() {
    return this.state.success ? this.renderSuccess() : this.renderForm();
  }
}

export default translate(ChangeEmailForm);
