import React, { Component } from 'react';
import NarrowColumn from '../contentGrid/narrowColumn';
import Heading from '../heading';
import Header from '../header';
import CloseButton from '../button/close';
import Paragraph from '../paragraph';
import Time from '../time';
import MessageIcon from './messageIcon';
import { connect } from '../../containers/storeContext';
import { actions } from '../../store';
import HTML from '../html';
import { authenticateEndpoint } from '../../utils/request';
import { endpoints } from '../../config/data';
import { T } from '../../containers/translationContext';

class ActiveMessage extends Component {
  componentDidMount() {
    const { read, messageId, contractId } = this.props;
    const { getData, invalidateData } = this.props;
    if (!read) {
      // mark as read api call
      authenticateEndpoint(endpoints.markMessageAsRead)({ messageId, contractId })
        .catch(() => {})
        .then(() => {
          invalidateData('messages');
          invalidateData('unreadMessageCount');
          getData('unreadMessageCount');
          getData('messages');
        });
    }
  }

  render() {
    const { type, text, title, date, read, contractLabel, contractId } = this.props;
    return (
      <NarrowColumn>
        <Paragraph size="xSmall" color="mediumGray">
          <Time timestamp={date} year month="short" day />
        </Paragraph>
        <Header right={<CloseButton color="darkPetrol" />} />
        <Heading variant="light" size="h4">
          <MessageIcon type={type} read={read} /> {title}
        </Heading>
        <Paragraph size="small" color="mediumGray" style={{ marginBottom: '0.5rem' }}>
          {contractLabel}
        </Paragraph>
        <Paragraph size="xSmall" color="mediumGray">
          <T id="policy.detail.general.policyNumber" />: {contractId}
        </Paragraph>
        <HTML component={Paragraph}>{text}</HTML>
      </NarrowColumn>
    );
  }
}

export default connect(null, actions)(ActiveMessage);
