import React from 'react';
import Heading from '../heading';
import Paragraph from '../paragraph';
import Link from '../link';
import { translate } from '../../containers/translationContext';
import style from './invalidPayment.module.scss';
import NarrowColumn from '../contentGrid/narrowColumn';
import Icon from '../image/icon';

function InvalidPayment({ t }) {
  return (
    <div className={style.container}>
      <NarrowColumn>
        <Heading variant="light" size="h6" color="white" className={style.heading}>
          <Icon type="warning" color="white" /> {t('policy.payments.invalidPayment.heading')}
        </Heading>
        <Paragraph size="xSmall" color="white">
          {t('policy.payments.invalidPayment.body')}
        </Paragraph>
        <Link href="/policy/details/payments" className={style.link}>
          {t('policy.payments.invalidPayment.link')}
          <Icon type="more" color="white" />
        </Link>
      </NarrowColumn>
    </div>
  );
}

export default translate(InvalidPayment);
