import React from 'react';
import cc from 'classcat';
import InputBox from '../../inputBox';
import InputIcon from '../../inputIcon';
import style from './simple.module.scss';

function Select({
  error,
  items,
  value,
  onChange,
  itemToString = defaultItemToString,
  itemToKey = defaultItemToKey,
  name,
  label,
  placeholder,
  noIcon,
  hasInput,
  width,
}) {
  const activeItem = items.filter(i => itemToKey(i) === value)[0] || null;
  const activeIndex = items.indexOf(activeItem);

  function handleChange(e) {
    const value = e.target.value;
    const activeItem = items.filter(i => itemToKey(i) === value)[0] || null;
    onChange(activeItem);
  }

  return (
    <div>
      <InputBox
        name={name}
        error={error}
        label={label}
        hasInput={hasInput}
        hasIcon={!noIcon}
        width={width}
      >
        <div className={style.button}>
          {!noIcon && <InputIcon type={name} hasInput={hasInput} hasError={!!error} />}
          {/* Visually hidden text for determining the width of auto-width dropdowns */}
          <div className={style.value}>{itemToString(items[activeIndex])}</div>
          <select
            value={value || 'placeholder'}
            id={name}
            name={name}
            onChange={handleChange}
            className={cc([style.select, noIcon && style.noIcon])}
          >
            {placeholder && (
              <option value="placeholder" key="placeholder" disabled>
                {placeholder}
              </option>
            )}
            {items.map(item => (
              <option value={itemToKey(item)} key={itemToKey(item)}>
                {itemToString(item)}
              </option>
            ))}
          </select>
        </div>
      </InputBox>
    </div>
  );
}

function defaultItemToString(item) {
  return item && item.label;
}

function defaultItemToKey(item) {
  return item && item.key;
}

export default Select;
