import React from 'react';

function Calendar({ ...props }) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <g>
        <path
          d="M40.5,13h-3.3V9.8c0-0.6-0.4-1-1-1s-1,0.4-1,1V13H30V9.8c0-0.6-0.4-1-1-1s-1,0.4-1,1V13h-5.2V9.8c0-0.6-0.4-1-1-1
		s-1,0.4-1,1V13h-5.2V9.8c0-0.6-0.4-1-1-1s-1,0.4-1,1V13H10c-1.7,0-3,1.3-3,3v23.2c0,1.7,1.3,3,3,3h30.5c1.7,0,3-1.3,3-2.9V16
		C43.5,14.3,42.2,13,40.5,13z M10,15h30.5c0.6,0,1,0.4,1,1v1.3H9V16C9,15.4,9.4,15,10,15z M40.5,40.2H10c-0.6,0-1-0.4-1-1V19.3h32.5
		v20C41.5,39.9,41,40.2,40.5,40.2z"
        />
        <path d="M24.1,23.4c0-0.6-0.5-1.1-1.1-1.1h-1.5c-0.6,0-1.1,0.5-1.1,1.1v1.5c0,0.6,0.5,1.1,1.1,1.1H23c0.6,0,1.2-0.5,1.1-1.1V23.4z" />
        <path
          d="M29.2,22.3h-1.5c-0.6,0-1.1,0.5-1.1,1.1v1.5c0,0.6,0.5,1.1,1.1,1.1h1.5c0.6,0,1.1-0.5,1.1-1.1v-1.5
		C30.3,22.8,29.8,22.3,29.2,22.3z"
        />
        <path
          d="M35.3,22.4h-1.5c-0.6,0-1.1,0.5-1.1,1.1V25c0,0.6,0.5,1.1,1.1,1.1h1.5c0.6,0,1.1-0.6,1.1-1.1v-1.5
		C36.4,22.9,35.9,22.4,35.3,22.4z"
        />
        <path
          d="M15.2,31.8h1.5c0.7,0,1.2-0.5,1.1-1.1v-1.5c0-0.6-0.5-1.1-1.1-1.1h-1.5c-0.6,0-1.1,0.5-1.1,1.1v1.5
		C14.1,31.3,14.6,31.8,15.2,31.8z"
        />
        <path
          d="M22.9,28.1h-1.5c-0.6,0-1.1,0.5-1.1,1.1v1.5c0,0.6,0.5,1.1,1.1,1.1h1.5c0.7,0,1.2-0.5,1.1-1.1v-1.5
		C24,28.6,23.5,28.1,22.9,28.1z"
        />
        <path
          d="M30.2,29.2c0-0.6-0.5-1.1-1.1-1.1h-1.5c-0.6,0-1.1,0.5-1.1,1.1v1.5c0,0.6,0.5,1.1,1.1,1.1h1.5c0.6,0,1.2-0.5,1.1-1.1V29.2z
		"
        />
        <path
          d="M35.3,28.1h-1.5c-0.6,0-1.1,0.5-1.1,1.1v1.5c0,0.6,0.5,1.1,1.1,1.1h1.5c0.6,0,1.1-0.5,1.1-1.1v-1.5
		C36.4,28.6,35.9,28.1,35.3,28.1z"
        />
        <path d="M17.8,35c0-0.6-0.5-1.1-1.1-1.1h-1.5c-0.6,0-1.1,0.5-1.1,1.1v1.6c0,0.6,0.5,1.1,1.1,1.1h1.5c0.6,0,1.2-0.5,1.1-1.2V35z" />
        <path
          d="M22.9,33.9h-1.5c-0.6,0-1.1,0.5-1.1,1.1v1.6c0,0.6,0.5,1.1,1.1,1.1h1.5c0.6,0,1.1-0.5,1.1-1.2V35
		C24,34.4,23.5,33.9,22.9,33.9z"
        />
        <path
          d="M29,33.9h-1.5c-0.6,0-1.1,0.5-1.1,1.1v1.6c0,0.6,0.5,1.1,1.1,1.1H29c0.6,0,1.1-0.5,1.1-1.2V35C30.1,34.4,29.6,33.9,29,33.9
		z"
        />
      </g>
    </svg>
  );
}

export default Calendar;
