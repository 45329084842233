import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import translationContext from '../../../containers/translationContext';
import PasswordStrengthMeter from '../../../components/passwordStrengthMeter';
import TextInput from '../inputs/text';
import bindInputState from '../../../utils/bindInputState';
import { entropy } from '../../../utils/passwordStrength';
import config from '../../../config';

class ChoosePassword extends Component {
  static propTypes = {
    name: string.isRequired,
    errors: object.isRequired,
    setError: func.isRequired,
    values: object.isRequired,
  };
  static contextType = translationContext;

  handleChange = () => {
    const { name, nameConfirm, setError } = this.props;
    if (this.isStrongEnough()) {
      setError(name, null);
    }
    if (this.isMatch()) {
      setError(nameConfirm, null);
    }
    if (this.meetsRequirements()) {
      setError(name, null);
    }
  };

  handleBlur = () => {
    const { values, name, nameConfirm, setError } = this.props;
    const { t } = this.context;

    if (!this.isStrongEnough()) {
      setError(name, t('form.error.passwordStrength'));
    }
    if (values[`${nameConfirm}Touched`] && !this.isMatch()) {
      setError(nameConfirm, t('form.error.passwordConfirm'));
    }
    if (!this.meetsRequirements()) {
      setError(name, t('form.error.passwordRequirements'));
    }
  };

  isStrongEnough() {
    const { values, name } = this.props;
    const value = values[name];
    return value && entropy(value) >= config.passwordMinEntropy;
  }

  isMatch() {
    const { values, name, nameConfirm } = this.props;
    return values[name] === values[nameConfirm];
  }

  isValid() {
    return this.isMatch() && this.isStrongEnough() && this.meetsRequirements();
  }

  meetsRequirements() {
    const { values, name } = this.props;

    // Check whether the value exists
    if (values.hasOwnProperty(name)) {
      // Check if the value matches a regex (1+ uppercase, 1+ lowercase, 1+ symbol & 1+ number)
      const matches = values[name].match(config.passwordRegex);

      // If we have matches, it meets the requirements
      return matches !== null ? matches.length > 0 : false;
    } else {
      return false;
    }
  }

  render() {
    const {
      errors,
      name,
      nameConfirm,
      label,
      labelConfirm,
      values,
      setValueState,
      passwordChange,
    } = this.props;

    const passwordTouched = values[`${name}Touched`];
    return (
      <>
        <TextInput
          {...bindInputState(values, setValueState, name, 'target.value', {
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            label,
            type: 'password',
            error: errors[name],
            extraChild: passwordTouched && (
              <PasswordStrengthMeter value={values[name]} passwordChange={passwordChange} />
            ),
          })}
        />
        <TextInput
          {...bindInputState(values, setValueState, nameConfirm, 'target.value', {
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            label: labelConfirm,
            type: 'password',
            error: errors[nameConfirm],
          })}
        />
      </>
    );
  }
}

export default ChoosePassword;
