import React from 'react';
import { translate } from '../containers/translationContext';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Header from '../components/header';
import Heading from '../components/heading';
import CardNav from '../components/cardNav';
import { connect } from '../containers/storeContext';
import Data from '../containers/data';
import Gtc from '../routes/policyGtc';
import Placeholder from '../components/placeholder';
import Paragraph from '../components/paragraph';
import TextLink from '../components/textLink';

function PolicyDetails({ t, activePolicy }) {
  // The flex-grow styling on the headings are fixes for IE
  return (
    <NarrowColumn>
      <Header left="menu" />
      <Heading color="white" size="h4">
        {t('policy.detail')}
      </Heading>
      <Heading color="white" variant="light" size="h6">
        {activePolicy.description}
      </Heading>
      <CardNav>
        <CardNav.Card href="/policy/details/general" arrow>
          <Heading size="h6" variant="light" color="black" ieFlexGrow>
            {t('policy.detail.general')}
          </Heading>
        </CardNav.Card>
        <CardNav.Card href="/policy/details/policyHolder" arrow>
          <Heading size="h6" variant="light" color="black" ieFlexGrow>
            {t('policy.detail.policyHolder')}
          </Heading>
        </CardNav.Card>
        <CardNav.Card href="/policy/details/insuredPerson" arrow>
          <Heading size="h6" variant="light" color="black" ieFlexGrow>
            {t('policy.detail.insuredPerson')}
          </Heading>
        </CardNav.Card>
        <CardNav.Card href="/policy/details/beneficiaries" arrow>
          <Heading size="h6" variant="light" color="black" ieFlexGrow>
            {t('policy.detail.beneficiaries')}
          </Heading>
        </CardNav.Card>
        <Data
          keys={[`gtc:${activePolicy.contractId}:${activePolicy.marketId}`]}
          fallback={<Placeholder color="white" />}
          renderErrors={e => (
            <Paragraph color="white" align="center">
              {(e => {
                // No display of error message. We just inform the user to contact support to request GTC
                return (
                  <>
                    {t('policy.noGtcPrefix')}{' '}
                    <TextLink href="/service/contact">{t('policy.noGtcLink')}</TextLink>{' '}
                    {t('policy.noGtcSuffix')}
                  </>
                );
              })(e)}
            </Paragraph>
          )}
        >
          {(data, error) => {
            let gtc = data[0].documents ? data[0].documents[0] : null;
            return <>{gtc !== null && <Gtc gtc={gtc}></Gtc>}</>;
          }}
        </Data>
      </CardNav>
    </NarrowColumn>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    activePolicy: state.activePolicy,
  };
};

export default connect(mapStateToProps)(translate(PolicyDetails));
