import React from 'react';
import { string, func, bool } from 'prop-types';
import cc from 'classcat';
import style from './style.module.scss';
import InputBox from '../../inputBox';
import InputIcon from '../../inputIcon';

CheckboxInput.propTypes = {
  name: string.isRequired,
  id: string,
  error: string,
  onChange: func,
  onBlur: func,
  value: bool,
  hasInput: bool,
  innerRef: func,
};

function CheckboxInput({
  name,
  label,
  readonly,
  error,
  id,
  onChange,
  value,
  onBlur,
  hasInput,
  innerRef,
  disabled,
  noIcon,
  icon,
  optional,
}) {
  return label ? (
    <InputBox name={name} error={error} label={label} hasIcon={!noIcon} noUnderline>
      {!noIcon && <InputIcon type={icon || name} hasInput={hasInput} hasError={!!error} />}
      <label className={style.checkBoxWrapper}>
        <input
          className={style.input}
          type="checkbox"
          id={name}
          name={name}
          onChange={onChange}
          checked={value}
          onBlur={onBlur}
          ref={innerRef}
          readOnly={readonly}
        />
        <span className={cc([style.button, value && style.on])} />
      </label>
    </InputBox>
  ) : (
    <InputBox name={name} error={error} hasIcon={!noIcon} noUnderline noMargin={true}>
      {!noIcon && <InputIcon type={icon || name} hasInput={hasInput} hasError={!!error} />}
      <input
        className={style.input}
        type="checkbox"
        id={name}
        name={name}
        onChange={onChange}
        checked={value}
        onBlur={onBlur}
        ref={innerRef}
        readOnly={readonly}
      />
      <span className={cc([style.button, value && style.on])} />
    </InputBox>
  );
}

export default CheckboxInput;
