import React from 'react';
import { T } from '../../../containers/translationContext';
import Link from '../../link';
import Heading from '../../heading';
import style from './style.module.scss';

function RegisterButton({ onClick, innerRef }) {
  return (
    <Heading
      component={Link}
      size="h6"
      variant="sans"
      color="black"
      href="/register"
      className={style.register}
    >
      <T id="register" />
    </Heading>
  );
}

export default RegisterButton;
