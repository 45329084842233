import React, { useContext } from 'react';
import Link from '../../link';
import Portal from '../../../components/portal';
import cc from 'classcat';
import style from './style.module.scss';
import transitionContext from '../../../containers/transitionContext';
import routeContext from '../../../containers/routeContext';

function SubmitButton({
  children,
  onClick,
  innerRef,
  href,
  color,
  hidden,
  inline,
  disabled,
  noMenu,
  ...rest
}) {
  const { layer } = useContext(routeContext);
  const transitionState = useContext(transitionContext);
  const className = cc([
    style.button,
    color === 'white' && style.white,
    hidden && style.hidden,
    style[`layer${layer}`],
    style[transitionState],
    noMenu && style.noMenu,
  ]);

  const button = href ? (
    <Link
      href={href}
      onClick={onClick}
      disabled={disabled}
      className={className}
      ref={innerRef}
      {...rest}
    >
      {children}
    </Link>
  ) : (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={className}
      ref={innerRef}
      {...rest}
    >
      {children}
    </button>
  );

  return (
    <div>
      {/* Hidden button inside the form so submit on pressing enter still works */}
      <button
        type="submit"
        onClick={onClick}
        disabled={disabled}
        role="none"
        className="visiblyHidden"
        tabIndex={-1}
      />
      <Portal>{button}</Portal>
    </div>
  );
}

export default SubmitButton;
