import React from 'react';
import { translate } from '../../containers/translationContext';
import Heading from '../heading';
import Paragraph from '../paragraph';
import style from './style.module.scss';
import NarrowColumn from '../contentGrid/narrowColumn';

function WhatDoISee({ t, body }) {
  return (
    <div className={style.box}>
      <NarrowColumn>
        <Heading className={style.heading} variant="sans" color="white" size="h6">
          {t('projectedValues.descriptionHeading')}
        </Heading>
        <Paragraph color="white" size="small">
          {body}
        </Paragraph>
      </NarrowColumn>
    </div>
  );
}

export default translate(WhatDoISee);
