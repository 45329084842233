import React, { useEffect } from 'react';
import style from './success.module.scss';
import Heading from '../../../heading';
import { translate } from '../../../../containers/translationContext';
import Thumb from '../../../image/thumb';
import { navigate } from '@reach/router';

function Success({ t, email }) {
  // CDM
  useEffect(() => {
    // redirect to login page after 2 second
    setTimeout(() => {
      navigate('/login');
    }, 2000);
  }, []);

  return (
    <div className={style.container}>
      <Heading size="h1">{t('register.successHeading')}</Heading>
      <Thumb />
      {/* <Paragraph>{t('register.successBody')}</Paragraph> */}
      {/* <Paragraph size="small">
        {t('register.successResend')} <ResendEmail email={email} />
      </Paragraph> */}
    </div>
  );
}

export default translate(Success);
