import React from 'react';
import style from './style.module.scss';
import cc from 'classcat';

function Warning({ className, ...props }) {
  return (
    <svg viewBox="0 0 55 55" className={cc([className, style.stroke, style.small])} {...props}>
      <path d="M1.4,50.7L25,3.5c0.2-0.4,0.7-0.4,0.9,0l23.6,47.2c0.2,0.3-0.1,0.7-0.5,0.7H1.9C1.5,51.4,1.3,51,1.4,50.7z" />
      <line x1="25.5" y1="18.5" x2="25.5" y2="38.2" />
      <circle cx="25.5" cy="44.6" r="1" />
    </svg>
  );
}

export default Warning;
