import React, { Component } from 'react';
import { oneOf } from 'prop-types';
import cc from 'classcat';
import style from './style.module.scss';
import Transition from '../';
import transitionContext, { TransitionProvider } from '../../../containers/transitionContext';

class InPageTransition extends Component {
  static propTypes = {
    enter: oneOf(['left', 'right']),
    leave: oneOf(['left', 'right']),
    // Don't add an onEnter callback. TransitionGroup adds it to communicate with the Transition
  };
  static contextType = transitionContext;

  render() {
    const { enter, leave, children, ...rest } = this.props;
    const pageState = this.context;

    return (
      <Transition {...rest} timeout={500}>
        {state => (
          <div
            className={cc([
              style.transition,
              style[`enter${enter}`],
              style[`leave${leave}`],
              style[state],
            ])}
          >
            <div className={style.content} ref={c => (this.node = c)}>
              <TransitionProvider value={pageState === 'entered' ? state : pageState}>
                {children}
              </TransitionProvider>
            </div>
          </div>
        )}
      </Transition>
    );
  }
}

export default InPageTransition;
