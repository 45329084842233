import React from 'react';

function Close({ ...props }) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <path
        d="M26.5,24.3l13.6-13.1c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L24.9,23.2L11,9.8c-0.4-0.4-1-0.4-1.4,0
	c-0.4,0.4-0.4,1,0,1.4l13.6,13.1L9.6,37.2c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3l13.9-13.1l13.9,13.1
	c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L26.5,24.3z"
      />
    </svg>
  );
}

export default Close;
