import React, { Component } from 'react';
import Heading from '../heading';
import Paragraph from '../paragraph';
import HTML from '../html';
import Link from '../link';
import Chart from '../lineChart';
import style from './style.module.scss';
import Icon from '../image/icon';
import NarrowColumn from '../contentGrid/narrowColumn';

/**
 * Intended to be used by both policy explanation as explanations about the dynamic option, etc.
 */
class Explanation extends Component {
  renderSection = (section, index, policy, yearData) => {
    let component = null;

    switch (section.type) {
      // Paragraph
      case 'paragraph':
        component = (
          <NarrowColumn className={style.narrowColumn} key={index.toString()}>
            <HTML component={Paragraph}>{section.value}</HTML>
          </NarrowColumn>
        );
        break;

      // Link
      case 'link':
        component = (
          <NarrowColumn className={style.narrowColumn} key={index.toString()}>
            <Paragraph>
              <Link href={section.href} className={style.link}>
                {section.value}
                <Icon type="more" color="darkPetrol" className={style.moreIcon} />
              </Link>
            </Paragraph>
          </NarrowColumn>
        );
        break;

      case 'graph':
        component = (
          <Chart policy={policy} key={index.toString()} yearData={yearData} section={section} />
        );
        break;

      // Default
      default:
        component = (
          <NarrowColumn className={style.narrowColumn} key={index.toString()}>
            <span>{section.value}</span>
          </NarrowColumn>
        );
        break;
    }

    return component;
  };

  render() {
    const { title, sections, policy, yearData } = this.props;

    return (
      <>
        <NarrowColumn>
          <Heading variant="light" size="h6" color="petrol">
            {title}
          </Heading>
        </NarrowColumn>
        {sections.map((section, index) => this.renderSection(section, index, policy, yearData))}
      </>
    );
  }
}

export default Explanation;
