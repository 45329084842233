import React, { Component } from 'react';
import ActiveMessage from '../components/message/activeMessage';
import Data from '../containers/data';
import Placeholder from '../components/placeholder';
import routeContext from '../containers/routeContext';
import Paragraph from '../components/paragraph';
import { T } from '../containers/translationContext';

class Messages extends Component {
  static contextType = routeContext;

  render() {
    const { id } = this.context;

    return (
      <Data keys={['messages']} fallback={<Placeholder color="white" />}>
        {([messages]) => {
          const activeMessage = messages.filter(message => message.messageId === id)[0];
          return activeMessage ? (
            <ActiveMessage {...activeMessage} />
          ) : (
            <Paragraph>
              <T id="message.notFound" />
            </Paragraph>
          );
        }}
      </Data>
    );
  }
}

export default Messages;
