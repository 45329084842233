import React from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import UpdateProfileForm from '../components/form/forms/updateProfile';
import CloseButton from '../components/button/close';
import Heading from '../components/heading';
import Data from '../containers/data';
import Header from '../components/header';
import { translate } from '../containers/translationContext';

function Update({ t }) {
  return (
    <NarrowColumn>
      <Header right={<CloseButton href="/account" color="darkPetrol" />} />
      <Heading align="center" variant="light" size="h4">
        {t('settings.profile')}
      </Heading>
      <Data keys={['user']}>
        {([user]) => {
          return (
            <>
              <Heading size="h2" align="center">
                {user.firstName} {user.lastName}
              </Heading>
              <UpdateProfileForm user={user} />
            </>
          );
        }}
      </Data>
    </NarrowColumn>
  );
}

export default translate(Update);
