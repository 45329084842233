import React from 'react';
import { translate } from '../containers/translationContext';
import Data from '../containers/data';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Header from '../components/header';
import Heading from '../components/heading';
import CloseButton from '../components/button/close';
import { connect } from '../containers/storeContext';
import Logo from '../components/image/logo';
import Broker from '../components/address/Broker';
import FWU from '../components/address/Fwu';

function AccountContact({ t, activePolicy, marketId }) {
  return (
    <NarrowColumn>
      <Header right={<CloseButton color="darkPetrol" />} />
      <Heading style={{ marginBottom: '31px' }}>{t('contact.header')}</Heading>
      <Logo style={{ width: '40%', display: 'block', margin: 'auto', paddingBottom: '10px' }} />
      {activePolicy ? (
        <Data keys={[`policy:${activePolicy.contractId}`]}>
          {([policy]) => <Broker broker={policy.brokers[0]} />}
        </Data>
      ) : (
        <FWU marketId={marketId} />
      )}
    </NarrowColumn>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    activePolicy: state.activePolicy,
    marketId: state.data.user ? state.data.user.value.marketId : '',
  };
};

export default connect(mapStateToProps)(translate(AccountContact));
