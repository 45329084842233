import dlv from 'dlv';
import DASHBOARD_PAGES from '../config/dashboardPages';

function transformPaymentData(data) {
  const newData = {
    labels: data.labels,
    totalAmount: dlv(data, 'totalAmount', '0,00'),
    yearWiseTotalAmount: data.yearWiseTotalAmount,
    currency: null,
    paymentError: false,
    yearly: {},
  };

  /**
   * data.yearWiseTotalAmount
   * {
   *   2006: "400",
   * }
   * data.totalAmount
   */

  Object.entries(data.payments).forEach(([year, paymentsData]) => {
    let currency = null;
    let lastPaymentDate = 0;

    paymentsData.forEach(el => {
      currency = el.currency;
      lastPaymentDate = el.paymentDate;
      if (el.paid !== 'paid') newData.paymentError = true;
    });

    // Set yearly amount
    newData.yearly[year] = {
      year,
      lastPaymentDate,
      totalAmount: dlv(data, `yearWiseTotalAmount.${year}`, '0,00'),
      currency,
      monthly: paymentsData,
    };

    newData.currency = currency;
  });

  return newData;
}

function transformPaymentPlanData(data) {
  return data.map(paymentPlan => {
    return {
      currency: paymentPlan.currency,
      frequency: paymentPlan.frequency,
      fromDate: paymentPlan.fromDate,
      toDate: paymentPlan.toDate,
      nextDueDate: paymentPlan.nextDueDate,
      premium: paymentPlan.premium,
      sumOfPremium: paymentPlan.sumOfPremium,
    };
  });
}

function transformMessagesData(data) {
  return data.map(message => {
    const date = new Date(0);
    date.setUTCSeconds(message.date);
    return {
      ...message,
      year: date.getFullYear(),
    };
  });
}

function transformLanguagesData(data) {
  return data.map(language => {
    return {
      key: language.languageId.toLowerCase(),
      label: language.languageDesc,
    };
  });
}

function transformPhoneCountryCodesData(data) {
  return data.map(phoneCountryCode => {
    return {
      code: phoneCountryCode.code === 'gb' ? 'en' : phoneCountryCode.code,
      key: phoneCountryCode.callingCode,
      label: `${phoneCountryCode.callingCode} (${phoneCountryCode.name})`,
    };
  });
}

/**
 * Transform the projected values data
 * So we know what pages to render in the dashboard
 * @param data
 * @returns {{years: *, pages: {}, indexes: Array}}
 */
function transformProjectedValuesData(data) {
  if (typeof data === 'undefined')
    return {
      years: null,
      pages: null,
      indexes: null,
    };

  const currentYear = new Date().getFullYear();
  const yearData = data.hasOwnProperty(currentYear) ? data[currentYear] : null;

  const pages = {};
  const pageOrders = [];

  // Loop through the projected values of the current year
  if (yearData !== null) {
    Object.keys(yearData).forEach(key => {
      // If the value is supposed to visible, it needs to have a page
      if (DASHBOARD_PAGES.hasOwnProperty(key) && yearData[key].visibility) {
        pages[key] = {
          endpoint: DASHBOARD_PAGES[key].endpoint,
          color: DASHBOARD_PAGES[key].color,
        };
        // And store this in the pageOrders array
        pageOrders.push({
          weight: DASHBOARD_PAGES[key].weight,
          key,
        });
      }
    });
  }

  // Loop through all the possible pages to determine the indexes for the dashboard
  let index = 0;
  const indexes = [];
  for (let i = 0; i < Object.keys(yearData).length; i++) {
    // If the weight of the page is the current i
    const page = pageOrders.filter(page => {
      return page.weight === i;
    });
    if (page.length) {
      indexes[index] = page[0].key;
      index++;
    }
  }

  return {
    years: data,
    pages,
    indexes,
  };
}

export {
  transformPaymentData,
  transformMessagesData,
  transformLanguagesData,
  transformPhoneCountryCodesData,
  transformProjectedValuesData,
  transformPaymentPlanData,
};
