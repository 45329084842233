import React from 'react';
import style from './style.module.scss';
import cc from 'classcat';

function Market({ className, ...props }) {
  return (
    <svg viewBox="0 0 55 55" {...props} className={cc([className, style.stroke])}>
      <path d="M27.9,4.1c-11.4-7.9-20.6,0-20.6,0c-11,9.6-5.2,20.3-5.2,20.3l14.6,29.3c0.4,0.8,1.6,0.8,2,0l14.2-29  C38.7,11.3,27.9,4.1,27.9,4.1z M17.6,27.7c-5.4,0-9.8-4.4-9.8-9.8s4.4-9.8,9.8-9.8s9.8,4.4,9.8,9.8S23,27.7,17.6,27.7z" />
    </svg>
  );
}

export default Market;
