import React from 'react';
import { oneOf } from 'prop-types';
import cc from 'classcat';
import styles from './style.module.scss';

Placeholder.propTypes = {
  color: oneOf(['white', 'petrol']),
};

function Placeholder({ color = 'petrol', height = 30, className, style }) {
  return (
    <svg
      className={cc([styles.spinner, styles[color], className])}
      viewBox="0 0 66 66"
      preserveAspectRatio="xMidYMid meet"
      style={{ height: `${height}px`, ...style }}
    >
      <circle
        className={styles.path}
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  );
}

export default Placeholder;
