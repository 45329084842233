import React, { Component } from 'react';
import Form from '../index';
import ChoosePassword from '../inputUnits/choosePassword';
import { endpoints } from '../../../config/data';
import Password from '../inputUnits/password';
import Button from '../../button/submit';
import InputGroup from '../inputGroup';
import { translate } from '../../../containers/translationContext';
import SendError from '../sendError';
import styles from './style.module.scss';

class PasswordChangeForm extends Component {
  state = {
    isValid: false,
    touched: true,
  };

  handleChange = () => {
    this.setState({
      isValid: this.password.isValid() && this.choose.isValid(),
      touched: true,
    });
  };

  render() {
    const { t, user } = this.props;

    return (
      <Form
        api={endpoints.passwordChange}
        onSuccess={this.props.onSuccess}
        onValueChange={this.handleChange}
      >
        {({ submit, isSending, sendError, ...inputProps }) => (
          <>
            <InputGroup heading="">
              <Password
                name="oldPassword"
                label={t('form.label.passwordOld')}
                ref={c => (this.password = c)}
                {...inputProps}
              />

              <ChoosePassword
                label={t('form.label.passwordNew')}
                labelConfirm={t('form.label.passwordConfirm')}
                name="newPassword"
                nameConfirm="newPasswordConfirm"
                ref={c => (this.choose = c)}
                passwordChange="true"
                {...inputProps}
              />
            </InputGroup>

            {user.isAdmin ? (
              <div className={styles.adminMessage}>
                Admin is logged in - You cannot save changes!
              </div>
            ) : (
              <>
                <SendError message={sendError} />
                <Button
                  onClick={submit}
                  disabled={isSending || !this.state.isValid}
                  hidden={!this.state.touched}
                >
                  {t('form.button.save')}
                </Button>
              </>
            )}
          </>
        )}
      </Form>
    );
  }
}

export default translate(PasswordChangeForm);
