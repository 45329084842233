import React from 'react';
import { connect } from '../containers/storeContext';
import { translate } from '../containers/translationContext';
import Heading from '../components/heading';
import CardNav from '../components/cardNav';

function Gtc({ t, activePolicy, authToken, gtc }) {
  return (
    <>
      <CardNav.Card
        href={`/gtc?contractId=${gtc.contractId}&userId=${activePolicy.userId}&marketId=${activePolicy.marketId}&documentId=${gtc.documentId}&jwt=${authToken}`}
        icon="download"
        external
      >
        <Heading size="h6" variant="light" color="black" ieFlexGrow>
          {t('policy.gtc')}
        </Heading>
      </CardNav.Card>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    activePolicy: state.activePolicy,
    authToken: state.authToken,
  };
};

export default connect(mapStateToProps)(translate(Gtc));
