import React, { useEffect } from 'react';
import emitter from '../../utils/emitter';
import cc from 'classcat';
import style from './style.module.scss';

function UseLines({ light, flipped, id, paused }) {
  useEffect(() => {
    emitter.emit('pauseLines', paused);
  }, [paused]);
  return (
    <svg
      viewBox={flipped ? '0 0 100 200' : '0 100 100 300'}
      className={cc([style.svg, light && style.light, flipped && style.flipped])}
      preserveAspectRatio={flipped ? 'xMidYMax meet' : 'xMidYMin meet'}
      shapeRendering="optimizeSpeed"
    >
      <use xlinkHref={`#${id}`} />
    </svg>
  );
}

export default UseLines;
