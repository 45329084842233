import React from 'react';
import { translate } from '../containers/translationContext';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import PolicyDetailsBeneficiariesForm from '../components/form/forms/policyDetailsBeneficiaries';

function PolicyDetailsBeneficiaries({ t }) {
  return (
    <NarrowColumn>
      <PolicyDetailsBeneficiariesForm />
    </NarrowColumn>
  );
}

export default translate(PolicyDetailsBeneficiaries);
