import React from 'react';
import { func, oneOfType, string } from 'prop-types';
import cc from 'classcat';
import style from './style.module.scss';

HTML.propTypes = {
  children: string.isRequired,
  component: oneOfType([string, func]),
  className: string,
};

function HTML({ children, className, component: Comp = 'span' }) {
  return (
    <Comp dangerouslySetInnerHTML={{ __html: children }} className={cc([style.html, className])} />
  );
}

export default HTML;
