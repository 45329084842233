import React from 'react';
import Header from '../components/header';
import Data from '../containers/data';
import Placeholder from '../components/placeholder';
import { translate } from '../containers/translationContext';
import { connect } from '../containers/storeContext';
import Form from '../components/form';
import Heading from '../components/heading';
import InputGroup from '../components/form/inputGroup';
import TextUnit from '../components/form/inputUnits/text';
import BackButton from '../components/button/back';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import formatMoney from '../utils/formatMoney';

function DetailPaymentPlan({ t, formatTime, activePolicy, language }) {
  return (
    <NarrowColumn>
      <Header left={<BackButton color="darkPetrol" />} />
      <Data keys={[`paymentPlan:${activePolicy.contractId}`]} fallback={<Placeholder />}>
        {([paymentPlan]) => {
          const firstPaymentPlan = paymentPlan.length > 0 ? paymentPlan[0] : null;
          const currency = firstPaymentPlan.currency.value;
          const premiumData = formatMoney(firstPaymentPlan.premium.value, currency);
          const sumOfPremiumData = formatMoney(firstPaymentPlan.sumOfPremium.value, currency);
          const initialValues = {
            fromDate: formatTime(firstPaymentPlan.fromDate.value, {
              year: true,
              month: true,
              day: true,
            }),
            toDate: formatTime(firstPaymentPlan.toDate.value, {
              year: true,
              month: true,
              day: true,
            }),
            nextDueDate: formatTime(firstPaymentPlan.nextDueDate.value, {
              year: true,
              month: true,
              day: true,
            }),
            frequency: firstPaymentPlan.frequency.value,
            premium: `${premiumData.v} ${premiumData.symbol}`,
            sumOfPremium: `${sumOfPremiumData.v} ${sumOfPremiumData.symbol}`,
          };

          return (
            <Form initialValues={initialValues}>
              {({ submit, isSending, sendError, ...inputProps }) => (
                <>
                  <Heading size="h3">{t('policy.payments.plan')}</Heading>
                  <InputGroup>
                    <TextUnit
                      name="fromDate"
                      label={t('policy.detail.payments.plan.dateFrom')}
                      {...inputProps}
                      noIcon
                      readonly
                    />
                    <TextUnit
                      name="toDate"
                      label={t('policy.detail.payments.plan.dateTo')}
                      {...inputProps}
                      noIcon
                      readonly
                    />
                    <TextUnit
                      name="nextDueDate"
                      label={t('policy.detail.payments.plan.nextDueDate')}
                      {...inputProps}
                      noIcon
                      readonly
                    />
                    <TextUnit
                      name="frequency"
                      label={t('policy.detail.payments.plan.frequency')}
                      {...inputProps}
                      noIcon
                      readonly
                    />
                    <TextUnit
                      name="premium"
                      label={t('policy.detail.payments.plan.premium')}
                      {...inputProps}
                      noIcon
                      readonly
                    />
                    <TextUnit
                      name="sumOfPremium"
                      label={t('policy.detail.payments.plan.sumOfPremium')}
                      {...inputProps}
                      noIcon
                      readonly
                    />
                  </InputGroup>
                </>
              )}
            </Form>
          );
        }}
      </Data>
    </NarrowColumn>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    activePolicy: state.activePolicy,
    language: state.language,
  };
};

export default connect(mapStateToProps)(translate(DetailPaymentPlan));
