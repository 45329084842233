import React, { createContext } from 'react';
import formatTime from '../utils/formatTime';
import { t } from '../utils/language';
import { connect } from './storeContext';
import { translations } from '../store';

const translationContext = createContext({});
const { Provider, Consumer } = translationContext;

function TranslationProvider({ language, children }) {
  const transls = translations[language];
  return (
    <Provider
      value={{
        language,
        t: t.bind(null, transls),
        formatTime: formatTime.bind(null, language),
      }}
    >
      {children}
    </Provider>
  );
}

const ConnectedTranslationProvider = connect('language')(TranslationProvider);

function T({ id, values = [] }) {
  return <Consumer>{({ t }) => <span>{t(id, ...values)}</span>}</Consumer>;
}

function withT(Wrapped) {
  function C(props) {
    C.displayName = `withTranslation(${Wrapped.displayName || Wrapped.name || 'C'})`;
    return (
      <Consumer>
        {({ t, formatTime }) => <Wrapped {...props} t={t} formatTime={formatTime} />}
      </Consumer>
    );
  }

  return C;
}

export default translationContext;
export { T, withT as translate, ConnectedTranslationProvider as TranslationProvider };
