import React, { Component } from 'react';
import { translate } from '../../containers/translationContext';
import routeContext from '../../containers/routeContext';
import { connect } from '../../containers/storeContext';
import Heading from '../heading';
import Money from '../money';
import Time from '../time';
import style from './paymentDetailOverview.module.scss';
import { T } from '../../containers/translationContext';
import NarrowColumn from '../contentGrid/narrowColumn';
import ContentGrid from '../contentGrid';
import WhatDoISee from '../whatDoISee';
import formatMoney from '../../utils/formatMoney';
import Select from '../../components/form/inputUnits/select';

class PaymentsDetailOverview extends Component {
  static contextType = routeContext;
  PAYMENT_PERIOD_ALL = 'all';

  renderMethod = this.renderDetails;
  state = {
    possiblePaymentPeriods: [],
    selectedPaymentPeriod: null,
  };

  componentDidMount() {
    const yearData = this.props.payments;
    const { t } = this.props;

    if (!yearData) {
      this.renderMethod = () => <div>Data not available</div>;
      return;
    }

    const { monthly } = yearData;
    if (!monthly) {
      this.renderMethod = () => <div>Yearly Payment</div>;
      return;
    }

    const possibleSelectOptions = [
      this.PAYMENT_PERIOD_ALL,
      ...Array.from(new Set(yearData.monthly.map(item => item.type))),
    ].map(item => ({ key: item, label: t(`paymentPeriod.${item.toLowerCase()}`) }));

    this.setState({
      possiblePaymentPeriods: possibleSelectOptions,
      selectedPaymentPeriod: possibleSelectOptions[0].key,
    });
  }

  renderDetails() {
    const selectedPaymentPeriod = this.state.selectedPaymentPeriod || this.PAYMENT_PERIOD_ALL;
    const yearData = this.props.payments;
    const { monthly } = yearData;
    const { t } = this.props;

    const selectVisible =
      this.state.possiblePaymentPeriods && this.state.possiblePaymentPeriods.length > 2;

    const totalContributions = monthly.reduce((total, currentValue) => {
      return selectedPaymentPeriod === this.PAYMENT_PERIOD_ALL ||
        currentValue.type === selectedPaymentPeriod
        ? total + currentValue.amountActual
        : total;
    }, 0);

    const summaryLabel =
      selectedPaymentPeriod === this.PAYMENT_PERIOD_ALL
        ? t('policy.payments.totalPayment')
        : t(`paymentPeriod.${selectedPaymentPeriod.toLowerCase()}`);

    return (
      <>
        <NarrowColumn>
          <Money value={totalContributions} currency={yearData.currency}>
            {({ symbol }) => (
              <Heading
                variant="sans"
                weight="bold"
                size="h1"
                color="petrol"
                className={style.heading}
              >
                {formatMoney(totalContributions, yearData.currency).v} {symbol}
              </Heading>
            )}
          </Money>
          <Heading variant="light" className={style.totalPayment} size="h6" color="petrol">
            {summaryLabel} {yearData.year}
          </Heading>
        </NarrowColumn>
        <ContentGrid>
          {({ getFullContentProps }) => (
            <div {...getFullContentProps()}>
              <WhatDoISee body={<T id="policy.payment.duePaymentsOfYear" />} />
            </div>
          )}
        </ContentGrid>

        <NarrowColumn style={{ marginTop: selectVisible ? '0px' : '40px' }}>
          {(() => {
            if (selectVisible) {
              return (
                <>
                  <Select
                    name="duration"
                    items={this.state.possiblePaymentPeriods}
                    hasInput={true}
                    width="100%"
                    value={this.state.selectedPaymentPeriod}
                    openTowards="top"
                    errors={{}}
                    values={{}}
                    setError={() => {}}
                    setValueState={newValue => {
                      this.setState({ selectedPaymentPeriod: newValue.duration });
                    }}
                  />
                  <br />
                </>
              );
            }
            return null;
          })()}

          {(() => {
            const filteredPayments =
              this.state.selectedPaymentPeriod === this.PAYMENT_PERIOD_ALL
                ? monthly
                : monthly.filter(item => item.type === this.state.selectedPaymentPeriod);

            if (filteredPayments.length === 0) {
              return null;
            }
            return filteredPayments.map((item, i) => (
              <div key={i} className={style.monthContainer}>
                <div className={style.time}>
                  <Heading variant="light" size="h6" color="petrol">
                    <Time timestamp={item.paymentDate} year />
                  </Heading>
                  <Heading variant="sans" weight="bold" size="h6">
                    <Time timestamp={item.paymentDate} month="short" day />
                  </Heading>
                </div>
                <Money value={item.amount} currency={item.currency}>
                  {({ symbol }) => (
                    <Heading size="h4" variant="sans" weight="bold" className={style.money}>
                      {formatMoney(item.amount, item.currency).v} {symbol}
                    </Heading>
                  )}
                </Money>
              </div>
            ));
          })()}
        </NarrowColumn>
      </>
    );
  }

  render() {
    return this.renderMethod();
  }
}

export default connect('activePolicy')(translate(PaymentsDetailOverview));
