import React, { Component } from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Header from '../components/header';
import BackButton from '../components/button/back';
import routeContext from '../containers/routeContext';
import Data from '../containers/data';
import Explanation from '../components/explanation';
import { ThemeProvider } from '../containers/themeContext';
import Placeholder from '../components/placeholder';
import { connect } from '../containers/storeContext';
import DASHBOARD_PAGES from '../config/dashboardPages';

class PolicyExplanation extends Component {
  static contextType = routeContext;

  render() {
    const { type } = this.context;
    const { activePolicy } = this.props;
    const { contractId } = activePolicy;

    if (!DASHBOARD_PAGES.hasOwnProperty(type)) return <span>Explanation not found</span>;

    const { endpoint, color } = DASHBOARD_PAGES[type];

    return (
      <ThemeProvider value={{ color }}>
        <NarrowColumn>
          <Header left={<BackButton color="darkPetrol" />} />
        </NarrowColumn>
        {endpoint && (
          <Data
            keys={[`policy:${contractId}`, `projectedValues:${contractId}`]}
            fallback={<Placeholder />}
          >
            {([policy, projectedValues]) => (
              <Data keys={[`explanation:${endpoint}:${contractId}`]} fallback={<Placeholder />}>
                {([explanation]) => {
                  if (!explanation || !explanation.title || !explanation.sections)
                    return <span>Explanation not found</span>;

                  const currentYear = new Date().getFullYear();
                  const yearData = projectedValues.years.hasOwnProperty(currentYear)
                    ? projectedValues.years[currentYear]
                    : null;

                  return (
                    <Explanation
                      title={explanation.title}
                      sections={explanation.sections}
                      policy={policy}
                      yearData={yearData}
                    />
                  );
                }}
              </Data>
            )}
          </Data>
        )}
      </ThemeProvider>
    );
  }
}

export default connect('activePolicy')(PolicyExplanation);
