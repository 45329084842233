import React, { Component } from 'react';
import Header from '../components/header';
import CloseButton from '../components/button/close';
import PaymentsDetailOverview from '../components/payments/paymentDetailOverview';
import { connect } from '../containers/storeContext';
import Data from '../containers/data';
import Placeholder from '../components/placeholder';
import routeContext from '../containers/routeContext';

class PaymentsDetail extends Component {
  static contextType = routeContext;

  render() {
    return (
      <>
        <Header right={<CloseButton href="/payments" color="darkPetrol" />} />
        <Data keys={[`payments:${this.props.activePolicy.contractId}`]} fallback={<Placeholder />}>
          {([payments]) => {
            return <PaymentsDetailOverview payments={payments.yearly[this.context.year]} />;
          }}
        </Data>
      </>
    );
  }
}

export default connect('activePolicy')(PaymentsDetail);
