import React from 'react';
import cc from 'classcat';
import style from './style.module.scss';

function Document({ className, ...props }) {
  return (
    <svg viewBox="0 0 55 55" {...props} className={cc([className, style.stroke, style.darkPetrol])}>
      <line x1="27.4" y1="3" x2="27.4" y2="36.3" />
      <line x1="38.2" y1="24.9" x2="27.3" y2="38.5" />
      <line x1="27.3" y1="38.5" x2="16.4" y2="24.9" />
      <polyline points="8.3,39.4 8.3,52.7 46.9,52.7 46.9,39.4 " />
    </svg>
  );
}

export default Document;
