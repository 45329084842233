import React from 'react';

function Arrow({ ...props }) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <path
        d="M46.7,25.7c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2s0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1
	    c0,0,0-0.1-0.1-0.1l-5.1-4.1c-0.5-0.4-1.2-0.3-1.7,0.2c-0.4,0.5-0.3,1.2,0.2,1.7l2.3,1.9H3.3c-0.6,0-1.2,0.5-1.2,1.2
	    c0,0.6,0.5,1.2,1.2,1.2h39.1l-2.6,2.1c-0.5,0.4-0.6,1.2-0.2,1.7c0.2,0.3,0.6,0.4,0.9,0.4c0.3,0,0.5-0.1,0.7-0.3l5.1-4.1l0.1-0.1
	    c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2C46.7,25.9,46.7,25.8,46.7,25.7z"
      />
    </svg>
  );
}

export default Arrow;
