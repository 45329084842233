import React, { Component } from 'react';
import InputGroup from '../../inputGroup';
import ChoosePassword from '../../inputUnits/choosePassword';
import translationContext from '../../../../containers/translationContext';
import TextUnit from '../../inputUnits/text';
import { endpoints } from '../../../../config/data';
import style from './step3.module.scss';
import Placeholder from '../../../placeholder';
import TextLink from '../../../../components/textLink';
import Paragraph from '../../../../components/paragraph';
import Request from '../../../../containers/request';

class Step3 extends Component {
  static contextType = translationContext;

  componentDidMount() {
    const { email, ...inputProps } = this.props;
    inputProps.setValueState({ email: email });
  }

  isValid() {
    return this.choose.isValid() && this.otp.isValid() && this.activationKey.isValid();
  }

  render() {
    const { onInputsChange, mobileNo, errorMessage, ...inputProps } = this.props;
    const { t } = this.context;

    return (
      <>
        <InputGroup heading={t('register.emailHeading')} marginTop={40}>
          <TextUnit name="email" label={t('form.label.email')} {...inputProps} readonly />
          <ChoosePassword
            name="password"
            nameConfirm="passwordConfirm"
            label={t('form.label.password')}
            labelConfirm={t('form.label.passwordConfirm')}
            ref={c => (this.choose = c)}
            {...inputProps}
          />

          <TextUnit
            name="activationKey"
            icon="password"
            label={t('policy.connect.activationKey')}
            tooltip={
              inputProps.values.language === 'en'
                ? inputProps.values.marketId === 'FLL-ES'
                  ? t('policy.connect.activationKey_toolTip_en_es')
                  : t('policy.connect.activationKey_toolTip_en_it')
                : t('policy.connect.activationKey_toolTip')
            }
            ref={c => (this.activationKey = c)}
            {...inputProps}
          />

          <span className={style.error}>{errorMessage}</span>

          <Request
            api={endpoints.generateActivationKey}
            method="post"
            params={{
              languageId: inputProps.values.language,
              marketId: inputProps.values.marketId,
              taxId: inputProps.values.taxId,
              policyId: inputProps.values.policyId,
            }}
            lazy
          >
            {({ fetch, fetching, error, response }) => {
              if (fetching) {
                return <Placeholder />;
              }

              if (response && response.reasonCode !== '65') {
                return <span>{response.message}</span>;
              }

              return (
                <>
                  <Paragraph>
                    <span>{t('register.noActivationLink')}</span>{' '}
                    <TextLink onClick={fetch}>
                      {t('register.button.resendActivationCode.first')}
                    </TextLink>{' '}
                    {t('register.button.resendActivationCode.second')}
                    <br />
                    {response && response.message && <span>{response.message}</span>}
                  </Paragraph>

                  {['localhost', 'test-cp.forwardyou.com', 'feature-cp.forwardyou.com'].includes(
                    window.location.hostname
                  ) &&
                  response &&
                  response.data &&
                  response.data.authenticationKey ? (
                    <Paragraph>
                      <span style={{ color: '#00879b' }}>
                        <span>
                          Debugging on FWU Test Systems. Activation Key:{' '}
                          <b>{response.data.authenticationKey}</b>
                        </span>
                        <br />
                        <span style={{ fontSize: '0.75em' }}>(localhost, test-cp, feature-cp)</span>
                      </span>
                    </Paragraph>
                  ) : (
                    ''
                  )}
                </>
              );
            }}
          </Request>
        </InputGroup>

        <InputGroup marginTop={40}>
          <p className={style.smsSentToNotification}>
            {t('register.sms.verificationCodeWasSentTo')} {mobileNo}
          </p>
          <TextUnit
            name="smsToken"
            icon="password"
            label={t('register.VerificationCode')}
            tooltip={
              inputProps.values.language === 'en'
                ? inputProps.values.marketId === 'FLL-ES'
                  ? t('register.VerificationCode_toolTip_en_es')
                  : t('register.VerificationCode_toolTip_en_it')
                : t('register.VerificationCode_toolTip')
            }
            ref={c => (this.otp = c)}
            {...inputProps}
          />
        </InputGroup>
      </>
    );
  }
}

export default Step3;
