import React from 'react';
import cc from 'classcat';
import style from './style.module.scss';

ContentGrid.defaultProps = {
  children: () => {},
};

function ContentGrid({ children, className, fullHeight }) {
  return (
    <div className={cc([style.grid, className, fullHeight && style.fullHeight])}>
      {children({
        getWideContentProps: getWideContentProps,
        getNarrowContentProps: getNarrowContentProps,
        getFullContentProps: getFullContentProps,
      })}
    </div>
  );
}

function getWideContentProps({ className, ...rest } = {}) {
  return {
    className: cc([style.contentWide, className]),
    ...rest,
  };
}

function getNarrowContentProps({ className, ...rest } = {}) {
  return {
    className: cc([style.contentNarrow, className]),
    ...rest,
  };
}

function getFullContentProps({ className, ...rest } = {}) {
  return {
    className: cc([style.contentFull, className]),
    ...rest,
  };
}

export default ContentGrid;
