import React from 'react';
import style from './style.module.scss';
import cc from 'classcat';

function DotsMenu({ className, ...props }) {
  return (
    <svg
      {...props}
      className={cc([className, style.stroke])}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="24"
      fill="currentColor"
      class="bi bi-three-dots-vertical"
      viewBox="0 0 16 16"
      id="IconChangeColor"
    >
      {' '}
      <path
        d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
        id="mainIconPathAttribute"
      ></path>{' '}
    </svg>
  );
}

export default DotsMenu;
