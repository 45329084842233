import React from 'react';
import { translate } from '../../../containers/translationContext';
import Form from '../index';
import TextUnit from '../inputUnits/text';
import Data from '../../../containers/data';
import { connect } from '../../../containers/storeContext';
import Placeholder from '../../placeholder';
import Heading from '../../heading';
import Header from '../../header';
import CloseButton from '../../button/close';
import InputGroup from '../inputGroup';

function PolicyDetailsGeneralForm({ t, formatTime, activePolicy }) {
  return (
    <Data keys={[`policy:${activePolicy.contractId}`]} fallback={<Placeholder />}>
      {([policy]) => {
        const { contract, products, brokers } = policy;
        const product = products.length > 0 ? products[0] : null;
        const broker = brokers.length > 0 ? brokers[0] : null;

        const initialValues = {
          policyNumber: contract._contractNo.value,
          applicationNo: contract._applicationNo.value,
          contractStartDate: formatTime(contract._contractStartDate.value, {
            year: true,
            month: true,
            day: true,
          }),
          contractEndDate: formatTime(contract._maturityDate.value, {
            year: true,
            month: true,
            day: true,
          }),
          contractDuration: `${contract._contractTermActual.value} ${t('years')}`,
          status: contract._subStatusText.value,
          riskType: contract._riskType.value,
          productName: `${product.productName.value} - ${product.tariffName.value}`,
          insurerName: broker.insurerName.value,
          agencyName: broker.brokerName.value,
        };

        return (
          <Form initialValues={initialValues}>
            {({ submit, isSending, sendError, ...inputProps }) => (
              <>
                <Header right={<CloseButton href="/policy/details" color="darkPetrol" />} />
                <Heading size="h3">{t('policy.detail.general')}</Heading>
                <InputGroup>
                  <TextUnit
                    name="policyNumber"
                    label={contract._contractNo.displayLabel}
                    {...inputProps}
                    noIcon
                    readonly
                  />
                  <TextUnit
                    name="applicationNo"
                    label={contract._applicationNo.displayLabel}
                    {...inputProps}
                    noIcon
                    readonly
                  />
                  <TextUnit
                    name="productName"
                    label={`${product.productName.displayLabel} - ${product.tariffName.displayLabel}`}
                    {...inputProps}
                    noIcon
                    readonly
                  />
                  <TextUnit
                    name="contractStartDate"
                    label={contract._contractStartDate.displayLabel}
                    {...inputProps}
                    noIcon
                    readonly
                  />
                  <TextUnit
                    name="contractEndDate"
                    label={contract._maturityDate.displayLabel}
                    {...inputProps}
                    noIcon
                    readonly
                  />
                  <TextUnit
                    name="contractDuration"
                    label={contract._contractTermActual.displayLabel}
                    {...inputProps}
                    noIcon
                    readonly
                  />
                  <TextUnit
                    name="status"
                    label={contract._contractStatus.displayLabel}
                    {...inputProps}
                    noIcon
                    readonly
                  />
                  {contract._riskType.visibility && (
                    <TextUnit
                      name="riskType"
                      label={contract._riskType.displayLabel}
                      {...inputProps}
                      noIcon
                      readonly
                    />
                  )}
                  <TextUnit
                    name="insurerName"
                    label={broker.insurerName.displayLabel}
                    {...inputProps}
                    noIcon
                    readonly
                  />
                  <TextUnit
                    name="agencyName"
                    label={broker.brokerName.displayLabel}
                    {...inputProps}
                    noIcon
                    readonly
                  />
                </InputGroup>
              </>
            )}
          </Form>
        );
      }}
    </Data>
  );
}

export default connect('activePolicy')(translate(PolicyDetailsGeneralForm));
