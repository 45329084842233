import React, { Component } from 'react';
import { translate } from '../../../../containers/translationContext';
import Form from '../../index';
import SubmitButton from '../../../../components/button/submit';
import { endpoints } from '../../../../config/data';
import { connect } from '../../../../containers/storeContext';
import { actions } from '../../../../store';
import { navigate } from '@reach/router';
import Icon from '../../../image/icon';
import TextUnit from '../../inputUnits/text';
import Paragraph from '../../../paragraph';
import Heading from '../../../heading';
import Thumb from '../../../image/thumb';
import TextLink from '../../../textLink';
import SendError from '../../sendError';
import formatTime from '../../../../utils/formatTime';
import Placeholder from '../../../placeholder';
import Header from '../../../header';
import BackButton from '../../../button/back';
import CloseButton from '../../../button/close';
import Select from '../../inputs/select';

class PaymentMethod extends Component {
  pm = {
    CBILL: 'CBILL',
    SEPA_SDD: 'SEPA_SDD',
  };
  eventName = 'UPDATE_PAYMENT_METHOD';

  step = {
    INPUT: 'Input',
    VERIFY: 'Verify',
    SUCCESS: 'Success',
  };
  inputRefs = [];
  state = {
    paymentMethods: [],
    touched: false,
    success: false,
    isValid: false,
    step: 'Input',
    selectedPaymentMethod: null,
    paymentTypeTouched: false,
    ibanNo: '',
    ibanNoTouched: false,
    bankName: '',
    bankNameTouched: false,
    lastCodeSent: null,
    debugToken: '',
  };

  submitButtonValidationState = paymentMethod => {
    const validationRequired = paymentMethod !== this.pm.CBILL;
    return {
      touched: !validationRequired,
      isValid: !validationRequired,
    };
  };

  componentDidMount = () => {
    const { getData, activePolicy } = this.props;

    var options = {
      onSuccess: data => {
        const paymentMethods = data.map(paymentMethod => {
          return {
            key: `${paymentMethod.code}`,
            label: `${paymentMethod.desc}`,
          };
        });

        const selectedPaymentMethod = paymentMethods[0];
        this.setState({
          paymentMethods,
          selectedPaymentMethod,
          ...this.submitButtonValidationState(selectedPaymentMethod.key),
        });
      },
    };

    getData(`paymentMethods`, options, activePolicy.contractId);
  };

  handleChangePaymentMethod = () => {
    this.setState({
      isValid: this.inputRefs.every(input => (input == null ? true : input.isValid())),
      success: false,
      touched: true,
    });
  };

  handleChangeVerify = () => {
    this.setState({
      isValid: this.inputRefs.every(input => (input == null ? true : input.isValid())),
      success: false,
      touched: true,
    });
  };

  renderBankAndIban = inputProps => {
    const { t } = this.props;

    return (
      <>
        <TextUnit
          name="ibanNo"
          label={t('policy.detail.payments.iban')}
          noIcon
          ref={c => (this.inputRefs[0] = c)}
          {...inputProps}
        />

        <TextUnit
          name="bankName"
          label={t('policy.detail.payments.bank')}
          noIcon
          optional
          ref={c => (this.inputRefs[1] = c)}
          {...inputProps}
        />
      </>
    );
  };

  renderInput = () => {
    const { t } = this.props;
    const { touched, isValid, paymentMethods, selectedPaymentMethod } = this.state;

    return (
      <>
        <Header right={<CloseButton href="/alteration" color="darkPetrol" />} />
        <Heading size="h4">{t('alteration.paymentMethod')}</Heading>
        <Paragraph>{t('alteration.paymentMethod.description')}</Paragraph>
        <Form
          api={endpoints.generateSmsToken}
          onValueChange={() => {
            this.handleChangePaymentMethod();
          }}
          onSuccess={response => {
            const update = () => {
              this.setState({
                step: this.step.VERIFY,
                isValid: false,
                touched: false,
                lastCodeSent: new Date(),
                debugToken: response.data.smsText || '',
              });

              this.inputRefs.length = 0;
            };

            update();
          }}
          initialValues={{
            ibanNo: this.state.ibanNo,
            bankName: this.state.bankName,
            eventName: this.eventName,
          }}
        >
          {({ submit, isSending, sendError, ...inputProps }) => {
            return (
              <>
                <Select
                  name="paymentMethod"
                  items={paymentMethods}
                  value={selectedPaymentMethod.key}
                  onChange={paymentMethod => {
                    this.setState({
                      selectedPaymentMethod: paymentMethod,
                      paymentTypeTouched: true,
                      ...this.submitButtonValidationState(paymentMethod.key),
                    });
                    inputProps.setValueState({ paymentMethod: paymentMethod.key });
                  }}
                  {...inputProps}
                />

                <SendError message={sendError} />

                {selectedPaymentMethod.key === this.pm.SEPA_SDD
                  ? this.renderBankAndIban(inputProps)
                  : ''}

                <Paragraph style={{ margin: '40px' }}>{isSending ? <Placeholder /> : ''}</Paragraph>

                <SubmitButton
                  onClick={() => {
                    this.setState({
                      ...inputProps.values,
                      isValid: false,
                    });
                    submit();
                  }}
                  disabled={isSending || !isValid}
                  hidden={!touched}
                >
                  {t('form.button.save')}
                </SubmitButton>
              </>
            );
          }}
        </Form>
      </>
    );
  };

  renderVerify = () => {
    const { t, activePolicy } = this.props;
    const { touched, success, isValid } = this.state;

    return (
      <>
        <Header
          left={
            <BackButton
              color="darkPetrol"
              onClick={() => {
                this.setState({
                  step: this.step.INPUT,
                  isValid: true,
                  touched: true,
                });
              }}
            />
          }
          right={<CloseButton href="/alteration" color="darkPetrol" />}
        />
        <Form
          autoComplete="off"
          api={endpoints.updatePaymentMethod}
          onValueChange={() => this.handleChangeVerify()}
          onSuccess={response => {
            const update = () => {
              this.inputRefs.length = 0;
              this.setState({
                step: this.step.SUCCESS,
                isValid: false,
                success: true,
              });

              setTimeout(() => {
                navigate('/alteration');
              }, 3000);
            };

            update();
          }}
          initialValues={{
            paymentType: this.state.selectedPaymentMethod.key,
            paymentTypeTouched: this.state.paymentTypeTouched,
            ibanNo: this.state.ibanNo,
            ibanNoTouched: this.state.ibanNoTouched,
            bankName: this.state.bankName,
            bankNameTouched: this.state.bankNameTouched,
            contractId: activePolicy.contractId,
          }}
        >
          {({ submit, isSending, sendError, ...inputProps }) => {
            return (
              <>
                <Heading size="h4">{t('general.sms.verifyHeader')}</Heading>
                <Paragraph>{t('general.sms.verifyBody')}</Paragraph>

                <br />

                <Paragraph>
                  {t('general.sms.requestTime')} {formatTime('en', this.state.lastCodeSent / 1000)}
                </Paragraph>

                <br />
                <TextUnit
                  name="smsToken"
                  label={t('general.sms.verifyCode')}
                  pattern={'^[0-9]{6}$'}
                  ref={c => (this.inputRefs[0] = c)}
                  {...inputProps}
                />

                {isSending ? <Placeholder /> : <SendError message={sendError} />}

                <br />
                <br />

                <SubmitButton
                  onClick={() => {
                    submit();
                  }}
                  disabled={isSending || !isValid}
                  hidden={!touched}
                >
                  {success ? (
                    <>
                      {t('form.button.saved')}
                      <Icon
                        type="check"
                        color="white"
                        style={{ marginTop: '-5px', marginLeft: '5px' }}
                      />
                    </>
                  ) : (
                    t('alteration.complete')
                  )}
                </SubmitButton>
              </>
            );
          }}
        </Form>

        <Form
          api={endpoints.generateSmsToken}
          onSuccess={response => {
            const update = () => {
              this.setState({
                step: this.step.VERIFY,
                isValid: false,
                lastCodeSent: new Date(),
                debugToken: response.data.smsText || '',
              });
            };
            update();
          }}
          initialValues={{
            eventName: this.eventName,
          }}
        >
          {({ submit, isSending, sendError }) => {
            return isSending ? (
              <Placeholder />
            ) : (
              <>
                <Paragraph>
                  {t('general.sms.resendToken')}{' '}
                  <TextLink onClick={submit}>{t('form.button.resend')}</TextLink>
                </Paragraph>
                {['localhost', 'test-cp.forwardyou.com', 'feature-cp.forwardyou.com'].includes(
                  window.location.hostname
                ) ? (
                  <Paragraph>
                    <span style={{ color: '#00879b' }}>
                      <span>
                        Debugging on FWU Test Systems. SMS Code: <b>{this.state.debugToken}</b>
                      </span>
                      <br />
                      <span style={{ fontSize: '0.75em' }}>(localhost, test-cp, feature-cp)</span>
                    </span>
                  </Paragraph>
                ) : (
                  ''
                )}
              </>
            );
          }}
        </Form>
      </>
    );
  };

  renderSuccess = () => {
    const { t } = this.props;

    return (
      <>
        <Header right={<CloseButton href="/alteration" color="darkPetrol" />} />
        <Heading size="h2" align="center">
          {t('alteration.complete')}
        </Heading>
        <Thumb />
        <Paragraph style={{ marginBottom: '40px', textAlign: 'center' }}>
          {t('general.thankYou')}
        </Paragraph>
      </>
    );
  };

  render() {
    const { paymentMethods } = this.state;

    if (paymentMethods.length === 0) {
      return (
        <>
          <Header right={<CloseButton href="/alteration" color="darkPetrol" />} />
          <Placeholder />
        </>
      );
    }

    switch (this.state.step) {
      case this.step.INPUT:
        return this.renderInput();
      case this.step.VERIFY:
        return this.renderVerify();
      case this.step.SUCCESS:
        return this.renderSuccess();
      default:
        return 'ERROR';
    }
  }
}

export default connect(null, actions)(translate(PaymentMethod));
