import React from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import CloseButton from '../components/button/close';
import Heading from '../components/heading';
import Header from '../components/header';
import { translate } from '../containers/translationContext';
import Paragraph from '../components/paragraph/index';
import HTML from '../components/html';
import Data from '../containers/data';
import style from './accountPrivacy.module.scss';

function AccountPrivacy({ t }) {
  return (
    <span className={style.gdpr}>
      <NarrowColumn>
        <Header right={<CloseButton href="/account" color="darkPetrol" />} />
        <Heading style={{ marginBottom: '5rem' }}>{t('settings.privacy')}</Heading>
        <Data keys={[`privacy`]} renderErrors={e => <Paragraph color="alert">{e[0]}</Paragraph>}>
          {([translations]) => (
            <>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.1.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.1.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.1.body.2']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.1.body.3']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.1.body.4']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.body.1']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.1.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.1.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.1.body.2']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.1.body.3']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.1.body.4']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.1.body.5']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.1.body.6']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.1.1.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.1.1.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.1.1.body.2']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.1.2.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.1.2.body.1']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.1.3.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.1.3.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.1.3.body.2']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.2.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.2.body.1']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.2.1.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.2.1.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.1.body.2']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.1.body.3']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.1.body.4']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.1.body.5']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.1.body.6']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.1.body.7']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.1.body.8']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.1.body.9']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.2.2.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.2.2.body.1']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.2.3.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.2.3.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.3.body.2']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.3.body.3']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.3.body.4']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.2.4.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.2.4.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.4.body.2']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.2.5.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.2.5.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.5.body.2']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.2.6.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.2.6.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.6.body.2']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.6.body.3']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.2.7.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.2.7.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.7.body.2']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.2.8.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.2.8.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.8.body.2']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.2.8.body.3']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.3.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.3.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.2.3.body.2']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.4.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.4.body.1']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.2.5.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.2.5.body.1']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.3.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.3.body.1']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.4.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.4.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.4.body.2']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.4.body.3']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.4.body.4']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.4.body.5']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.5.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.5.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.5.body.2']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.5.body.3']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.5.body.4']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.5.body.5']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.5.body.6']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.6.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.6.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.6.body.2']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.6.body.3']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.6.body.4']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.6.body.5']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.6.body.6']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.6.body.7']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.6.body.8']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.6.body.9']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.6.body.10']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.6.body.11']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.7.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.7.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.7.body.2']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.7.body.3']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.7.body.4']}</HTML>
              <Heading size="h6" variant="sans" color="black">
                {translations['gdpr.8.heading']}
              </Heading>
              <HTML component={Paragraph}>{translations['gdpr.8.body.1']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.8.body.2']}</HTML>
              <HTML component={Paragraph}>{translations['gdpr.8.body.3']}</HTML>
            </>
          )}
        </Data>
      </NarrowColumn>
    </span>
  );
}

export default translate(AccountPrivacy);
