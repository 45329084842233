import React, { Component } from 'react';
import routeContext from '../containers/routeContext';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Header from '../components/header';
import Data from '../containers/data';
import CloseButton from '../components/button/close';
import BackButton from '../components/button/back';
import { Redirect } from '@reach/router';
import Answer from '../components/faq/answer';
import Questions from '../components/faq/questions';
import { slug } from '../utils/routing';
import Placeholder from '../components/placeholder';

class AccountFaqDetail extends Component {
  static contextType = routeContext;

  render() {
    const { section, category, question } = this.context;

    return (
      <NarrowColumn>
        <Header
          right={!question ? <CloseButton href="/service" color="darkPetrol" /> : null}
          left={
            question ? (
              <BackButton href={`/service/faq/${category}/${section}`} color="darkPetrol" />
            ) : null
          }
        />

        <Data keys={['faq']} fallback={<Placeholder />}>
          {([faq]) => {
            const cat = faq;
            if (!cat) return <Redirect to="/service" noThrow />;
            const questions = cat.categories.filter(s => slug(s.title) === section);
            if (question && questions[0]) {
              const item = questions[0].questions.filter(
                result => slug(result.question) === question
              )[0];
              return item ? (
                <Answer answer={item.answer} question={item.question} />
              ) : (
                <div>Answer not found</div>
              );
            } else if (questions[0]) {
              return <Questions items={questions} section={section} category={category} />;
            }

            return <div>Questions not found</div>;
          }}
        </Data>
      </NarrowColumn>
    );
  }
}

export default AccountFaqDetail;
