import React from 'react';
import { translate } from '../../containers/translationContext';
import Form from '../form';
import Data from '../../containers/data';
import { connect } from '../../containers/storeContext';
import Placeholder from '../placeholder';
import Heading from '../heading';
import Header from '../header';
import CloseButton from '../button/close';
import InputGroup from '../form/inputGroup';
import { uiElements, prepareInitialFormValues } from '../../utils/uiFormFunctions';

function TechnicalPaymentPayer({ t, formatTime, activePolicy }) {
  return (
    <Data keys={[`policy:${activePolicy.contractId}`]} fallback={<Placeholder />}>
      {([policy]) => {
        const { premiumPayers } = policy;
        const initialValues = {
          ...(premiumPayers.length > 0
            ? prepareInitialFormValues(premiumPayers[0], formatTime)
            : null),
        };

        return (
          <Form initialValues={initialValues}>
            {({ submit, isSending, sendError, ...inputProps }) => (
              <>
                <Header right={<CloseButton href="/payments/technical" color="darkPetrol" />} />
                <Heading size="h3">{t('policy.payments.technical.payer')}</Heading>
                <InputGroup>
                  {uiElements(premiumPayers.length > 0 ? premiumPayers[0] : {}, inputProps)}
                </InputGroup>
              </>
            )}
          </Form>
        );
      }}
    </Data>
  );
}

export default connect('activePolicy')(translate(TechnicalPaymentPayer));
