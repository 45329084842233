import React, { Component } from 'react';
import { translate } from '../../../containers/translationContext';
import Form from '../index';
import SubmitButton from '../../button/submit';
import { endpoints } from '../../../config/data';
import { connect } from '../../../containers/storeContext';
import { actions } from '../../../store';
import { navigate } from '@reach/router';
import Icon from '../../image/icon';
import TextUnit from '../inputUnits/text';
import Paragraph from '../../paragraph';
import Heading from '../../heading';
import Thumb from '../../image/thumb';
import TextLink from '../../textLink';
import SendError from '../sendError';
import formatTime from '../../../utils/formatTime';
import Placeholder from '../../placeholder';
import Header from '../../header';
import BackButton from '../../button/back';
import CloseButton from '../../button/close';

class UpdateTaxIdForm extends Component {
  eventName = 'UPDATE_TAX_ID';

  step = {
    INPUT: 'Input',
    VERIFY: 'Verify',
    SUCCESS: 'Success',
  };
  inputRefs = [];
  state = {
    isValid: !this.props.user.taxId && this.props.user.taxId.length > 0,
    touched: false,
    success: false,
    step: 'Input',
    lastCodeSent: null,
    debugToken: '',
  };

  handleChangeTaxId = () => {
    this.setState({
      isValid: this.inputRefs.every(input => input.isValid()),
      success: false,
      touched: true,
    });
  };

  handleChangeVerify = () => {
    this.setState({
      isValid: this.inputRefs.every(input => input.isValid()),
      success: false,
      touched: true,
    });
  };

  renderVerifyMobileNumber = () => {
    const { t } = this.props;

    return (
      <>
        <Header right={<CloseButton href="/account" color="darkPetrol" />} />
        <Heading size="h4">{t('phone.verifyHeader')}</Heading>
        <Paragraph>{t('taxId.verifyFirst')}</Paragraph>
        <Form>
          {({ submit, isSending, sendError, ...inputProps }) => {
            return (
              <>
                <SubmitButton href="/account/phone">{t('phone.verifyHeader')}</SubmitButton>
              </>
            );
          }}
        </Form>
      </>
    );
  };

  renderInput = () => {
    const { t, user } = this.props;
    const { touched, isValid } = this.state;

    return (
      <>
        <Header right={<CloseButton href="/account" color="darkPetrol" />} />
        <Heading size="h4">{t('taxId.header')}</Heading>
        <Paragraph>{t('taxId.body')}</Paragraph>
        <Form
          api={endpoints.generateSmsToken}
          onValueChange={() => this.handleChangeTaxId()}
          onSuccess={response => {
            const update = () => {
              this.setState({
                step: this.step.VERIFY,
                isValid: false,
                touched: false,
                lastCodeSent: new Date(),
                taxId: this.inputRefs[0].props.values.taxId,
                debugToken: response.data.smsText || '',
              });

              this.inputRefs.length = 0;
            };

            update();
          }}
          initialValues={{
            userId: user.userId,
            eventName: this.eventName,
          }}
        >
          {({ submit, isSending, sendError, ...inputProps }) => {
            return (
              <>
                <TextUnit
                  name="taxId"
                  icon="policy"
                  label={t('form.label.taxId')}
                  ref={c => (this.inputRefs[0] = c)}
                  {...inputProps}
                />

                <SendError message={sendError} />

                <Paragraph style={{ margin: '40px' }}>{isSending ? <Placeholder /> : ''}</Paragraph>

                <SubmitButton
                  onClick={() => {
                    this.setState({
                      isValid: false,
                    });
                    submit();
                  }}
                  disabled={isSending || !isValid}
                  hidden={!touched}
                >
                  {t('form.button.save')}
                </SubmitButton>
              </>
            );
          }}
        </Form>
      </>
    );
  };

  renderVerify = () => {
    const { t, user } = this.props;
    const { touched, success, isValid } = this.state;

    return (
      <>
        <Header
          left={
            <BackButton
              color="darkPetrol"
              onClick={() => {
                this.setState({
                  step: this.step.INPUT,
                  isValid: true,
                  touched: true,
                });
              }}
            />
          }
          right={<CloseButton href="/account" color="darkPetrol" />}
        />
        <Form
          autoComplete="off"
          api={endpoints.updateTaxId}
          onValueChange={() => this.handleChangeVerify()}
          onSuccess={response => {
            const { getData, invalidateData, activePolicy, setActivePolicy } = this.props;

            const update = () => {
              var options = {
                onSuccess: data => {
                  var matchingPolicy = data.filter(
                    policy => policy.contractId === activePolicy.contractId
                  );
                  if (matchingPolicy.length === 1) {
                    setActivePolicy(matchingPolicy[0]);
                  }
                },
              };

              getData(`policies`, options);

              this.inputRefs.length = 0;
              this.setState({
                step: this.step.SUCCESS,
                isValid: false,
                success: true,
              });

              setTimeout(() => {
                navigate('/account');
              }, 3000);
            };

            Promise.all([
              invalidateData('user'),
              invalidateData('policies'),
              getData('user'),
              getData('policies'),
            ])
              .then(update)
              .catch(update);
          }}
          initialValues={{
            userId: user.userId,
            taxId: this.state.taxId,
          }}
        >
          {({ submit, isSending, sendError, ...inputProps }) => {
            return (
              <>
                <Heading size="h4">{t('general.sms.verifyHeader')}</Heading>
                <Paragraph>{t('general.sms.verifyBody')}</Paragraph>

                <br />

                <Paragraph>
                  {t('general.sms.requestTime')} {formatTime('en', this.state.lastCodeSent / 1000)}
                </Paragraph>

                <br />
                <TextUnit
                  name="smsToken"
                  label={t('general.sms.verifyCode')}
                  pattern={'^[0-9]{6}$'}
                  ref={c => (this.inputRefs[0] = c)}
                  {...inputProps}
                />

                {isSending ? <Placeholder /> : <SendError message={sendError} />}

                <br />
                <br />

                <SubmitButton
                  onClick={() => {
                    this.setState({
                      isValid: false,
                    });
                    submit();
                  }}
                  disabled={isSending || !isValid}
                  hidden={!touched}
                >
                  {success ? (
                    <>
                      {t('form.button.saved')}
                      <Icon
                        type="check"
                        color="white"
                        style={{ marginTop: '-5px', marginLeft: '5px' }}
                      />
                    </>
                  ) : (
                    t('taxId.updateButtonText')
                  )}
                </SubmitButton>
              </>
            );
          }}
        </Form>

        <Form
          api={endpoints.generateSmsToken}
          onSuccess={response => {
            const update = () => {
              this.setState({
                step: this.step.VERIFY,
                isValid: false,
                lastCodeSent: new Date(),
                debugToken: response.data.smsText || '',
              });
            };
            update();
          }}
          initialValues={{
            userId: user.userId,
            eventName: this.eventName,
          }}
        >
          {({ submit, isSending, sendError }) => {
            return isSending ? (
              <Placeholder />
            ) : (
              <>
                <Paragraph>
                  {t('general.sms.resendToken')}{' '}
                  <TextLink onClick={submit}>{t('form.button.resend')}</TextLink>
                </Paragraph>
                {['localhost', 'test-cp.forwardyou.com', 'feature-cp.forwardyou.com'].includes(
                  window.location.hostname
                ) ? (
                  <Paragraph>
                    <span style={{ color: '#00879b' }}>
                      <span>
                        Debugging on FWU Test Systems. SMS Code: <b>{this.state.debugToken}</b>
                      </span>
                      <br />
                      <span style={{ fontSize: '0.75em' }}>(localhost, test-cp, feature-cp)</span>
                    </span>
                  </Paragraph>
                ) : (
                  ''
                )}
              </>
            );
          }}
        </Form>
      </>
    );
  };

  renderSuccess = () => {
    const { t } = this.props;

    return (
      <>
        <Heading size="h2" align="center">
          {t('taxId.successHeading')}
        </Heading>
        <Thumb />
        <Paragraph style={{ marginBottom: '40px', textAlign: 'center' }}>
          {t('general.thankYou')}
        </Paragraph>
      </>
    );
  };

  render() {
    const { user } = this.props;

    if (!user.isPhoneVerified) {
      return this.renderVerifyMobileNumber();
    }

    switch (this.state.step) {
      case this.step.INPUT:
        return this.renderInput();
      case this.step.VERIFY:
        return this.renderVerify();
      case this.step.SUCCESS:
        return this.renderSuccess();
      default:
        return 'ERROR';
    }
  }
}

export default connect('activePolicy', actions)(translate(UpdateTaxIdForm));
