const charsets = [
  {
    regex: /[a-z]/,
    size: 26,
  },
  {
    regex: /[A-Z]/,
    size: 26,
  },
  {
    regex: /[0-9]/,
    size: 10,
  },
  {
    regex: /\s/,
    size: 1,
  },
  {
    regex: /[!@#$%^&*()]/,
    size: 10,
  },
  {
    regex: /[`~\-_=+[{\]}\\|;:'",<.>/?]/,
    size: 20,
  },
  {
    regex: /[^0-9a-zA-Z\s!@#$%^&*()`~\-_=+[{\]}\\|;:'",<.>/?]/,
    size: 50, // arbitrary, for when there's a special character not in any of the charsets above
  },
];

function log2(n) {
  return Math.log(n) / Math.LN2;
}

function base(string) {
  return charsets.reduce((acc, cur) => (cur.regex.test(string) ? acc + cur.size : acc), 0) || 1;
}

function entropy(string = '') {
  return Math.floor(log2(base(string) ** string.length));
}

export { entropy };
