/** Persists a unistore store to disk using a provided adapter
 *  @name persistStore
 *  @param {Object} [store] unistore store to perist
 *  @param {Object} [adapter] adapter to persist state
 *  @param {Object} [config] unistore configuration
 *  @returns {Function} cancel()
 *  @example
 *   let store = createStore();
 *   let adapter = indexedDbAdapter();
 *   persistStore(store, adapter);
 */
export default function persistStore(store, adapter, conf = {}) {
  const version = conf.version || 1;

  store.setState({ hydrated: false });

  const initPromise = Promise.resolve(adapter.getState()).then(state => {
    if (!state || (!state.version && conf.migration) || state.version < version) {
      if (conf.migration) {
        Promise.resolve(conf.migration(state, version)).then(migrated => {
          store.setState(Object.assign({}, migrated, { hydrated: true }));
        });
      } else {
        store.setState({ hydrated: true, version });
      }
    } else if (conf.hydration) {
      store.setState(Object.assign({}, conf.hydration(state), { hydrated: true }));
    } else {
      state.hydrated = true;
      store.setState(state);
    }
  });

  let timer;
  const unsubscribe = store.subscribe(() => {
    if (!timer)
      timer = setTimeout(() => {
        const state = store.getState();
        state.version = state.version || version;
        adapter.setState((conf.map || Object)(state));
        timer = null;
      }, conf.debounceTime || 100);
  });

  return {
    unsubscribe: () => {
      unsubscribe();
      clearTimeout(timer);
    },
    loaded: initPromise,
  };
}
