import React from 'react';
import Close from '../../image/icon/close';
import style from './style.module.scss';
import Paragraph from '../../paragraph';
import Popup from '../../popup';
import cc from 'classcat';
import HTML from '../../html/index';

function ToolTip({ children, white, inline, html }) {
  return (
    <Popup className={cc([style.popup, inline && style.inline])}>
      {({ getButtonProps, getPopupProps, isOpen, close }) => [
        <button
          type="button"
          className={cc([style.openButton, white && style.white, inline && style.inline])}
          {...getButtonProps()}
        >
          ?
        </button>,
        isOpen && (
          <div className={style.tooltip} {...getPopupProps()}>
            <button className={style.closeButton}>
              <Close onClick={close} />
            </button>
            {html ? (
              <HTML component={Paragraph}>{children}</HTML>
            ) : (
              <Paragraph>{children}</Paragraph>
            )}
          </div>
        ),
      ]}
    </Popup>
  );
}

export default ToolTip;
