import React from 'react';
import Header from '../components/header';
import BackButton from '../components/button/back';
import { translate } from '../containers/translationContext';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Heading from '../components/heading';
import CardNav from '../components/cardNav';

function DetailTechnical({ t }) {
  return (
    <>
      <Header left={<BackButton href="/payments" color="white" />} />
      <NarrowColumn>
        <Header left="menu" />
        <Heading color="white" size="h4">
          {t('policy.payments.technical')}
        </Heading>
        <CardNav>
          <CardNav.Card href="/payments/technical/paymentData" arrow>
            <Heading size="h6" variant="light" ieFlexGrow>
              {t('policy.payments.technical.paymentData')}
            </Heading>
          </CardNav.Card>
          <CardNav.Card href="/payments/technical/payer" arrow>
            <Heading size="h6" variant="light" ieFlexGrow>
              {t('policy.payments.technical.payer')}
            </Heading>
          </CardNav.Card>
        </CardNav>
      </NarrowColumn>
    </>
  );
}

export default translate(DetailTechnical);
