import React from 'react';
import TextLink from '../textLink';
import Request from '../../containers/request';
import { endpoints } from '../../config/data';
import { translate } from '../../containers/translationContext';

function ResendEmail({ t, email, className }) {
  return (
    <Request
      api={endpoints.resendEmail}
      params={{ loginId: email, processName: 'REGISTRATION' }}
      lazy
    >
      {({ fetch, fetching, error, response }) => {
        if (fetching) {
          return <span />;
        }
        if (error || (response && !response.success)) {
          return <span />;
        }
        return (
          <span>
            <TextLink onClick={fetch}>{t('form.button.resend')}</TextLink>{' '}
            {response && response.success && t('form.button.resendSuccess')}
          </span>
        );
      }}
    </Request>
  );
}

export default translate(ResendEmail);
