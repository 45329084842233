import React, { useState } from 'react';
import { connect } from '../containers/storeContext';
import { translate } from '../containers/translationContext';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Header from '../components/header';
import Heading from '../components/heading';
import CardNav from '../components/cardNav';
import Time from '../components/time';
import Data from '../containers/data';
import Box from '../components/box';
import Paragraph from '../components/paragraph';
import Placeholder from '../components/placeholder';
import { request } from '../utils/request';

function Documents({ t, activePolicy, authToken }) {
  const [isLoading, setIsloading] = useState(false);

  function convertReadableStreamToFile(readableStream, filename) {
    const reader = readableStream.getReader();
    const chunks = [];

    function read() {
      return reader.read().then(({ done, value }) => {
        if (done) {
          const fileData = new Uint8Array(chunks.reduce((acc, chunk) => acc + chunk.length, 0));
          let offset = 0;
          chunks.forEach(chunk => {
            fileData.set(chunk, offset);
            offset += chunk.length;
          });

          const file = new File([fileData], filename, { type: 'application/pdf;base64' });
          const url = URL.createObjectURL(file);

          return url;
        }

        chunks.push(value);
        return read();
      });
    }

    return read();
  }

  const handleCallBack = (documentId, contractId) => {
    setIsloading(true);
    request(
      `/api/document?documentId=${documentId}&contractId=${contractId}`,
      undefined,
      'get',
      authToken
    )
      .then(response => {
        convertReadableStreamToFile(response.body, 'document.pdf')
          .then(fileURL => {
            setIsloading(false);
            window.open(fileURL);
          })
          .catch(err => {
            setIsloading(false);
          });
      })
      .catch(err => {
        setIsloading(false);
        console.error(err);
      });
  };

  return (
    <NarrowColumn>
      <Header left="menu" />
      <Heading color="white" size="h4">
        {t('policy.documents')}
      </Heading>
      <Heading color="white" variant="sans" size="h6" weight="light">
        {activePolicy.description}
      </Heading>

      {isLoading ? (
        <Placeholder color="white" />
      ) : (
        <Data
          keys={[`documents:${activePolicy.contractId}`]}
          fallback={<Placeholder color="white" />}
          renderErrors={e => (
            <Paragraph color="white" size="small">
              {e[0]}
            </Paragraph>
          )}
        >
          {([documents]) => (
            <CardNav>
              {documents && documents.length
                ? documents.map(doc => (
                    <CardNav.Card
                      href={`/document?documentId=${doc.documentId}&contractId=${activePolicy.contractId}&jwt=${authToken}`}
                      arrow
                      key={doc.documentId}
                      isCallBack
                      handleCallBack={() => handleCallBack(doc.documentId, activePolicy.contractId)}
                    >
                      <Box column style={{ width: 'calc(100% - 25px)' }}>
                        <Heading
                          color="black"
                          size="h6"
                          variant="sans"
                          style={{
                            margin: 0,
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                          }}
                        >
                          {doc.hasOwnProperty('description') ? doc.description : doc.name}
                        </Heading>
                        <Paragraph color="lightishGray" size="xSmall">
                          <Time timestamp={doc.date} day month="long" year />
                        </Paragraph>
                      </Box>
                    </CardNav.Card>
                  ))
                : '' /*(
                  <Paragraph color="white asd">{t('policy.documents.none')}</Paragraph>
                )*/}
            </CardNav>
          )}
        </Data>
      )}
    </NarrowColumn>
  );
}

export default connect('activePolicy,authToken')(translate(Documents));
