import React from 'react';
import { func, node, oneOf, string } from 'prop-types';
import cc from 'classcat';
import style from './style.module.scss';

Paragraph.propTypes = {
  align: oneOf(['left', 'center', 'right']),
  color: oneOf(['black', 'white', 'alert', 'darkPetrol', 'petrol', 'mediumGray', 'lightishGray']),
  size: oneOf(['normal', 'small', 'xSmall']),
  children: node,
  className: string,
  component: string,
  innerRef: func,
};

function Paragraph({
  align = 'left',
  color = 'black',
  size = 'normal',
  component: Comp = 'p',
  children,
  className,
  innerRef,
  ...rest
}) {
  const classNames = cc([style.paragraph, style[color], style[align], style[size], className]);

  return (
    <Comp className={classNames} ref={innerRef} {...rest}>
      {children}
    </Comp>
  );
}

export default Paragraph;
