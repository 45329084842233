import React from 'react';

function Check({ style, ...props }) {
  return (
    <svg viewBox="0 0 50 50" {...props} style={{ height: '22px', ...style }}>
      <path
        d="M43.4,12.8c-0.7-0.6-1.8-0.4-2.4,0.3L22.1,37.3l-10.3-9.1c-0.7-0.6-1.8-0.6-2.4,0.2c-0.6,0.7-0.6,1.8,0.2,2.4l11.6,10.3
	c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.2,0,0.4,0
	c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
	c0,0,0,0,0,0c0,0,0,0,0,0l20-25.7C44.3,14.5,44.2,13.4,43.4,12.8z"
      />
    </svg>
  );
}

export default Check;
