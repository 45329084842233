import { Component } from 'react';
import { connect } from '../containers/storeContext';

class IsAuthorized extends Component {
  static defaultProps = {
    renderYes: noop,
    renderNo: noop,
    onYes: noop,
    onNo: noop,
  };

  componentDidMount() {
    const { onYes, onNo } = this.props;

    if (this.isYes()) {
      onYes();
    } else {
      onNo();
    }
  }

  isYes() {
    const { authToken, expiresAt } = this.props;

    // TODO: make better check
    return authToken && expiresAt && expiresAt > Date.now();
  }

  render() {
    const { render, renderYes, renderNo } = this.props;
    const isYes = this.isYes();

    if (render) {
      return render(isYes);
    }

    if (isYes) {
      return renderYes();
    }

    return renderNo();
  }
}

function noop() {
  return null;
}

export default connect('authToken,expiresAt')(IsAuthorized);
