import store, { translations } from '../store';

function t(translations, key, ...values) {
  const text = translations[key];

  if (text === undefined) {
    console.log(key);
    return '-- INVALID TRANSLATION ID --';
  }

  const parts = text.split(/({\w+})/g);
  let allText = true;

  values.forEach((value, i) => {
    const j = parts.indexOf(`{${i + 1}}`);
    if (j > -1) {
      parts[j] = value;
    }
    if (typeof value !== 'string') {
      allText = false;
    }
  });

  if (allText) {
    return parts.join('');
  }

  return parts;
}

function translate(language, ...args) {
  const lang = language || store.getState().language;
  if (!translations[lang]) throw new Error('No translations found for language');
  return t(translations[lang], ...args);
}

export { translate, t };
