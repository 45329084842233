import React from 'react';
import Heading from '../heading';
import Card from './card';
import style from './policy.module.scss';
import { T } from '../../containers/translationContext';
import Data from '../../containers/data';
import Paragraph from '../paragraph/index';

function Policy({ contractId, description, policyHolderName, onClick }) {
  return (
    <Card href="/dashboard" onClick={onClick} dontTransition>
      <Heading variant="sans" size="h6" color="darkPetrol" className={style.heading}>
        {description}
      </Heading>
      <Paragraph color="darkPetrol" size="small">
        {policyHolderName}
      </Paragraph>

      <Data keys={[`policy:${contractId}`, `projectedValues:${contractId}`]}>
        {([policy]) => (
          <div className={style.valueWrapper}>
            <div className={style.valueContainer}>{contractId}</div>
            <div className={style.valueLabel}>
              <T id="policy.detail.general.policyNumber" />
            </div>
          </div>
        )}
      </Data>
    </Card>
  );
}

export default Policy;
