import React from 'react';
import style from './style.module.scss';
import cc from 'classcat';

function Policy({ className, ...props }) {
  return (
    <svg viewBox="0 0 55 55" {...props} className={cc([className, style.stroke])}>
      <g>
        <path d="M23.8,52.3C-0.5,40.8,1.2,17.7,2,12.2c0.1-0.7,0.7-1.1,1.3-1.1c3.8,0.3,16,0.5,20.5-7.1H24   c4.6,7.6,16.7,7.4,20.5,7.1c0.7,0,1.2,0.4,1.3,1.1c0.8,5.5,2.5,28.6-21.9,40.1H23.8z" />
        <g>
          <line x1="15.3" y1="28.7" x2="22.8" y2="38.8" />
        </g>
        <g>
          <line x1="33.7" y1="20.8" x2="22.9" y2="38.9" />
        </g>
      </g>
    </svg>
  );
}

export default Policy;
