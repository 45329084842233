import React from 'react';
import TextUnit from '../components/form/inputUnits/text';

const formatFieldData = (formatTime, dataType, dataValue) => {
  switch (dataType) {
    case 'String':
    case 'Number':
      return `${dataValue}` || ' ';
    case 'Date':
      return formatTime(dataValue, { year: true, month: true, day: true }) || ' ';
    case 'Percentage':
      const value = `${dataValue}` || ' ';
      return value.length ? `${value}%` : value;
    default:
      return dataValue || ' ';
  }
};

const textUnit = (key, data, inputProps) => {
  return (
    <TextUnit
      key={key}
      name={key}
      label={data.displayLabel || key}
      {...inputProps}
      noIcon
      readonly
    />
  );
};

const prepareInitialFormValues = (dataProvider, formatTime) => {
  const result = {};
  for (const key in dataProvider) {
    if (dataProvider[key].visibility) {
      const data = dataProvider[key];
      result[key] = formatFieldData(formatTime, data.dataType, data.value);
    }
  }
  return result;
};

const fieldHasValue = field => {
  return field.value.toString().trim().length > 0;
};

const uiElements = (dataProvider, inputProps) => {
  return (
    <>
      {(() => {
        const ui = [];
        for (const key in dataProvider) {
          if (dataProvider[key].visibility && fieldHasValue(dataProvider[key])) {
            ui.push(textUnit(key, dataProvider[key], inputProps));
          }
        }
        return ui;
      })()}
    </>
  );
};

export { formatFieldData, textUnit, prepareInitialFormValues, uiElements };
