import React from 'react';
import style from './style.module.scss';
import cc from 'classcat';

function Password({ className, ...props }) {
  return (
    <svg viewBox="0 0 55 55" className={cc([className, style.stroke, style.small])} {...props}>
      <g>
        <path d="M31.3,52.2H8.9c-4,0-7.4-3.3-7.4-7.4V24c0-1.2,1-2.2,2.2-2.2h32.7c1.2,0,2.2,1,2.2,2.2v20.8   C38.7,48.8,35.4,52.2,31.3,52.2z" />
        <path d="M9.5,22.2V12.1C9.5,12.1,9.1,2,20,2" />
        <path d="M30.3,22.2V12.1c0,0,0.4-10.1-10.5-10.1" />
      </g>
    </svg>
  );
}

export default Password;
