import React from 'react';
import style from './style.module.scss';
import Money from '../../../components/money';
import Back from '../../image/icon/back';
import Link from '../../link';
import formatTime from '../../../utils/formatTime';
import { connect } from '../../../containers/storeContext';

function PolicyValue({ projected, currency, url, name, language }) {
  return (
    <div className={style.container}>
      {projected.values.map((policyValue, i) => {
        const { displayLabel, dataType, value, visibility } = policyValue;
        // Don't render this value if it's not visible
        if (!visibility) {
          return null;
        }
        let component = null;

        switch (dataType) {
          case 'Percentage':
            component = <PolicyValuePercentage value={value} />;
            break;
          default:
            component = <PolicyValueCurrency value={value} currency={currency} />;
            break;
        }

        let href = url;
        if (name === 'allocatedBonus' && i === 0) href = `/dashboard/explanation/subscriptionBonus`;

        const valueDate = formatTime(language, projected.valueDate.value, {
          year: true,
          month: true,
          day: true,
        });
        const valuationLabel = `${projected.valueDate.displayLabel} ${valueDate}`;

        return (
          <div key={i}>
            {component}
            <Link className={style.label} href={href}>
              <div>
                {displayLabel}
                <Back className={style.arrow} />
              </div>
              {valuationLabel}
            </Link>
          </div>
        );
      })}
    </div>
  );
}

function PolicyValueCurrency({ value, currency }) {
  return (
    <Money value={value} currency={currency}>
      {({ symbol, rounded }) => (
        <div className={style.value}>
          <span className={[style.valueBold, style.nowrap].join(' ')}>
            {rounded} {symbol}
          </span>
        </div>
      )}
    </Money>
  );
}

function PolicyValuePercentage({ value }) {
  return (
    <div className={style.value}>
      <span className={style.valueBold}>{value}%</span>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return { language: state.language };
};

export default connect(mapStateToProps)(PolicyValue);
