import React, { Component } from 'react';
import translationContext from '../../../../containers/translationContext';
import Heading from '../../../../components/heading';

class DigitalConsent extends Component {
  static contextType = translationContext;

  isValid = () => true;

  render() {
    const { t } = this.context;
    const { marketId } = this.props.values;

    return (
      <>
        <Heading size="h4" component="h2">
          {t('register.digitalConsent.header')}
        </Heading>
        <p style={{ fontSize: '1.8rem' }}>
          {t('register.digitalConsent.body')} {t(`register.${marketId}.branch`)}.
        </p>
      </>
    );
  }
}

export default DigitalConsent;
