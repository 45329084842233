import SYMBOLS from '../config/currencySymbols';

function formatMoney(value, currency, language = 'de') {
  const symbol = SYMBOLS[currency];
  const v = value.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const testV = (1000).toLocaleString(language);
  const usesPointFor1000 = testV.indexOf('.') > -1;
  const radix = usesPointFor1000 ? ',' : '.';
  const thousandDelimiter = usesPointFor1000 ? '.' : ',';

  const parts = v.split(/[.,]/g);
  const decimals = `${radix}${parts.pop()}`;
  const big = parts.shift();
  const small = parts.length ? `${thousandDelimiter}${parts.join(thousandDelimiter)}` : '';

  return { v, big, small, decimals, symbol };
}

function formatMoneyString(value, currency, language) {
  return value.toLocaleString(language, { style: 'currency', currency });
}

export default formatMoney;
export { formatMoneyString };
