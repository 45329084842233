import React, { Component } from 'react';
import { isTargetWithin } from '../utils/dom';

class Popup extends Component {
  static defaultProps = {
    onOpen: () => {},
  };

  state = { open: false };

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
    document.addEventListener('touchstart', this.handleTouch, { passive: true });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
    document.removeEventListener('touchstart', this.handleTouch);
  }

  getButtonProps = props => ({
    onClick: this.handleButtonClick,
    ref: c => (this.button = c),
    key: 'button',
    ...props,
  });

  getPopupProps = props => ({
    ref: c => (this.popup = c),
    key: 'popup',
    ...props,
  });

  handleClick = e => {
    if (
      this.state.open &&
      !isTargetWithin(e.target, this.button, true) &&
      !isTargetWithin(e.target, this.popup, true)
    ) {
      this.setState({ open: false });
    }
  };

  handleTouch = e => {
    if (
      this.state.open &&
      !isTargetWithin(e.target, this.button, true, false) &&
      !isTargetWithin(e.target, this.popup, true, false)
    ) {
      this.setState({ open: false });
    }
  };

  handleButtonClick = e => {
    this.setState(
      state => ({
        open: !state.open,
      }),
      () => this.state.open && this.props.onOpen()
    );
  };

  close = e => {
    this.setState({ open: false });
  };

  render() {
    const { style, onOpen, ...rest } = this.props;
    return (
      <div style={{ ...style }} {...rest}>
        {this.props.children({
          getButtonProps: this.getButtonProps,
          getPopupProps: this.getPopupProps,
          isOpen: this.state.open,
          close: this.close,
        })}
      </div>
    );
  }
}

export default Popup;
