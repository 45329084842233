import React, { useState } from 'react';
import { translate } from '../../../containers/translationContext';
import Form from '../../../components/form';
import TextUnit from '../inputUnits/text';
import Data from '../../../containers/data';
import { connect } from '../../../containers/storeContext';
import Placeholder from '../../placeholder';
import Select from '../inputUnits/select';
import Heading from '../../heading';
import Header from '../../header';
import CloseButton from '../../button/close';
import InputGroup from '../inputGroup';
import { formatFieldData } from '../../../utils/uiFormFunctions';
import style from './style.module.scss';

const suffixFuncDeath = key => `${key}Death`;
const suffixFuncSurvival = key => `${key}Survival`;

const textUnit = (key, data, inputProps, suffixFunc) => {
  return (
    <TextUnit
      key={suffixFunc(key)}
      name={suffixFunc(key)}
      label={data.displayLabel || key}
      {...inputProps}
      noIcon
      readonly
    />
  );
};

const messageUnit = (key, data, inputProps, suffixFunc) => {
  return (
    <div key={suffixFunc(key)} className={style.textColor}>
      {data.value}
    </div>
  );
};

const uiElement = (key, data, inputProps, suffixFunc) => {
  switch (data.dataType) {
    case 'Message':
      return messageUnit(key, data, inputProps, suffixFunc);
    default:
      return textUnit(key, data, inputProps, suffixFunc);
  }
};

const prepareValues = (beneficiary, formatTime, suffixFunc) => {
  const result = {};
  for (const key in beneficiary) {
    if (beneficiary[key].visibility) {
      const data = beneficiary[key];
      result[suffixFunc(key)] = formatFieldData(formatTime, data.dataType, data.value);
    }
  }
  return result;
};

const uiElements = (beneficiaries, inputProps, formatTime, suffixFunc, idx, setIdx) => {
  let current = beneficiaries[idx];
  return (
    <>
      {beneficiaries.length > 1 ? (
        <Select
          name={suffixFunc('beneficiarySelectInCaseOf')}
          items={beneficiaries.map((beneficiary, index) => {
            return {
              key: `${index}`,
              label: `${beneficiary.name.value} ${beneficiary.surname.value}`,
            };
          })}
          width="auto"
          onChange={index => {
            setIdx(index);
            inputProps.setValueState(prepareValues(beneficiaries[index], formatTime, suffixFunc));
          }}
          {...inputProps}
        />
      ) : null}

      {(() => {
        const ui = [];
        for (const key in current) {
          if (current[key].visibility) {
            ui.push(uiElement(key, current[key], inputProps, suffixFunc));
          }
        }
        return ui;
      })()}
    </>
  );
};

function PolicyDetailsBeneficiariesForm({ t, formatTime, activePolicy }) {
  const [idxDeath, setIdxDeath] = useState(0);
  const [idxSurvival, setIdxSurvival] = useState(0);

  return (
    <Data keys={[`policy:${activePolicy.contractId}`]} fallback={<Placeholder />}>
      {([policy]) => {
        const { beneficiariesCaseOfDeath, beneficiariesCaseOfSurvival } = policy;
        const initialValues = {
          ...(beneficiariesCaseOfDeath.values.length > 0
            ? prepareValues(beneficiariesCaseOfDeath.values[0], formatTime, suffixFuncDeath)
            : null),
          ...(beneficiariesCaseOfSurvival.values.length > 0
            ? prepareValues(beneficiariesCaseOfSurvival.values[0], formatTime, suffixFuncSurvival)
            : null),
        };

        return (
          <Form initialValues={initialValues}>
            {({ submit, isSending, sendError, ...inputProps }) => (
              <>
                <Header right={<CloseButton href="/policy/details" color="darkPetrol" />} />
                <Heading size="h3">{t('policy.detail.beneficiaries')}</Heading>
                <InputGroup
                  heading={t('policy.detail.beneficiaries.caseOfDeath')}
                  marginTop={40}
                  color="black"
                  className={style.fontForBeneficiaries}
                >
                  {uiElements(
                    beneficiariesCaseOfDeath.values,
                    inputProps,
                    formatTime,
                    suffixFuncDeath,
                    idxDeath,
                    setIdxDeath
                  )}
                </InputGroup>
                <InputGroup
                  heading={t('policy.detail.beneficiaries.caseOfSurvival')}
                  marginTop={40}
                  color="black"
                  className={style.fontForBeneficiaries}
                >
                  {uiElements(
                    beneficiariesCaseOfSurvival.values,
                    inputProps,
                    formatTime,
                    suffixFuncSurvival,
                    idxSurvival,
                    setIdxSurvival
                  )}
                </InputGroup>
              </>
            )}
          </Form>
        );
      }}
    </Data>
  );
}

export default connect('activePolicy')(translate(PolicyDetailsBeneficiariesForm));
