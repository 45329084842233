function isOrContainsNode(parent, child) {
  return parent === child || containsNode(parent, child);
}

function containsNode(parent, child) {
  return parent.contains(child);
}

function isTargetWithin(eventTarget, parent, includeParent = false, checkActiveElement = true) {
  const checkFunc = includeParent ? isOrContainsNode : containsNode;
  return (
    checkFunc(parent, eventTarget) ||
    (checkActiveElement && checkFunc(parent, document.activeElement))
  );
}

function testCSSProp(prop, value) {
  return window.CSS && CSS.supports && CSS.supports(prop, value);
}

export { isTargetWithin, containsNode, isOrContainsNode, testCSSProp };
