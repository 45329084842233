import React from 'react';
import style from './style.module.scss';
import Icon from '../image/icon';

const MenuDropDown = props => {
  const { options } = props;

  return (
    <div className={style.dropdown}>
      <div className={style.dropbtn} onClick={props.toggleMenu}>
        <Icon color="white" type="dotsMenu" style={{ paddingTop: '4px' }} />
      </div>
      {props.menuHide && (
        <div className={style.dropdown_content}>
          {options &&
            options.length &&
            options.map((opt, _idx) => (
              <div key={_idx} className={style.option} onClick={opt.optionClick}>
                <Icon color={opt.iconColor} type={opt.iconType} /> {opt.optionTitle}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default MenuDropDown;
