import React, { useState } from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Header from '../components/header';
import Message from '../components/message';
import CardNav from '../components/cardNav';
import Data from '../containers/data';
import Placeholder from '../components/placeholder';
import { T } from '../containers/translationContext';
import Paragraph from '../components/paragraph';
import Heading from '../components/heading';
import style from '../style/global/base.module.scss';
import { endpoints } from '../config/data';
import { boundActions } from '../store';
import MenuDropDown from '../components/MenuDropDown';

const Messages = () => {
  const { getData, invalidateData } = boundActions;
  // const [messagesStatus, setMessagesStatus] = useState(false);
  const [msgToggle, setMsgToggle] = useState(false);
  const [loader, setLoader] = useState(false);

  const toggleMessagesDDL = () => {
    setMsgToggle(!msgToggle);
  };

  const readAllMessages = () => {
    let getStorageData = localStorage.getItem('fwuPersistDb');
    let getAllData = JSON.parse(getStorageData);
    let data = getAllData.data;
    if (data) {
      let messagesList = [];
      for (let info of data.messages.value) {
        messagesList.push({
          messageId: info.messageId,
          contractId: info.contractId,
        });
      }
      // let msgs = data.messages.value.every(msg => msg.read);
      // setMessagesStatus(msgs);
      setLoader(true);
      // Getting values/objects from LocalStorage
      let authToken = getAllData.authToken;
      let messageListDTO = { messages: messagesList, hasMore: true };
      let userId = data.user.value._userId || data.user.value.userId;
      // API Call for read all messages
      endpoints
        .readAllMessages(userId, JSON.stringify(messageListDTO), authToken)
        .catch(() => {
          setLoader(false);
          setMsgToggle(false);
        })
        .then(() => {
          invalidateData('messages');
          invalidateData('unreadMessageCount');
          getData('unreadMessageCount');
          getData('messages');
          setLoader(false);
          setMsgToggle(false);
        });
    }
  };

  const menuOptions = [
    {
      optionTitle: <T id="form.button.read.all" />,
      optionClick: readAllMessages,
      iconType: 'readAll',
      iconColor: 'white',
    },
  ];

  return (
    <NarrowColumn>
      <Data
        keys={['messages']}
        fallback={
          <>
            <Header left="menu" />
            <Placeholder color="white" />
          </>
        }
        renderErrors={errors => (
          <>
            <Header left="menu" />
            <Paragraph color="white" size="small">
              {errors[0]}
            </Paragraph>
          </>
        )}
      >
        {([messages]) => {
          let previousMessageYear = null;

          return (
            <>
              <Header left="menu" />
              <div className={style.messageHeader}>
                <Heading size="h3" color="white">
                  <T id="messages" />
                </Heading>
                <MenuDropDown
                  options={menuOptions}
                  toggleMenu={toggleMessagesDDL}
                  menuHide={msgToggle}
                />
              </div>
              {loader && (
                <Placeholder
                  color="white"
                  style={{
                    marginTop: '20px',
                    textAlign: 'center',
                  }}
                />
              )}
              <CardNav>
                {messages.length ? (
                  messages.map(message => {
                    return (
                      <React.Fragment key={message.messageId}>
                        {previousMessageYear !== message.year ? (
                          <Paragraph color="white" size="normal">
                            {(() => {
                              previousMessageYear = message.year;
                              return message.year;
                            })()}
                          </Paragraph>
                        ) : null}
                        <Message {...message} />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <Paragraph color="white">
                    <T id="messages.noneFound" />
                  </Paragraph>
                )}
              </CardNav>
            </>
          );
        }}
      </Data>
    </NarrowColumn>
  );
};

export default Messages;
