import React from 'react';
import style from './style.module.scss';
import Paragraph from '../../paragraph';

function InputGroup({ heading, children, marginTop, ...rest }) {
  const fieldsetStyle = {};

  if (marginTop !== undefined) {
    fieldsetStyle.marginTop = `${marginTop}px`;
  }

  return (
    <fieldset className={style.fieldset} style={fieldsetStyle}>
      {heading && (
        <Paragraph component="legend" style={{ margin: 0 }} {...rest}>
          {heading}
        </Paragraph>
      )}
      {children}
    </fieldset>
  );
}

export default InputGroup;
