import React, { Component } from 'react';
import PasswordChangeForm from '../components/form/forms/passwordChange';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Heading from '../components/heading';
import CloseButton from '../components/button/close';
import Hybrid from '../components/image/hybrid';
import Header from '../components/header';
import { translate } from '../containers/translationContext';
import { connect } from '../containers/storeContext';
import { actions } from '../store';
import ReplaceTransition from '../components/transition/replace';
import InPageTransition from '../components/transition/inPage';
import Data from '../containers/data';

class ChangePassword extends Component {
  state = { completed: false };
  mounted = true;

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSuccess = () => {
    this.setState({ completed: true });
    setTimeout(() => {
      if (this.mounted) {
        this.props.clearUser();
      }
    }, 2000);
  };

  render() {
    const { t } = this.props;
    return (
      <NarrowColumn>
        <Header right={<CloseButton href="/account" color="darkPetrol" />} />
        <ReplaceTransition in={!this.state.completed}>
          <InPageTransition leave="left">
            <Heading>{t('settings.password')}</Heading>
            <Heading size="h6" component="h3" variant="light" color="black">
              {t('settings.password.subheading')}
            </Heading>
            <Data keys={['user']}>
              {([user]) => (
                <PasswordChangeForm
                  onSuccess={this.handleSuccess}
                  buttonRef={c => (this.button = c)}
                  user={user}
                />
              )}
            </Data>
          </InPageTransition>
          <InPageTransition enter="right">
            <Hybrid />
            <Heading>{t('settings.password.successHeading')}</Heading>
            <Heading size="h6" component="h3" variant="light" color="black">
              {t('settings.password.successBody')}
            </Heading>
          </InPageTransition>
        </ReplaceTransition>
      </NarrowColumn>
    );
  }
}

export default connect(null, actions)(translate(ChangePassword));
