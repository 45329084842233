import React, { Component } from 'react';
import { connect } from '../../../../containers/storeContext';
import { endpoints } from '../../../../config/data';
import { actions } from '../../../../store';
import ProgressButtons from '../../../progressButtons';
import { translate } from '../../../../containers/translationContext';
import BackButton from '../../../../components/button/back';
import Form from '../../index';
import Success from './success';
import Error from './error';
import ErrorIncompleteData from './errorIncompleteData';
import ErrorCombinePolicies from './errorCombinePolicies';
import Step1 from './step1';
import Step3 from './step3';
import DigitalConsent from './digitalConsent';
import SubmitButton from '../../../../components/button/submit';
import Header from '../../../header';
import { navigate } from '@reach/router';
import Paragraph from '../../../../components/paragraph';
import Placeholder from '../../../placeholder';
import TextLink from '../../../../components/textLink';

const steps = {
  1: Step1,
  2: Step3,
  3: DigitalConsent,
};

const stepEndpoints = {
  1: endpoints.unregisteredUserData,
  3: endpoints.confirmUser,
};

class RegisterForm extends Component {
  state = {
    step: 1,
    showForm: true,
    showError: false,
    currentStepIsValid: true,
    Statelanguage: '',
    StatemarketId: '',
    StatepolicyId: '',
    StatetaxId: '',
    isPrefil: false,
  };

  componentDidMount() {
    let isArgsExists = document.location.search.includes('args');

    if (isArgsExists) {
      let argsURL = document.location.search.split('=')[1];
      try {
        let urlData = atob(argsURL);

        if (urlData) {
          let splitURLData = urlData.split('~');
          if (splitURLData.length === 4) {
            this.setState(
              {
                Statelanguage: splitURLData[3],
                StatemarketId: splitURLData[2],
                StatepolicyId: splitURLData[0],
                StatetaxId: splitURLData[1],
                isPrefil: true,
                currentStepIsValid: true,
              },
              () => {
                setTimeout(() => {
                  this.handleChange();
                }, 100);
              }
            );
          }
        }
      } catch (err) {}
    } else {
      this.handleChange();
    }
  }

  handleChange = () => {
    if (!this.currentStep) return;
    this.setState({
      currentStepIsValid: this.currentStep.isValid(),
    });
  };

  nextStep = () => {
    this.setState(
      ({ step }) => ({
        step: step + 1,
        showForm: true,
        showError: false,
      }),
      this.handleChange
    );
  };

  previousStep = e => {
    this.setState(({ step }) => ({ step: step - 1 }), this.handleChange);
  };

  backToLogin = e => {
    navigate('/');
  };

  gotoStep = step => {
    if ((step > this.state.step && !this.currentStep.isValid()) || step - 1 > this.state.step)
      return;

    this.setState({ step }, this.handleChange);
  };

  formSuccess = (res, data) => {
    if (res.success === true) {
      const newStateData = { ...res.data, ...data };

      if (this.state.step === 3) {
        Object.assign(newStateData, { showForm: false, showError: false });
        this.setState(newStateData);
        return;
      }

      this.setState(newStateData);
    }

    this.nextStep();
  };

  renderError = (res, data) => {
    if (res.reasonCode === '68') {
      // Invalid Activation Token
      this.setState(
        {
          step: 2,
          showForm: true,
          showError: false,
          errorMessage: res.message,
          errorCode: res.reasonCode,
        },
        this.handleChange
      );
      return;
    }

    this.setState({
      showForm: false,
      showError: true,
      errorMessage: res.message,
      errorCode: res.reasonCode,
    });
  };

  renderOtp() {
    const { t } = this.props;
    const { policyId, marketId, taxId } = this.state;

    return (
      <div style={{ marginTop: '-22px' }}>
        <Form
          api={stepEndpoints[1]}
          onSuccess={response => {
            const update = () => {
              this.setState({
                smsToken: response.data.smsToken,
              });
            };
            update();
          }}
          initialValues={{
            policyId,
            marketId,
            taxId,
          }}
        >
          {({ submit, isSending, sendError }) => {
            return isSending ? (
              <Placeholder />
            ) : (
              <>
                <Paragraph>
                  {t('register.sms.resendVerificationCode')}{' '}
                  <TextLink onClick={submit}>
                    {t('register.button.resendVerificationCode.first')}
                  </TextLink>{' '}
                  {t('register.button.resendVerificationCode.second')}
                </Paragraph>
                {['localhost', 'test-cp.forwardyou.com', 'feature-cp.forwardyou.com'].includes(
                  window.location.hostname
                ) ? (
                  <Paragraph>
                    <span style={{ color: '#00879b' }}>
                      <span>
                        Debugging on FWU Test Systems. SMS Code: <b>{this.state.smsToken}</b>
                      </span>
                      <br />
                      <span style={{ fontSize: '0.75em' }}>(localhost, test-cp, feature-cp)</span>
                    </span>
                  </Paragraph>
                ) : (
                  ''
                )}
              </>
            );
          }}
        </Form>
      </div>
    );
  }

  render() {
    const {
      showForm,
      showError,
      step,
      currentStepIsValid,
      email,
      Statelanguage,
      StatemarketId,
      StatepolicyId,
      StatetaxId,
      isPrefil,
    } = this.state;
    const { changeLanguage, changeMarket, invalidateAllData, language, marketId, t } = this.props;

    let additionalProps = {};

    if (step === 2) {
      additionalProps = {
        mobileNo: this.state.mobileNo,
        email: this.state.email,
        errorMessage: this.state.errorMessage,
        errorCode: this.state.errorCode,
      };
    }

    return showForm ? (
      <>
        <Header
          left={
            step > 1 ? (
              <BackButton onClick={this.previousStep} color="darkPetrol" />
            ) : (
              <BackButton onClick={this.backToLogin} color="darkPetrol" />
            )
          }
        />

        <ProgressButtons steps={3} step={step} onChange={this.gotoStep} />

        <Form
          api={stepEndpoints[step]}
          onValueChange={this.handleChange}
          onSuccess={this.formSuccess}
          onError={this.renderError}
          initialValues={{
            language: Statelanguage.length === 0 ? language : Statelanguage,
            marketId: StatemarketId.length === 0 ? marketId : StatemarketId,
            policyId: StatepolicyId,
            taxId: StatetaxId,
            isPrefil: isPrefil,
          }}
        >
          {({ submit, isSending, sendError, ...inputProps }) => {
            const Step = steps[step];

            return (
              <>
                <Step
                  changeHandler={{
                    onChangeLanguage: changeLanguage,
                    onChangeMarket: changeMarket,
                  }}
                  invalidateAllData={invalidateAllData}
                  {...inputProps}
                  onInputsChange={this.handleChange}
                  ref={c => c && (this.currentStep = c)}
                  {...additionalProps}
                />
                {step === 2 ? (
                  <SubmitButton
                    onClick={this.nextStep}
                    disabled={!currentStepIsValid || isSending}
                    noMenu
                  >
                    {t('form.button.next')}
                  </SubmitButton>
                ) : (
                  <SubmitButton onClick={submit} disabled={!currentStepIsValid || isSending} noMenu>
                    {step === 1 ? t('form.button.next') : t('form.button.agree')}
                  </SubmitButton>
                )}
              </>
            );
          }}
        </Form>

        {step === 2 ? this.renderOtp() : null}
      </>
    ) : showError ? (
      switchErrors(this.state.errorCode, this.state.errorMessage, this.nextStep)
    ) : (
      <Success email={email} />
    );
  }
}

function switchErrors(errorCode, errorMessage, nextStep) {
  switch (errorCode) {
    case '10':
      return <ErrorIncompleteData message={errorMessage} />;
    case 'XX':
      return <ErrorCombinePolicies message={errorMessage} nextStep={nextStep} />;
    default:
      return <Error message={errorMessage} />;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    language: state.language,
    marketId: state.marketId,
  };
}

export default connect(mapStateToProps, actions)(translate(RegisterForm));
