import React from 'react';
import { bool, func, oneOf, oneOfType, string, node } from 'prop-types';
import style from './style.module.scss';
import InputBox from '../../inputBox';
import InputIcon from '../../inputIcon';
import Tooltip from '../../tooltip';
import cc from 'classcat';

TextInput.propTypes = {
  type: oneOf(['text', 'code', 'email', 'multiline', 'tel', 'search', 'password']),
  name: string.isRequired,
  error: oneOfType([string, node]),
  onChange: func,
  onBlur: func,
  value: string,
  hasInput: bool,
  innerRef: func,
  tooltip: string,
};

function TextInput({
  name,
  label,
  readonly,
  error,
  type = 'text',
  onChange,
  value = '',
  onBlur,
  hasInput,
  innerRef,
  disabled,
  noIcon,
  icon,
  optional,
  tooltip,
  extraChild,
  className,
  ...rest
}) {
  return type === 'multiline' ? (
    <InputBox
      name={name}
      error={error}
      label={label}
      hasInput={hasInput}
      inputMoves
      hasIcon={!noIcon}
      readOnly={readonly}
    >
      <textarea
        id={name}
        name={name}
        onInput={onChange}
        value={value}
        onBlur={onBlur}
        ref={innerRef}
        {...rest}
      />
    </InputBox>
  ) : (
    <InputBox
      name={name}
      error={error}
      label={label}
      hasInput={hasInput}
      labelMoves
      hasIcon={!noIcon}
      optional={optional}
      extraChild={extraChild}
      readOnly={readonly}
    >
      {!noIcon && <InputIcon type={icon || name} hasInput={hasInput} hasError={!!error} />}
      <input
        className={cc([className, style.form])}
        type={type}
        // id={name}
        name={name}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        ref={innerRef}
        readOnly={readonly}
        tabIndex={readonly ? -1 : 0}
      />
      {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </InputBox>
  );
}

export default TextInput;
