import React from 'react';
import { translate } from '../containers/translationContext';
import Header from '../components/header';
import Heading from '../components/heading';
import CardNav from '../components/cardNav';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import style from './accountSettingsStyle.module.scss';
import store from '../store';

function AccountSettings({ t }) {
  const user = store.getState().data.user.value;

  function renderTaxIdUpdate(user) {
    if (user.isTaxIdVerified === true) {
      return '';
    }

    if (user.alterationEnabled === false) {
      return '';
    }

    // to cater the logic that dont show taxID if user dont have any contract map
    if (user.isTaxIdVerified === false && user.listOfContracts.length <= 0) {
      return '';
    }

    return (
      <CardNav.Card href="/account/taxId" arrow>
        <Heading size="h6" variant="light" ieFlexGrow>
          {t('settings.taxId')}
          <span className={style.messagesIndicator}>!</span>
        </Heading>
      </CardNav.Card>
    );
  }

  function renderDigitalConsentOpt() {
    if (user.marketId === 'FLL-IT' || user.marketId === 'FLL-ES') {
      return (
        <CardNav.Card href="/account/consent" arrow>
          <Heading size="h6" variant="light" ieFlexGrow>
            {t('settings.consent')}
          </Heading>
        </CardNav.Card>
      );
    } else {
      return false;
    }
  }

  return (
    <NarrowColumn>
      <Header left="menu" />
      <Heading color="white" size="h4">
        {t('settings')}
      </Heading>
      <CardNav>
        <CardNav.Card href="/account/update" arrow>
          <Heading size="h6" variant="light" ieFlexGrow>
            {t('settings.profile')}
          </Heading>
        </CardNav.Card>

        {// Hide change email: CP-914
        false ? (
          <CardNav.Card href="/account/email" arrow>
            <Heading size="h6" variant="light" ieFlexGrow>
              {t('settings.email')}
            </Heading>
          </CardNav.Card>
        ) : (
          ''
        )}

        {renderTaxIdUpdate(user)}

        <CardNav.Card href="/account/password/change" arrow>
          <Heading size="h6" variant="light" ieFlexGrow>
            {t('settings.password')}
          </Heading>
        </CardNav.Card>
        <CardNav.Card href="/account/privacy" arrow>
          <Heading size="h6" variant="light" ieFlexGrow>
            {t('settings.privacy')}
          </Heading>
        </CardNav.Card>
        {renderDigitalConsentOpt()}
        <CardNav.Card href="/account/delete" arrow>
          <Heading size="h6" variant="light" ieFlexGrow>
            {t('settings.delete')}
          </Heading>
        </CardNav.Card>
      </CardNav>
    </NarrowColumn>
  );
}

export default translate(AccountSettings);
