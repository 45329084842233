import React, { Component } from 'react';
import { func, number } from 'prop-types';
import cc from 'classcat';
import style from './style.module.scss';

class ProgressButtons extends Component {
  static propTypes = {
    steps: number,
    step: number,
    onChange: func,
  };

  renderButton = (_, i) => {
    const step = i + 1;
    return (
      <button
        key={step}
        type="button"
        className={cc([
          style.button,
          this.props.step >= step && style.active,
          step < this.props.step && style.previous,
        ])}
        onClick={() => this.props.onChange(step)}
      >
        {step}
      </button>
    );
  };

  render() {
    const { step } = this.props;

    const classes = [style.container];

    if (step > 1) {
      classes.push(style[`step${step}`]);
    }

    return (
      <div className={cc(classes)}>
        {Array.from(new Array(this.props.steps)).map(this.renderButton)}
      </div>
    );
  }
}

export default ProgressButtons;
