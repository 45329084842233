import React from 'react';

function Facebook(props) {
  return (
    <svg viewBox="0 0 350 350" {...props}>
      <path d="M182.71,306.44v-101.55h33.86l6.45-42.01h-40.3v-27.28c0-11.49,5.63-22.69,23.68-22.69h18.33v-35.74s-16.63-2.84-32.53-2.84c-33.21,0-54.88,20.12-54.88,56.53v32.02h-36.91v42.01h36.91v101.55C67.83,295.53,14.69,235.41,14.69,162.88,14.69,82.61,79.73,17.57,160,17.57s145.31,65.04,145.31,145.31c0,72.53-53.14,132.64-122.61,143.55Z" />
    </svg>
  );
}

export default Facebook;
