import React, { Component } from 'react';
import { translate } from '../../../containers/translationContext';
import Form from '../index';
import SubmitButton from '../../button/submit';
import { endpoints } from '../../../config/data';
import { connect } from '../../../containers/storeContext';
import { actions } from '../../../store';
import { navigate } from '@reach/router';
import Icon from '../../image/icon';
import TextUnit from '../inputUnits/text';
import PhoneUnit from '../inputUnits/phone';
import Paragraph from '../../paragraph';
import Heading from '../../heading';
import Thumb from '../../image/thumb';
import TextLink from '../../textLink';
import SendError from '../sendError';
import Data from '../../../containers/data';
import formatTime from '../../../utils/formatTime';
import Placeholder from '../../placeholder';
import Header from '../../../components/header';
import BackButton from '../../../components/button/back';
import CloseButton from '../../../components/button/close';

class UpdatePhoneNumberForm extends Component {
  eventName = 'UPDATE_MOBILE_NO';

  step = {
    INPUT: 'Input',
    VERIFY: 'Verify',
    SUCCESS: 'Success',
  };
  inputRefs = [];
  state = {
    isValid: !this.props.user.isPhoneVerified && this.props.user.phoneNo.length > 0,
    touched: !this.props.user.isPhoneVerified && this.props.user.phoneNo.length > 0,
    success: false,
    step: 'Input',
    phoneNo: this.props.user.phoneNo,
    phoneCountryCode: this.props.user.phoneCountryCode,
    lastCodeSent: null,
    debugToken: '',
  };

  handleChangePhoneNumber = () => {
    const { user } = this.props;
    const isValid =
      (user.phoneCountryCode !== this.inputRefs[0].props.values.phoneCountryCode ||
        user.phoneNo !== this.inputRefs[0].props.values.phoneNo ||
        !user.isPhoneVerified) &&
      this.inputRefs.every(input => input.isValid());

    this.setState({
      isValid: isValid,
      success: false,
      touched: true,
    });
  };

  handleChangeVerify = () => {
    this.setState({
      isValid: this.inputRefs.every(input => input.isValid()),
      success: false,
      touched: true,
    });
  };

  renderInput = () => {
    const { t, user } = this.props;
    const { touched, isValid } = this.state;

    return (
      <Data keys={['phoneCountryCodes']}>
        {([phoneCountryCodes]) => {
          let phoneCountryCodeValue = '';
          if (user.phoneCountryCode) {
            phoneCountryCodeValue = user.phoneCountryCode;
          } else if (user.languageId) {
            // If there's a language, we should select the country code for that language by default
            const phoneCountryCode = phoneCountryCodes.filter(countryCode => {
              return countryCode.code === user.languageId.toLowerCase();
            });

            // There is, select this country code by default
            if (phoneCountryCode.length) {
              phoneCountryCodeValue = phoneCountryCode[0].key;
            }
          }

          // There isn't, just grab the first one
          if (!phoneCountryCodeValue.length) {
            phoneCountryCodeValue = phoneCountryCodes[0].key;
          }

          return (
            <>
              <Header right={<CloseButton href="/account" color="darkPetrol" />} />
              <Heading size="h4">{t('settings.phone')}</Heading>
              <Paragraph>{t('phone.body')}</Paragraph>
              <Form
                api={endpoints.updatePhoneNumberRequest}
                onValueChange={() => this.handleChangePhoneNumber()}
                onSuccess={response => {
                  const update = () => {
                    this.setState({
                      step: this.step.VERIFY,
                      isValid: false,
                      touched: false,
                      lastCodeSent: new Date(),
                      phoneNo: this.inputRefs[0].props.values.phoneNo,
                      phoneCountryCode: this.inputRefs[0].props.values.phoneCountryCode,
                      debugToken: response.data.smsText || '',
                    });

                    this.inputRefs.length = 0;
                  };

                  update();
                }}
                initialValues={{
                  userId: user.userId,
                  eventName: this.eventName,
                  phoneNo: this.state.phoneNo,
                  phoneCountryCode: this.state.phoneCountryCode || phoneCountryCodeValue,
                }}
              >
                {({ submit, isSending, sendError, ...inputProps }) => {
                  return (
                    <>
                      <PhoneUnit
                        name="phoneNo"
                        label={t('form.label.phoneNumber')}
                        countryCodeLabel={t('form.label.countryCode')}
                        ref={c => (this.inputRefs[0] = c)}
                        phoneCountryCodes={phoneCountryCodes}
                        {...inputProps}
                      />

                      <SendError message={sendError} />

                      <Paragraph style={{ margin: '40px' }}>
                        {isSending ? <Placeholder /> : ''}
                      </Paragraph>

                      <SubmitButton
                        onClick={() => {
                          this.setState({
                            isValid: false,
                          });
                          submit();
                        }}
                        disabled={isSending || !isValid}
                        hidden={!touched}
                      >
                        {t('form.button.save')}
                      </SubmitButton>
                    </>
                  );
                }}
              </Form>
            </>
          );
        }}
      </Data>
    );
  };

  renderVerify = () => {
    const { t, user } = this.props;
    const { touched, success, isValid } = this.state;

    return (
      <>
        <Header
          left={
            <BackButton
              color="darkPetrol"
              onClick={() => {
                this.setState({
                  step: this.step.INPUT,
                  isValid: true,
                  touched: true,
                });
              }}
            />
          }
          right={<CloseButton href="/account" color="darkPetrol" />}
        />
        <Form
          autoComplete="off"
          api={endpoints.updatePhoneNumber}
          onValueChange={() => this.handleChangeVerify()}
          onSuccess={response => {
            const { getData, invalidateData } = this.props;

            const update = () => {
              this.inputRefs.length = 0;
              this.setState({
                step: this.step.SUCCESS,
                isValid: false,
                success: true,
              });

              setTimeout(() => {
                navigate('/account');
              }, 3000);
            };

            Promise.all([invalidateData('user'), getData('user')])
              .then(update)
              .catch(update);
          }}
          initialValues={{
            userId: user.userId,
            phoneNo: this.state.phoneNo,
            phoneCountryCode: this.state.phoneCountryCode,
          }}
        >
          {({ submit, isSending, sendError, ...inputProps }) => {
            return (
              <>
                <Heading size="h4">{t('phone.verifyHeader')}</Heading>
                <Paragraph>{t('phone.verifyBody')}</Paragraph>

                <br />

                <Paragraph>
                  {t('general.sms.requestTime')} {formatTime('en', this.state.lastCodeSent / 1000)}
                </Paragraph>

                <br />
                <TextUnit
                  name="smsToken"
                  label={t('general.sms.verifyCode')}
                  pattern={'^[0-9]{6}$'}
                  ref={c => (this.inputRefs[0] = c)}
                  {...inputProps}
                />

                {isSending ? <Placeholder /> : <SendError message={sendError} />}

                <br />
                <br />

                <SubmitButton
                  onClick={() => {
                    this.setState({
                      isValid: false,
                    });
                    submit();
                  }}
                  disabled={isSending || !isValid}
                  hidden={!touched}
                >
                  {success ? (
                    <>
                      {t('form.button.saved')}
                      <Icon
                        type="check"
                        color="white"
                        style={{ marginTop: '-5px', marginLeft: '5px' }}
                      />
                    </>
                  ) : (
                    t('phone.updateButtonText')
                  )}
                </SubmitButton>
              </>
            );
          }}
        </Form>

        <Form
          api={endpoints.generateSmsToken}
          onSuccess={response => {
            const update = () => {
              this.setState({
                step: this.step.VERIFY,
                isValid: false,
                lastCodeSent: new Date(),
                debugToken: response.data.smsText || '',
              });
            };
            update();
          }}
          initialValues={{
            userId: user.userId,
            eventName: this.eventName,
          }}
        >
          {({ submit, isSending, sendError }) => {
            return isSending ? (
              <Placeholder />
            ) : (
              <>
                <Paragraph>
                  {t('general.sms.resendToken')}{' '}
                  <TextLink onClick={submit}>{t('form.button.resend')}</TextLink>
                </Paragraph>
                {['localhost', 'test-cp.forwardyou.com', 'feature-cp.forwardyou.com'].includes(
                  window.location.hostname
                ) ? (
                  <Paragraph>
                    <span style={{ color: '#00879b' }}>
                      <span>
                        Debugging on FWU Test Systems. SMS Code: <b>{this.state.debugToken}</b>
                      </span>
                      <br />
                      <span style={{ fontSize: '0.75em' }}>(localhost, test-cp, feature-cp)</span>
                    </span>
                  </Paragraph>
                ) : (
                  ''
                )}
              </>
            );
          }}
        </Form>
      </>
    );
  };

  renderSuccess = () => {
    const { t } = this.props;

    return (
      <>
        <Heading size="h2" align="center">
          {t('phone.successHeading')}
        </Heading>
        <Thumb />
        <Paragraph style={{ marginBottom: '40px', textAlign: 'center' }}>
          {t('general.thankYou')}
        </Paragraph>
      </>
    );
  };

  render() {
    switch (this.state.step) {
      case this.step.INPUT:
        return this.renderInput();
      case this.step.VERIFY:
        return this.renderVerify();
      case this.step.SUCCESS:
        return this.renderSuccess();
      default:
        return 'ERROR';
    }
  }
}

export default connect(null, actions)(translate(UpdatePhoneNumberForm));
