import cc from 'classcat';
import Link from '../link';
import Portal from '../portal';
import Data from '../../containers/data';
import { connect } from '../../containers/storeContext';
import React, { Component } from 'react';
import ConnectedTransition from 'react-connected-transition';
import transitionContext from '../../containers/transitionContext';
import { actions } from '../../store';
import { isTargetWithin } from '../../utils/dom';
import Swipable from '../../utils/swipable';
import BackButton from '../button/back';
import ConnectedCardTransition from '../connectedCardTransition';
import style from './style.module.scss';

let wasOpen = false;

class YearSelector extends Component {
  static contextType = transitionContext;
  state = {
    open: wasOpen,
  };

  componentDidMount() {
    this.swipe = new Swipable([this.menu, this.swipeBar]);
    this.swipe.on('right', this.close);
    document.addEventListener('click', this.handleClick);
    document.addEventListener('touchstart', this.handleTouch, { passive: true });
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    this.swipe.off('right', this.close);
    document.removeEventListener('click', this.handleClick);
    document.removeEventListener('touchstart', this.handleTouch);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    if (e.key === 'Escape') {
      this.toggle(false);
    }
  };

  handleClick = e => {
    if (
      this.state.open &&
      !isTargetWithin(e.target, this.yearsNode, true) &&
      !isTargetWithin(e.target, this.button, true)
    ) {
      this.toggle(false);
    }
  };

  handleTouch = e => {
    if (
      this.state.open &&
      !isTargetWithin(e.target, this.yearsNode, true, false) &&
      !isTargetWithin(e.target, this.button, true, false)
    ) {
      this.toggle(false);
    }
  };

  close = () => {
    this.toggle(false);
  };

  toggle = force => {
    this.setState(
      ({ open }) => ({
        open: typeof force === 'boolean' ? force : !open,
      }),
      () => (wasOpen = this.state.open)
    );
  };

  setYearsRef = c => {
    this.yearsNode = c;
    const node = this.currentYearNode;
    if (node && !this.scrolledIntoView) {
      this.scrolledIntoView = true;
      Promise.resolve().then(() => node.scrollIntoView({ block: 'center' }));
    }
  };

  setCurrentYearRef = c => {
    this.currentYearNode = c;
  };

  render() {
    const { activePolicy, dashboardPage } = this.props;
    const { open } = this.state;
    const transitionState = this.context;
    const currentYear = new Date().getFullYear();

    return (
      <>
        <button
          onClick={this.toggle}
          tabIndex={1}
          className={style.toggle}
          ref={c => (this.button = c)}
        >
          {currentYear}
        </button>
        <Portal>
          <div>
            <div
              className={cc([style.swipeBar, open && style.open, style.mobileOnly])}
              onClick={this.toggle}
              ref={c => (this.swipeBar = c)}
            />
            <div className={cc([style.menu, open && style.open])} ref={c => (this.menu = c)}>
              <div className={style.content}>
                <BackButton color="darkPetrol" className={cc([style.close, style.mobileOnly])} />
                <div className={style.years} ref={this.setYearsRef}>
                  <Data keys={[`projectedValues:${activePolicy.contractId}`]}>
                    {([projectedValues]) => {
                      if (projectedValues.years == null) return null;

                      return Object.entries(projectedValues.years)
                        .reverse()
                        .map(([y, data], i) => {
                          const year = Number(y);
                          const Comp = year >= currentYear ? 'div' : Link;
                          const props = {};

                          let hash = false;

                          if (year === currentYear) {
                            props.ref = this.setCurrentYearRef;
                            props.onClick = this.toggle;
                          }
                          if (year <= currentYear) {
                            props.href = `/dashboard/projected/${y}`;
                            hash = `#${dashboardPage}`;
                          }
                          let bg = <div className={style.yearBackground} />;

                          if (year <= currentYear) {
                            bg = (
                              <ConnectedTransition
                                name={props.href}
                                exit={transitionState === 'exiting' || transitionState === 'exited'}
                              >
                                <ConnectedCardTransition animates>{bg}</ConnectedCardTransition>
                              </ConnectedTransition>
                            );
                          }

                          if (hash) props.href += hash;

                          return (
                            <Comp
                              className={cc([
                                style.year,
                                year > currentYear && style.futureYear,
                                year < currentYear && style.pastYear,
                                year === currentYear && style.currentYear,
                              ])}
                              style={{ zIndex: i }}
                              key={y}
                              tabIndex={open && year <= currentYear ? 2 : -1} // To follow the header
                              {...props}
                            >
                              {bg}
                              <span>{year}</span>
                            </Comp>
                          );
                        });
                    }}
                  </Data>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      </>
    );
  }
}

export default connect('activePolicy', actions)(YearSelector);
