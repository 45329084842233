import React, { Component } from 'react';
import anime from 'animejs';
import { translate } from '../containers/translationContext';
import Data from '../containers/data';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import Policies from '../components/policies';
import Header from '../components/header';
import Heading from '../components/heading';
import AnimationContainer from '../components/animation/container';
import AnimationItem from '../components/animation/item';

// We're animating only once per page load and only when the user doesn't have any policies
let animated = false;

class PolicyOverview extends Component {
  state = { animated };

  handleHeadingRef(c, policies) {
    this.heading = c || this.heading;
    const { height, top } = this.heading.getBoundingClientRect();
    const wh = window.innerHeight;
    if (animated || policies.length > 0) return;
    this.heading.style.transform = `translateY(${wh / 2 - top - height / 2}px)`;
    anime({
      targets: this.heading,
      translateY: 0,
      easing: 'easeInOutQuart',
      duration: 800,
      delay: 1000,
      complete: () => {
        this.heading.style = '';
      },
    });
    animated = true;
  }

  render() {
    const { t } = this.props;
    return (
      <NarrowColumn>
        <Data keys={['user', 'policies']}>
          {([user, policies]) => (
            <>
              <AnimationContainer
                disabled={!!policies.length > 0 || this.state.animated}
                delay={1200}
              >
                <Header left="menu" />

                <Heading
                  color="white"
                  component="h1"
                  size="h3"
                  innerRef={c => this.handleHeadingRef(c, policies)}
                >
                  {user.salCode === '003'
                    ? t('policy.overview.headingFemale', user.firstName)
                    : t('policy.overview.heading', user.firstName)}
                </Heading>

                <AnimationItem type="slide">
                  <Heading color="white" variant="sans" size="h6">
                    {t('policy.overview.subheading')}
                  </Heading>
                </AnimationItem>

                <AnimationItem type="slide">
                  <Policies />
                </AnimationItem>
              </AnimationContainer>
            </>
          )}
        </Data>
      </NarrowColumn>
    );
  }
}

export default translate(PolicyOverview);
