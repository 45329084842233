import React from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import UpdateTaxIdForm from '../components/form/forms/updateTaxId';
import Data from '../containers/data';
import Header from '../components/header';

function AccountTaxId() {
  return (
    <NarrowColumn>
      <Header />
      <Data keys={['user']}>{([user]) => <UpdateTaxIdForm user={user} />}</Data>
    </NarrowColumn>
  );
}

export default AccountTaxId;
