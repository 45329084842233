import React, { Component } from 'react';
import routeContext from '../containers/routeContext';
import ActivateForm from '../components/form/forms/activate';
import NarrowColumn from '../components/contentGrid/narrowColumn';

class Activate extends Component {
  static contextType = routeContext;
  render() {
    const { code } = this.context;
    return (
      <NarrowColumn>
        <ActivateForm code={code} />
      </NarrowColumn>
    );
  }
}

export default Activate;
