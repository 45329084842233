import React, { useState, useEffect } from 'react';
import NarrowColumn from '../components/contentGrid/narrowColumn';
import CloseButton from '../components/button/close';
import Header from '../components/header';
import { translate } from '../containers/translationContext';
import Hybrid from '../components/image/hybrid';
import Paragraph from '../components/paragraph';
import DigitalConsentForm from '../components/form/forms/digitalConsent';
import { request } from '../utils/request';
import store from '../store';
import { endpoints } from '../config/data';
import { navigate } from '@reach/router';

const DigitalConsent = () => {
  let _store = store.getState();

  const [consentError, setConsentError] = useState();

  let initialValues = {
    digitalConsent: true,
    marketingConsent: false,
    accept: false,
  };
  const [formData, setFormData] = useState(initialValues);

  const handleOnChange = e => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const renderError = message => (
    <>
      <Hybrid />
      <Paragraph align="center">{message}</Paragraph>
    </>
  );

  const handleOnSubmit = async () => {
    await request('/api/user/save-user-consent', formData, 'POST', _store.authToken)
      .then(res => {
        if (res.success) {
          navigate('/account');
        }
      })
      .catch(e => {
        return renderError(e);
      });
  };

  useEffect(() => {
    const getUserConsent = async () => {
      await endpoints
        .userGetDigitalConsent(_store.authToken, _store.data.user.value.userId)
        .then(res => {
          if (res.reasonCode === '00' && res.success) {
            formData.digitalConsent = res.data.digitalConsent;
            formData.marketingConsent = res.data.marketingConsent;
          }
        })
        .catch(e => {
          setConsentError(e);
        });
    };

    getUserConsent();
  }, []);

  return (
    <NarrowColumn>
      <Header right={<CloseButton href="/account" color="darkPetrol" />} />
      <DigitalConsentForm
        digitalConsent={formData.digitalConsent}
        marketingConsent={formData.marketingConsent}
        accept={formData.accept}
        onChange={handleOnChange}
        handleOnSubmit={handleOnSubmit}
      />
      {consentError && <p style={{ color: 'red' }}>{consentError}</p>}
    </NarrowColumn>
  );
};

export default translate(DigitalConsent);
