import React from 'react';

function Back({ ...props }) {
  return (
    <svg viewBox="0 0 80 55" {...props}>
      <g>
        <g>
          <line x1="5.6" y1="27.9" x2="75.5" y2="27.9" />
        </g>
        <g>
          <line x1="47.7" y1="4.9" x2="76.2" y2="27.9" />
          <line x1="76.2" y1="27.9" x2="47.7" y2="50.9" />
        </g>
      </g>
    </svg>
  );
}

export default Back;
