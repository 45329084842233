import React from 'react';
import style from './style.module.scss';

function Thumb() {
  return (
    <div className={style.wrapper}>
      <img className={style.image} src={require('../../../assets/thumb.png')} alt="" />
    </div>
  );
}

export default Thumb;
