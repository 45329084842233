import React from 'react';
import style from './style.module.scss';
import cc from 'classcat';

function Profile({ className, ...props }) {
  return (
    <svg viewBox="0 0 55 55" {...props} className={cc([className, style.stroke, style.small])}>
      <g>
        <g>
          <path d="M32.4,16.8c0,7.1-5.1,12.8-11.3,12.8S9.8,23.9,9.8,16.8S14.8,4,21.1,4S32.4,9.7,32.4,16.8z M1.4,53.1    c0,0-0.4-23.3,18.9-23.4 M21.1,29.6C40.4,29.7,40,53.1,40,53.1" />
        </g>
      </g>
    </svg>
  );
}

export default Profile;
