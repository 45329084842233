import React, { useState } from 'react';
import { T } from '../../containers/translationContext';
import Button from '../button/dialogButton';
import Style from './style.module.scss';
import CheckboxInput from '../form/inputs/checkbox';
import { request } from '../../utils/request';
import Hybrid from '../image/hybrid';
import Paragraph from '../paragraph';

const DigitalConsentDialog = props => {
  let initialValues = {
    digitalConsent: true,
    marketingConsent: false,
    accept: false,
  };
  const [formData, setFormData] = useState(initialValues);
  const [isShow, setIsShow] = useState(props.isShow);

  const handleOnChange = e => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const renderError = message => (
    <>
      <Hybrid />
      <Paragraph align="center">{message}</Paragraph>
    </>
  );

  const handleOnSubmit = async () => {
    await request('/api/user/save-user-consent', formData, 'POST', props.authToken)
      .then(res => {
        if (res.success) {
          setIsShow(false);
        }
      })
      .catch(e => {
        return renderError(e);
      });
  };

  return (
    <>
      {isShow && (
        <div className={Style.dialogContainer}>
          <div className={Style.dialogInnerContainer}>
            <div className={Style.dialogHeader}>
              <p className={Style.dialogHeadingtext}>
                <T id="dialog.heading.digitalConsent" />
              </p>
            </div>
            <div className={Style.dialogBody}>
              <p className={Style.dialogParagraphtext}>
                <T id="dialog.paragraph.one" />
              </p>
              <span className={Style.optionContainer}>
                <p className={Style.optionNo}>
                  <T id="dialog.option.no" />
                </p>
                <CheckboxInput
                  id="digitalConsent"
                  name="digitalConsent"
                  value={formData.digitalConsent}
                  onChange={handleOnChange}
                  noIcon={true}
                />
                <p className={Style.optionYes}>
                  <T id="dialog.option.yes" />
                </p>
              </span>
              <p className={Style.dialogParagraphtext}>
                <T id="dialog.paragraph.two" />
              </p>
              <ul className={Style.dialogPointContainer}>
                <li>
                  <T id="dialog.paragraph.two.one" />
                </li>
                <li>
                  <T id="dialog.paragraph.two.two" />
                </li>
                <li>
                  <T id="dialog.paragraph.two.three" />
                </li>
              </ul>
              <span className={Style.optionContainer}>
                <p className={Style.optionNo}>
                  <T id="dialog.option.no" />
                </p>
                <CheckboxInput
                  id="marketingConsent"
                  name="marketingConsent"
                  value={formData.marketingConsent}
                  onChange={handleOnChange}
                  noIcon={true}
                />
                <p className={Style.optionYes}>
                  <T id="dialog.option.yes" />
                </p>
              </span>
              <div className={Style.acceptance}>
                <input
                  className={Style.checkboxAcceptance}
                  type="checkbox"
                  name="accept"
                  value={formData.accept}
                  onChange={handleOnChange}
                />
                <span
                  className={Style.acceptanceText}
                  style={{ color: `${!formData.accept ? '#d63b1b' : '#3c3c3c'}` }}
                >
                  <T id="dialog.paragraph.three" />
                </span>
              </div>
            </div>
            <div className={Style.dialogFooter}>
              <Button
                title={<T id="dialog.button.submit" />}
                disabled={!formData.accept}
                onClick={handleOnSubmit}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DigitalConsentDialog;
