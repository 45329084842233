import React from 'react';
import style from './style.module.scss';

function InputCouple({ children }) {
  return <div className={style.couple}>{children}</div>;
}

function Input({ children, width }) {
  return (
    <div className={style.input} style={{ width: `calc(${width}% - 10px)` }}>
      {children}
    </div>
  );
}

InputCouple.Input = Input;

export default InputCouple;
