import React from 'react';
import { entropy } from '../../utils/passwordStrength';
import config from '../../config';
import style from './style.module.scss';
import Paragraph from '../paragraph';
import { translate } from '../../containers/translationContext';

function PasswordStrengthMeter({ value, t, passwordChange = false }) {
  const max = config.passwordMinEntropy * 1.8;
  const lineOffset = curve(config.passwordMinEntropy / max) * 100;
  const currentStrength = entropy(value);
  const highLevel = max * 0.8;

  let help = '';
  if (currentStrength < config.passwordMinEntropy) {
    help = 'passwordMeter.low';
  } else if (currentStrength < highLevel) {
    help = 'passwordMeter.medium';
  } else if (currentStrength >= highLevel && currentStrength < max) {
    help = 'passwordMeter.high';
  }

  return (
    <>
      <div className={style.background}>
        <div className={style.line} style={{ left: `${lineOffset}%` }} />
        <div className={style.bar} style={{ width: `${barWidth(value, max)}%` }} />
      </div>
      {help && (
        <>
          <Paragraph size="xSmall" className={style.help}>
            {t(help)}
          </Paragraph>
          <Paragraph size="xSmall" className={style.help} style={{ marginTop: 0 }}>
            {t('passwordMeter.help')}
          </Paragraph>
          {passwordChange && (
            <Paragraph size="xSmall" className={style.help} style={{ marginTop: 0 }}>
              {t('passwordMeter.history')}
            </Paragraph>
          )}
        </>
      )}
    </>
  );
}

function barWidth(value, max) {
  return Math.min(Math.max((1 - curve(entropy(value) / max)) * 100, 0), 100);
}

function curve(x) {
  return x;
}

export default translate(PasswordStrengthMeter);
