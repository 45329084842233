import React, { Component } from 'react';
import Page from '../../page';
import DashboardNavigation from '../../dashboard/navigation';
import DashboardPagination from '../../dashboard/pagination';
import PolicyValue from '../../dashboard/policyValue';
import ContentGrid from '../../contentGrid';
import Swipable from '../../../utils/swipable';

const UP = 1;
const DOWN = -1;
const SNAPHEIGHT = 0.495;
const OUTROTATION = 40;
const OUTSCALE = 0.5;

class Card extends Component {
  swipe = {};
  timer = null;
  timer2 = null;
  speed = 2;
  offsetY = 0;
  deltaY = 0;
  otherCardId = false;
  activeIndex = false;
  attached = false;
  ios = false;

  constructor(props) {
    super();
    this.index = props.index;
    this.activeIndex = props.activeIndex;
    this.ios = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }

  componentDidMount() {
    this.compH = this.page.clientHeight;

    this.swipe = new Swipable([this.main], {
      thresholdPixels: 0,
      index: this.index,
      emitter: this.props.totalCards > 1 ? this.props.emitter : null,
    });

    if (this.index !== this.activeIndex) {
      this.page.style.visibility = 'hidden';
    } else {
      this.page.style.visibility = 'visible';
      setTimeout(() => {
        this.attachListeners(this.index);
      }, 1000);
    }

    this.props.emitter.on('animateExitPositions', this.animateExitPositions);
    this.props.emitter.on('updatePositions', this.updatePositions);
    this.props.emitter.on('attachListeners', this.attachListeners);
    this.props.emitter.on('navigate', this.navigate);
    this.props.emitter.on('hide', this.hide);
  }

  componentWillUnmount() {
    this.props.emitter.off('animateExitPositions', this.animateExitPositions);
    this.props.emitter.off('updatePositions', this.updatePositions);
    this.props.emitter.off('attachListeners', this.attachListeners);
    this.props.emitter.off('navigate', this.navigate);
    this.props.emitter.off('hide', this.hide);
    this.swipe.off('start', this.dragStart);
    this.swipe.off('end', this.dragEnd);
    this.swipe.off('up', this.scroll);
    this.swipe.off('down', this.scroll);
  }

  attachListeners = index => {
    this.activeIndex = this.mod(index);
    if (this.index === this.mod(index)) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      this.swipe.on('start', this.dragStart);
    }
  };

  detachListeners = e => {
    this.attached = false;
    this.swipe.off('up', this.scroll);
    this.swipe.off('down', this.scroll);
    this.swipe.off('start', this.dragStart);
    this.swipe.off('end', this.dragEnd);
  };

  dragStart = e => {
    this.deltaY = 0;
    if (this.index === this.mod(e)) {
      this.swipe.on('up', this.scroll);
      this.swipe.on('down', this.scroll);
      this.swipe.on('end', this.dragEnd);
    }
  };

  dragEnd = () => {
    this.detachListeners();
    this.animateToPosition();
  };

  animateToPosition = () => {
    const newIndex = this.exitPosition();
    this.activeIndex = newIndex;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.emitter.emit('attachListeners', newIndex);
    }, 300);
  };

  scroll = e => {
    let offsetY = (e.y > 0 ? e.y * this.speed - this.compH : e.y * this.speed + this.compH) * 1.2;
    this.deltaY = e.y;
    this.otherCardId = this.deltaY > 0 ? this.index - 1 : this.index + 1;

    if (this.draggedBeyondLimits(e)) {
      // threshold
      this.dragEnd();
      // this.animateToPosition();
      // this.page.style.visibility = 'hidden';
    } else {
      // realtime animate
      this.props.emitter.emit('updatePositions', {
        data: [
          { id: this.index, y: e.y * this.speed },
          { id: this.otherCardId, y: offsetY },
        ],
      });
    }
  };

  // still able to doubleclick
  navigate = indexes => {
    const { oldIndex, newIndex, next } = indexes;
    this.activeIndex = newIndex;

    if (this.index === this.mod(oldIndex)) {
      this.animateExitPositions({
        data: [
          {
            id: this.index,
            y: next ? -window.innerHeight : window.innerHeight,
            hide: true,
            scale: 0.5,
            deg: next ? -20 : 20,
            speed: this.ios ? 300 : 1000,
          },
        ],
      });
    }

    if (this.index === this.mod(newIndex)) {
      const height = window.innerHeight;
      const posY = next ? height : -height;
      const deg = next ? 20 : -20;
      this.page.style.transition = '';
      this.page.style.transform = `translateY(${posY}px)  scale(0.5) rotate3D(0.4,1,0, ${deg}deg)`;
      this.page.style.visibility = 'hidden';

      this.attachListeners(this.index);

      setTimeout(() => {
        this.animateExitPositions({
          data: [{ id: this.index, y: 0, speed: this.ios ? 300 : 1000 }],
        });
      }, 20);
    }
  };

  hide = e => {
    if (this.index === this.mod(e.index)) this.page.style.visibility = 'hidden';
  };

  mod = n => {
    const { totalCards } = this.props;
    return ((n % totalCards) + totalCards) % totalCards;
  };

  draggedBeyondLimits = e => {
    let minSnap = this.compH * SNAPHEIGHT;
    if (e.y > minSnap) return UP;
    if (e.y < -minSnap) return DOWN;
    return false;
  };

  updatePositions = e => {
    const { data } = e;

    data.forEach(transform => {
      if (this.index === this.mod(transform.id)) {
        let scale = 1;
        let deg = 0;

        if (transform.id === this.activeIndex) {
          scale = 1 - Math.abs(this.deltaY) * 0.001;
          deg = this.deltaY * 0.1;
        } else {
          const rect = this.main.getBoundingClientRect();

          let yProgress = Math.abs(rect.y / window.innerHeight);
          scale = OUTSCALE + (0 - yProgress + 1) * OUTSCALE;
          deg = OUTROTATION - (0 - yProgress + 1) * OUTROTATION;
        }

        this.page.style.transition = ``;
        this.page.style.visibility = 'visible';
        this.page.style.transform = `translateY(${transform.y}px) scale(${scale})
        rotate3D(0.4,1,0, ${deg}deg)`;
      }
    });
  };

  exitPosition = () => {
    let changeActiveCard = false;
    if (this.deltaY > this.compH * 0.12) changeActiveCard = DOWN;
    if (this.deltaY < 0 - this.compH * 0.12) changeActiveCard = UP;

    // return cards to old position
    if (!changeActiveCard) {
      if (!this.otherCardId || this.index === this.otherCardId) return this.index;
      if (this.deltaY === 0) return this.index;
      this.props.emitter.emit('animateExitPositions', {
        data: [
          { id: this.index, y: 0 },
          {
            id: this.otherCardId,
            y: this.deltaY < 0 ? window.innerHeight : -window.innerHeight,
            scale: 0.5,
            deg: -30,
            hide: true,
          },
        ],
      });
      this.deltaY = 0;
      return this.index;
    } else {
      this.props.onChange(this.mod(this.otherCardId));
      this.props.emitter.emit('animateExitPositions', {
        data: [
          {
            id: this.index,
            y: this.deltaY > 0 ? window.innerHeight : -window.innerHeight,
            scale: 0.5,
            deg: -30,
            hide: true,
          },
          { id: this.otherCardId, y: 0 },
        ],
      });
      this.deltaY = 0;
      return this.otherCardId;
    }
  };

  animateExitPositions = e => {
    const { data } = e;

    data.forEach(transform => {
      if (this.index === this.mod(transform.id)) {
        const scale = transform.scale || 1;
        const deg = transform.deg || 0;
        const speed = transform.speed || 300;

        this.page.style.transition = `transform ${speed}ms ease`;
        this.page.style.visibility = 'visible';
        this.page.style.transform = `
          translateY(${transform.y}px)
          scale(${scale})
          rotate3D(0.4,1,0, ${deg}deg)
        `;

        if (transform.hide) {
          // this.page.addEventListener('transitionend', this.hide);

          clearTimeout(this.timer2);

          this.timer2 = setTimeout(() => {
            if (this.page) this.page.style.visibility = 'hidden';
          }, speed);
        }
      }
    });
  };

  render() {
    const {
      color,
      name,
      projected,
      nextLabel,
      prevLabel,
      onNext,
      onPrev,
      index,
      totalCards,
      currency,
      onChange,
      active,
      emitter,
    } = this.props;

    return (
      <Page
        innerRef={c => (this.page = c)}
        color={color}
        layer={2}
        hidden={!active}
        onChange={onChange}
      >
        <ContentGrid fullHeight>
          {({ getWideContentProps, getNarrowContentProps }) => (
            <>
              <div
                ref={c => (this.main = c)}
                {...getNarrowContentProps()}
                style={{ position: 'relative', zIndex: 2 }}
              >
                <PolicyValue
                  projected={projected}
                  currency={currency}
                  name={name}
                  url={`/dashboard/explanation/${name}`}
                />
              </div>

              {(() => {
                if (totalCards > 1) {
                  return (
                    <div {...getWideContentProps()} style={{ position: 'relative' }}>
                      <DashboardNavigation
                        emitter={emitter}
                        nextLabel={nextLabel}
                        prevLabel={prevLabel}
                        onNext={onNext}
                        onPrev={onPrev}
                      />
                      <DashboardPagination index={index} total={totalCards} />
                    </div>
                  );
                }
                return '';
              })()}
            </>
          )}
        </ContentGrid>
      </Page>
    );
  }
}

export default Card;
