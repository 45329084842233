// page/index.js

import React, { Component } from 'react';
import { number, oneOf } from 'prop-types';
import cc from 'classcat';
import ConnectedTransition from 'react-connected-transition';
import routeContext from '../../containers/routeContext';
import Lines from '../lines';
import ContentGrid from '../contentGrid';
import ResizeChecker from '../../containers/resizeChecker';
import { ThemeProvider } from '../../containers/themeContext';
import ConnectedPageTransition from '../connectedPageTransition';
// import LinesSource from '../lines/source';
import styles from './style.module.scss';
import { mod } from '../../utils/lines';
import ConnectedCardTransition from '../connectedCardTransition';

class Page extends Component {
  static propTypes = {
    color: oneOf(['white', 'darkPetrol', 'purple', 'petrol', 'yellow', 'mint']),
    layer: number,
    index: number,
    yPos: number,
  };
  static contextType = routeContext;
  static defaultProps = {
    color: 'white',
    layer: 1,
    index: 0,
    yPos: 0,
    innerRef: c => c,
  };

  constructor(props) {
    super();
    // this.activeId = props.activeIndex;
    // this.index = props.index;
  }

  getTransitionData = () => ({
    ...this.context,
    ...this.props,
  });

  setRef = c => {
    this.main = c;
    this.props.innerRef(c);
  };

  render() {
    const { children, layer, color, className, style, hidden } = this.props;
    const seed = mod(layer);
    const flipH = seed > 0.5;
    const flipV = (seed * 10) % 1 > 0.5;
    // const id = `lines-${seed.toString(26).substr(2)}`;

    return (
      <ConnectedPageTransition
        name="page"
        passive={hidden}
        getTransitionData={this.getTransitionData}
      >
        {({ from, to, state }, transitionState) => (
          <>
            <main
              ref={this.setRef}
              className={cc([
                styles[transitionState],
                from && styles[`fromLayer${from.layer}`],
                to && styles[`toLayer${to.layer}`],
                from &&
                  to &&
                  from.column &&
                  to.column &&
                  styles[`column${from.column < to.column ? 'RightToLeft' : 'LeftToRight'}`],

                from && to && from.name && to.name && styles[`from-${from.name}-to-${to.name}`],
                from && to && !from.isProtected && to.isProtected && styles.loggingIn,
                styles.page,
                styles[`layer${layer}`],
                styles[color],
                this.context.isProtected && styles.hasMenu,
                className,
              ])}
              style={style}
            >
              <ConnectedTransition
                name={this.context.uri}
                exit={transitionState === 'exiting' || transitionState === 'exited'}
              >
                <ConnectedCardTransition>
                  <div className={styles.background} />
                </ConnectedCardTransition>
              </ConnectedTransition>
              {/* <LinesSource
                id={'linesSource'}
                seed={seed}
                paused={transitionState === 'entering' || transitionState === 'exiting'}
              /> */}
              <ContentGrid className={styles.gridLines}>
                {() => (
                  <>
                    <div
                      className={cc([
                        styles.lineContainer,
                        flipH && styles.flipH,
                        flipV && styles.flipV,
                      ])}
                    >
                      <Lines
                        id={'linesSource'}
                        light={color !== 'white'}
                        paused={transitionState === 'entering' || transitionState === 'exiting'}
                      />
                    </div>
                    <ResizeChecker breakpoints={['large']}>
                      {widthState =>
                        widthState > 0 && (
                          <div
                            className={cc([
                              styles.lineContainer,
                              styles.second,
                              !flipH && styles.flipH,
                              flipV && styles.flipV,
                            ])}
                          >
                            <Lines
                              id={'linesSource'}
                              light={color !== 'white'}
                              paused={
                                transitionState === 'entering' || transitionState === 'exiting'
                              }
                              flipped
                            />
                          </div>
                        )
                      }
                    </ResizeChecker>
                    <span className={styles.line} />
                    <span className={styles.line} />
                    <span className={styles.line} />
                    <span className={styles.line} />
                    <span className={styles.line} />
                  </>
                )}
              </ContentGrid>
              <ThemeProvider value={{ color: this.props.color }}>
                <div className={styles.scrollContainer}>{children}</div>
              </ThemeProvider>
            </main>
          </>
        )}
      </ConnectedPageTransition>
    );
  }
}

export default Page;
