import React from 'react';
import { translate } from '../../containers/translationContext';
import NarrowColumn from '../../components/contentGrid/narrowColumn';
import { actions } from '../../store';
import { connect } from '../../containers/storeContext';
import Placeholder from '../../components/placeholder';
import ChangeOfPersonalData from '../../components/form/forms/alteration/changeOfPersonalData';
import Data from '../../containers/data';

class AlterationChangeOfPersonalData extends React.Component {
  state = {
    activePolicy: this.props.activePolicy,
    policyHolderDetails: null,
  };

  componentDidMount() {
    this.loadPolicyDetails(this.state.activePolicy.contractId);
  }

  loadPolicyDetails = contractId => {
    const { getData } = this.props;
    var options = {
      onSuccess: data => {
        this.setState({ policyHolderDetails: data.policyHolders[0] });
      },
    };

    getData(`policy`, options, contractId);
  };

  render() {
    return this.state.policyHolderDetails === null ? (
      <Placeholder />
    ) : (
      <NarrowColumn>
        <Data keys={['user']}>
          {([user]) => (
            <ChangeOfPersonalData
              activePolicy={this.state.activePolicy}
              policyHolderDetails={this.state.policyHolderDetails}
              user={user}
            />
          )}
        </Data>
      </NarrowColumn>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activePolicy: state.activePolicy,
  };
};

export default connect(mapStateToProps, actions)(translate(AlterationChangeOfPersonalData));
