// disabled lazy loading for now, because in synchronous mode Suspense always renders the placeholder for a brief moment,
// causing the connected page transition to miss sometimes
import Landing from '../routes/index';
import Login from '../routes/login';
import Activate from '../routes/accountActivate';
import Dashboard from '../routes/dashboard';
import PolicyOverview from '../routes/policyOverview';
import AccountPasswordReset from '../routes/accountPasswordReset';
import AccountPasswordChoose from '../routes/accountPasswordChoose';
import Register from '../routes/register';
import AccountSettings from '../routes/accountSettings';
import AccountDelete from '../routes/accountDelete';
import AccountDigitalConsent from '../routes/accountDigitalConsent';
import AccountDeleteConfirm from '../routes/accountDeleteConfirm';
import AccountPasswordChange from '../routes/accountPasswordChange';
import AccountUpdate from '../routes/accountUpdate';
import AccountPrivacy from '../routes/accountPrivacy';
import AccountContact from '../routes/accountContact';
import AccountFaq from '../routes/accountFaq';
import AccountFaqDetail from '../routes/accountFaqDetail';
import PolicyConnect from '../routes/policyConnect';
import PolicyDetails from '../routes/policyDetails';
import PolicyDetailsGeneral from '../routes/policyDetailsGeneral';
import PolicyDetailsHolder from '../routes/policyDetailsHolder';
import PolicyDetailsInsuredPerson from '../routes/policyDetailsInsuredPerson';
import PolicyDetailsBeneficiaries from '../routes/policyDetailsBeneficiaries';
import AccountEmail from '../routes/accountEmail';
import AccountPhone from '../routes/accountPhoneNumber';
import AccountTaxId from '../routes/accountTaxId';
import Messages from '../routes/messages';
import Message from '../routes/message';
import PolicyDocuments from '../routes/policyDocuments';
import PolicyGtc from '../routes/policyGtc';
import PaymentsOverview from '../routes/paymentsOverview';
import PaymentsDetail from '../routes/paymentsDetail';
import PaymentsPayPremium from '../routes/paymentsPayPremium';
import PolicyProjectedValues from '../routes/policyProjectedValues';
import AccountEmailConfirm from '../routes/accountEmailConfirm';
import PolicyExplanation from '../routes/policyExplanation';
import DetailLumpSum from '../routes/detailLumpSum';
import DetailPaymentPlan from '../routes/detailPaymentPlan';
import DetailDynamicOption from '../routes/detailDynamicOption';
import DetailMaturityGuarantee from '../routes/detailMaturityGuarantee';
import DetailRiskType from '../routes/detailRiskType';
import DetailContractStatus from '../routes/detailContractStatus';
import DetailContractDuration from '../routes/detailContractDuration';
import DetailTechnical from '../routes/detailTechnical';
import NotFound from '../routes/notFound';
import DetailTechnicalPayer from '../routes/detailTechnicalPayer';
import DetailTechnicalPaymentData from '../routes/detailTechnicalPaymentData';
import AlterationList from '../routes/alterationList';
import AlterationPaymentMethod from '../routes/alterations/alterationPaymentMethod';
import AlterationPaymentFrequency from '../routes/alterations/alterationPaymentFrequency';
import AlterationChangeOfAddress from '../routes/alterations/alterationChangeOfAddress';
import AlterationChangeOfPersonalData from '../routes/alterations/alterationChangeOfPersonalData';

// prettier-ignore
const routes = [
  // Public
  { Comp: Login, path: '/login', isProtected: false, layer: 1, column: 1 },
  { Comp: AccountPasswordReset, path: '/account/password/reset', isProtected: false, layer: 1 },
  { Comp: AccountPasswordChoose, path: '/account/password/reset/:code', isProtected: false, layer: 1 },
  { Comp: Activate, path: '/account/activate/:code', isProtected: false, layer: 1 },
  { Comp: Register, path: '/register', isProtected: false, layer: 1, column: 2 },
  { Comp: AccountEmailConfirm, path: '/account/email/confirm/:code', isProtected: false, layer: 1 },
  { Comp: AccountDeleteConfirm, path: '/account/delete/confirm/:deleteToken', isProtected: false, layer: 1 },

  // Protected
  { Comp: Dashboard, path: '/dashboard', dontRenderPage: true, isProtected: true, layer: 2, name: 'dashboard' },
  { Comp: PolicyProjectedValues, path: '/dashboard/projected/:year', isProtected: true, layer: 3 },
  { Comp: AccountSettings, path: '/account', color: 'darkPetrol', isProtected: true, layer: 2 },
  { Comp: AccountDelete, path: '/account/delete', isProtected: true, layer: 3 },
  { Comp: AccountDigitalConsent, path: '/account/consent', isProtected: true, layer: 3},
  { Comp: AccountPasswordChange, path: '/account/password/change', isProtected: true, layer: 3 },
  { Comp: AccountUpdate, path: '/account/update', isProtected: true, layer: 3 },
  { Comp: AccountPrivacy, path: '/account/privacy', isProtected: true, layer: 3 },
  { Comp: AccountContact, path: '/service/contact', isProtected: true, layer: 3, column: 3 },
  { Comp: AccountFaq, path: '/service', color: 'darkPetrol', isProtected: true, layer: 2 },
  { Comp: AccountFaqDetail, path: '/service/faq/:category/:section', isProtected: true, layer: 3, column: 1 },
  { Comp: AccountFaqDetail, path: '/service/faq/:category/:section/:question', isProtected: true, layer: 3, column: 2 },
  { Comp: PolicyOverview, path: '/policy/overview', color: 'darkPetrol', isProtected: true, layer: 2, name:  'policyOverview' },
  { Comp: PolicyConnect, path: '/policy/connect', isProtected: true, layer: 3 },
  { Comp: PolicyDetails, path: '/policy/details', color: 'darkPetrol', isProtected: true, layer: 2 },
  { Comp: PolicyDetailsGeneral, path: '/policy/details/general', isProtected: true, layer: 3 },
  { Comp: PolicyDetailsHolder, path: '/policy/details/policyHolder', isProtected: true, layer: 3 },
  { Comp: PolicyDetailsInsuredPerson, path: '/policy/details/insuredPerson', isProtected: true, layer: 3 },
  { Comp: PolicyDetailsBeneficiaries, path: '/policy/details/beneficiaries', isProtected: true, layer: 3 },
  { Comp: AlterationList, path: '/alteration', color: 'darkPetrol', isProtected: true, layer: 2 },
  { Comp: AlterationPaymentMethod, path: "/alteration/paymentMethod", isProtected: true, layer: 3 },
  { Comp: AlterationPaymentFrequency, path: "/alteration/paymentFrequency", isProtected: true, layer: 3 },
  { Comp: AlterationChangeOfAddress, path: "/alteration/changeOfAddress", isProtected: true, layer: 3 },
  { Comp: AlterationChangeOfPersonalData, path: "/alteration/changeOfPersonalData", isProtected: true, layer: 3 },
  { Comp: AccountEmail, path: '/account/email', isProtected: true, layer: 3 },
  { Comp: AccountPhone, path: '/account/phone', isProtected: true, layer: 3 },
  { Comp: AccountTaxId, path: '/account/taxId', isProtected: true, layer: 3 },
  { Comp: Landing, path: '/', isProtected: true, dontRenderPage: true, layer: 1 },
  { Comp: Messages, path: '/messages', color: 'darkPetrol', isProtected: true, layer: 2 },
  { Comp: Message, path: '/messages/:id', isProtected: true, layer: 3 },
  { Comp: PolicyDocuments, path: '/documents', color: 'darkPetrol', isProtected: true, layer: 2 },
  { Comp: PolicyGtc, path: '/gtc/list', color: 'darkPetrol', isProtected: true, layer: 2 },
  { Comp: PaymentsOverview, path: '/payments', isProtected: true, color: 'darkPetrol', layer: 2 },
  { Comp: PaymentsDetail, path: '/payments/detail/:year', isProtected: true, layer: 3 },
  { Comp: PaymentsPayPremium, path: '/payments/pay', isProtected: true, layer: 1, column: 2 },
  { Comp: PolicyExplanation, path: '/dashboard/explanation/:type', isProtected: true, layer: 1 },
  { Comp: DetailLumpSum, path: '/payments/lumpSum', isProtected: true, layer: 1, column: 2 },
  { Comp: DetailLumpSum, path: '/payments/lumpSum', isProtected: true, layer: 1, column: 2, },
  { Comp: DetailPaymentPlan, path: '/payments/plan', isProtected: true, layer: 1, column: 2, },
  { Comp: DetailDynamicOption, path: '/policy/details/information/dynamicOption', isProtected: true, layer: 3, column: 2 },
  { Comp: DetailMaturityGuarantee, path: '/policy/details/information/maturityGuarantee', isProtected: true, layer: 3, column: 2 },
  { Comp: DetailRiskType, path: '/policy/details/information/riskType', isProtected: true, layer: 3, column: 2 },
  { Comp: DetailContractStatus, path: '/policy/details/information/contractStatus', isProtected: true, layer: 3, column: 2 },
  { Comp: DetailContractDuration, path: '/policy/details/information/contractDuration', isProtected: true, layer: 3, column: 2 },
  { Comp: DetailTechnical, path: '/payments/technical', isProtected: true, layer: 2, color: 'darkPetrol'},
  { Comp: DetailTechnicalPaymentData, path: '/payments/technical/paymentData', isProtected: true, layer: 3},
  { Comp: DetailTechnicalPayer, path: '/payments/technical/payer', isProtected: true, layer: 3},
  { Comp: NotFound, path: '/404', default: true, isProtected: false, layer: 1 },
];

export default routes;
