function addFocusClasses(el) {
  function keyboardFocus() {
    el.classList.add('keyboard-focus');
  }

  function mouseFocus() {
    el.classList.remove('keyboard-focus');
  }

  document.addEventListener('keyup', keyboardFocus);
  document.addEventListener('mousedown', mouseFocus);
}

export default addFocusClasses;
