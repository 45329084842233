import { getUserAgent } from 'dpdk-devicedetection';
import anime from 'animejs';

const validTransforms = [
  'translateX',
  'translateY',
  'translateZ',
  'rotate',
  'rotateX',
  'rotateY',
  'rotateZ',
  'scale',
  'scaleX',
  'scaleY',
  'scaleZ',
  'skewX',
  'skewY',
  'perspective',
];

function setCSS(node, values) {
  const transforms = [];
  const otherValues = {};
  Object.entries(values).forEach(([key, value]) => {
    if (validTransforms.indexOf(key) !== -1) {
      const val = typeof value === 'number' ? `${value}px` : value;
      transforms.push(`${key}(${val})`);
    } else {
      otherValues[key] = value;
    }
  });
  Object.assign(node.style, otherValues, { transform: transforms.join(' ') });
}

function shouldDisableCoolStuff() {
  const UA = getUserAgent();
  return UA === 'IE11' || UA === 'Edge';
}

// reverse animation without initial flicker that Anime produces
function animateReverse(node, values, params) {
  anime({
    ...params,
    ...values,
    targets: node,
    direction: 'reverse',
    complete: () => {
      node.style = '';
    },
  });
  setCSS(node, values);
}

export { shouldDisableCoolStuff, animateReverse, setCSS };
