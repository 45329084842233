function formatTime(
  language,
  timestamp,
  { weekday, year, month, day, hour, minute, second, hour12 } = {}
) {
  if (timestamp === 0 || timestamp === '0') return '';

  const date = new Date(0);
  date.setUTCSeconds(timestamp);
  const options = {
    weekday: weekday === true ? 'long' : weekday,
    year: year ? 'numeric' : undefined,
    month: month === true ? '2-digit' : month,
    day: day === true ? (month === 'long' ? 'numeric' : '2-digit') : day,
    hour: hour ? '2-digit' : undefined,
    minute: minute ? '2-digit' : undefined,
    second: second ? '2-digit' : undefined,
    hour12,
    timeZone: 'UTC',
  };

  return date.toLocaleString(language === 'en' ? 'en-GB' : language, options);
}

export default formatTime;
