import { Component } from 'react';
import { request } from '../utils/request';

class Request extends Component {
  static defaultProps = {
    method: 'get',
    onSuccess() {},
    onError() {},
    lazy: false,
  };

  state = {
    fetching: !this.props.lazy,
    response: null,
    error: null,
    success: false,
  };

  componentDidMount() {
    if (!this.props.lazy) {
      this.doFetch();
    }
  }

  doFetch = () => {
    const { url, params, method, api } = this.props;
    this.setState({ fetching: true, error: null, response: null });
    const isArray = Array.isArray(api);
    const apis = isArray ? Promise.all(api.map(endpoint => endpoint())) : api;

    (apis ? api(null, params) : request(url, params, method))
      .then(response => {
        const success = isArray ? response.every(res => res.success) : response.success;
        this.setState({ fetching: false, response, success });
        if (success) this.props.onSuccess(response);
        if (!success) this.props.onError(response);
      })
      .catch(e => {
        this.setState({ error: e.message, fetching: false });
      });
  };

  render() {
    return this.props.children({
      ...this.state,
      fetch: this.props.lazy ? this.doFetch : undefined,
    });
  }
}

export default Request;
