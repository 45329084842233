import { navigate } from '@reach/router';
import delve from 'dlv';
import en from '../lang/en/general.json';
import createUnistore from 'unistore';
import devtools from 'unistore/devtools';
import { endpoints, sources } from '../config/data';
import languages from '../config/languages';
import localStorageAdapter from './localStorageAdapter';
import persistStore from './persistStore';

const translations = {
  en,
};

const initialState = {
  authToken: null,
  expiresAt: null,
  data: {},
  activePolicy: null,
  dashboardPages: {},
  dashboardIndexes: [],
  language: 'en',
};
const pending = {};

function actions(store) {
  const actionFuncs = {
    setUser(state, authToken, expiresAt, user) {
      callAction('changeLanguage', user?.languageId?.toLowerCase());
      return { authToken, expiresAt };
    },
    clearUser(state) {
      if (state.authToken)
        endpoints
          .logout(state.authToken)
          .then(response => {})
          .catch(e => {});

      localStorage.clear('dashboardIndex');
      sessionStorage.clear();
      return { ...initialState, language: state.language };
    },
    getData(state, key, opts, ...params) {
      const options = opts || {};
      const dataKey = [key, ...params].join(':');
      const existing = state.data[dataKey];
      const { lifetime, path = 'data', transformation = d => d } = sources[key];

      if (existing && existing.expiresAt > Date.now()) {
        if (options.onSuccess) options.onSuccess(existing.value);
        return Promise.resolve(null);
      }

      if (pending[dataKey]) {
        return pending[dataKey].then(() => null);
      }
      const localPending = (pending[dataKey] = new Promise((resolve, reject) => {
        endpoints[key](state.authToken, ...params)
          .then(res => {
            if (localPending !== pending[dataKey]) {
              return;
            }
            delete pending[dataKey];
            const state = store.getState();

            if (res.success) {
              const value = transformation(delve(res, path)) || null;
              if (key === 'policies' && value && value.length) {
                // TODO: maybe check if current active policy is in the list of newly fetched policies
                if (!state.activePolicy) {
                  callAction('setActivePolicy', value[0]);
                }
              }

              resolve({
                data: {
                  ...state.data,
                  [dataKey]: { value, expiresAt: Date.now() + lifetime * 1000 },
                },
              });

              if (options.onSuccess) options.onSuccess(value);
            } else {
              throw new Error(
                res.message ||
                  (res.errors && res.errors[0] && res.errors[0].msg) ||
                  'Error fetching data'
              );
            }
          })
          .catch(e => {
            delete pending[dataKey];

            if (options.onError) {
              options.onError(e);
            }

            reject(e);

            if (e.message.indexOf('session') !== -1) {
              alert(translations[state.language]['app.sessionExpired']);
              navigate('/login');
            }

            return null;
          });

        return null;
      }));

      return pending[dataKey];
    },
    invalidateData(state, key, ...params) {
      const dataKey = [key, ...params].join(':');

      return { data: { ...state.data, [dataKey]: { ...state.data[dataKey], expiresAt: 0 } } };
    },
    invalidateDataOfPattern(state, pattern, ...params) {
      const entries = Object.entries(state.data);

      const data = { ...state.data };

      entries.forEach(([key, value]) => {
        if (key.indexOf(pattern) > -1) data[key] = { ...value, expiresAt: 0 };
      });

      return { data };
    },
    invalidateAllData(state, ...params) {
      const entries = Object.entries(state.data);

      const data = {};

      entries.forEach(([key, value]) => {
        data[key] = { ...value, expiresAt: 0 };
      });

      return { data };
    },
    changeLanguage(state, lang) {
      // The language should exist
      if (languages.filter(l => l.key === lang).length === 0) {
        return null;
      }

      if (!translations[lang]) {
        return import(/* webpackChunkName: "lang-[request]" */ `../lang/${lang}/general.json`).then(
          translation => {
            translations[lang] = translation;
            return { language: lang };
          }
        );
      }
      return Promise.resolve({ language: lang });
    },
    changeMarket(state, marketId) {
      return {
        marketId: marketId,
      };
    },
    setActivePolicy(state, policy) {
      return {
        activePolicy: policy,
      };
    },
    setDashboardData(state, projectedValues) {
      return {
        dashboardPages: projectedValues.pages,
        dashboardIndexes: projectedValues.indexes,
      };
    },
  };

  function callAction(action, ...args) {
    store.action(actionFuncs[action])(...args);
  }

  return actionFuncs;
}

function createStore() {
  const store =
    process.env.NODE_ENV === 'production'
      ? createUnistore(initialState)
      : devtools(createUnistore(initialState));

  const adapter = localStorageAdapter('fwuPersistDb');
  const { loaded } = persistStore(store, adapter, {
    version: 14, // Increment this when state shape has changed
    migration: state => ({
      ...initialState,
      authToken: state && state.authToken,
      expiresAt: state && state.expiresAt,
    }),
  });

  const initPromise = new Promise(res => {
    loaded.then(() => {
      const { language: hydratedLanguage } = store.getState();
      store.setState({
        language: 'en',
      });

      if (!store.getState().hydrated) {
        const lang = navigator.language.split('-')[0];
        if (
          languages
            .map(l => l.key)
            .filter(l => l !== 'en')
            .indexOf(lang) > -1
        ) {
          boundActions.changeLanguage(lang).then(res);
        }
      } else {
        if (hydratedLanguage !== 'en') {
          boundActions.changeLanguage(hydratedLanguage).then(res);
        } else {
          res();
        }
      }
    });
  });

  return { store, loaded: initPromise };
}

const { store, loaded } = createStore();
const boundActions = Object.entries(actions(store)).reduce((obj, [key, func]) => {
  obj[key] = store.action(func);
  return obj;
}, {});

export default store;
export { actions, boundActions, translations, loaded };
