import { oneOfType, string, bool, number, oneOf } from 'prop-types';
import { connect } from '../containers/storeContext';
import formatTime from '../utils/formatTime';

Time.propTypes = {
  timestamp: oneOfType([string, number]).isRequired,
  weekday: oneOfType([bool, oneOf(['short', 'long'])]),
  year: bool,
  month: oneOfType([bool, oneOf(['2-digit', 'long', 'short'])]),
  day: bool,
  hour: oneOfType([bool, oneOf(['2-digit', 'numeric'])]),
  minute: bool,
  second: bool,
  hour12: bool,
};

function Time({ language, timestamp, ...rest }) {
  return formatTime(language, timestamp, rest);
}

export default connect('language')(Time);
