import { get, post, put } from '../utils/request';
import {
  transformLanguagesData,
  transformMessagesData,
  transformPaymentData,
  transformPhoneCountryCodesData,
  transformProjectedValuesData,
  transformPaymentPlanData,
} from '../utils/transformData';

const endpoints = {
  // data sources
  activationKeyStatus: (jwt, contractId, taxId, marketId) =>
    post('/api/contract/key/status', { contractId, taxId, marketId }, jwt),
  documents: (jwt, contractId) => post('/api/document/list', { contractId }, jwt),
  gtc: (jwt, contractId, marketId) => get('/api/gtc/list', { contractId, marketId }, jwt),
  explanation: (jwt, type, contractId) =>
    get(`/api/explanation/${type}/${contractId}`, undefined, jwt),
  faq: jwt => get('/api/faq/list', undefined, jwt),
  languages: (jwt, marketId) => get(`/api/info/language?marketId=${marketId}`, undefined, jwt),
  logout: jwt => post(`/api/user/logout`, undefined, jwt),
  markets: jwt => get('/api/info/market', undefined, jwt),
  messages: (jwt, contractId, limit, offset) =>
    post(`/api/message/list`, { contractId, limit, offset }, jwt),
  payments: (jwt, id) => get(`/api/contract/${id}/payment-history`, undefined, jwt),
  phoneCountryCodes: jwt => get(`/api/info/country-codes`, undefined, jwt),
  policies: jwt => get('/api/user/contracts', undefined, jwt),
  policy: (jwt, id) => get(`/api/contract/${id}`, undefined, jwt),
  policyActivationKey: (jwt, policyActivationKeyParameters, marketId) =>
    post('/api/contract/key/generateKey', { policyActivationKeyParameters, marketId }, jwt),
  privacy: jwt => get(`/api/info/privacy`, undefined, jwt),
  projectedValues: (jwt, contractId) =>
    get(`/api/contract/${contractId}/projected-values`, undefined, jwt),
  salutations: jwt => get(`/api/info/salutations`, undefined, jwt),
  unreadMessageCount: jwt => post(`/api/message/get-amount`, undefined, jwt),
  user: jwt => get(`/api/user/get`, undefined, jwt),
  paymentPlan: (jwt, contractId) => get(`/api/contract/${contractId}/payment-plan`, undefined, jwt),
  possibleAlterationList: (jwt, contractId) =>
    get(`/api/alteration/${contractId}/possibleAlterations`, undefined, jwt),
  paymentMethods: (jwt, id) => get(`/api/alteration/${id}/payment-methods`, undefined, jwt),
  paymentFrequencies: (jwt, id) => get(`/api/alteration/${id}/payment-frequencies`, undefined, jwt),
  countries: jwt => get(`/api/info/country`, undefined, jwt),
  translateScreen: (jwt, contractId, screenId) =>
    get(`/api/translate/screen/${contractId}/${screenId}`, undefined, jwt),

  // forms
  // unauthenticated
  activate: (_, body) => post('/api/user/activate', body),
  emailNotExists: (_, body) => post('/api/user/notexist', body),
  passwordForgotten: (_, body) => post('/api/user/forget-password', body),
  passwordReset: (_, body) => post('/api/user/reset-password', body),
  register: (_, body) => post('/api/user/register', body),
  resendEmail: (_, body) => post('/api/user/resend-email', body),
  unregisteredUserData: (_, body) => post('/api/user/unregistered-user-data', body),
  generateActivationKey: (_, body) => post(`/api/contract/generate-activation-key`, body),
  confirmUser: (_, body) => post('/api/user/confirm', body),

  // authenticated
  changeEmail: (jwt, body) => post('/api/user/change-email', body, jwt),
  delete: (jwt, body) => post('/api/user/delete-request', body, jwt),
  deleteConfirm: (jwt, body) => get('/api/user/delete', body, jwt),
  login: (jwt, body) => post('/api/user/login', body, jwt),
  markMessageAsRead: (jwt, body) => post('/api/message/get', body, jwt),
  readAllMessages: (userId, messageListDTO, jwt) =>
    post(
      `/api/contract/read-all?userId=${userId}&messageListDTO=${messageListDTO}`,
      undefined,
      jwt
    ),
  passwordChange: (jwt, body) => post('/api/user/change-password', body, jwt),
  policyActivate: (jwt, body) => post('/api/policy/activate', body, jwt),
  policyActivationCodeSendMethod: (jwt, body) => post('/api/contract/key/confirmMode', body, jwt),
  policyAlteration: (jwt, body) => put('/api/contract/alter-data', body, jwt),
  updateProfile: (jwt, body) => put('/api/user/update', body, jwt),
  generateSmsToken: (jwt, body) => post('/api/user/generateSmsToken', body, jwt),
  updatePhoneNumber: (jwt, body) => post('/api/user/updatePhoneNumber', body, jwt),
  updatePhoneNumberRequest: (jwt, body) => post('/api/user/updatePhoneNumberRequest', body, jwt),
  updateTaxId: (jwt, body) => post('/api/user/updateTaxId', body, jwt),
  updatePaymentMethod: (jwt, body) => post('/api/alteration/updatePaymentMethod', body, jwt),
  updatePaymentFrequency: (jwt, body) => post('/api/alteration/updatePaymentFrequency', body, jwt),
  updateAddress: (jwt, body) => post('/api/alteration/updateAddress', body, jwt),
  updatePersonalData: (jwt, body) => post('/api/alteration/updatePersonalData', body, jwt),
  userDigitalConsent: (jwt, body) => post('/api/user/save-user-consent', body, jwt),
  userGetDigitalConsent: (jwt, userId) =>
    get(`/api/user/get-user-consent?userId=${userId}`, undefined, jwt),
};

const sources = {
  activationKeyStatus: {
    lifetime: 600, // 10 minutes
  },
  documents: {
    lifetime: 3600 * 24,
    path: 'data.documents',
  },
  gtc: {
    lifetime: 3600 * 24,
  },
  explanation: {
    lifetime: 3600 * 24 * 7,
  },
  faq: {
    lifetime: 3600 * 24 * 7,
    path: 'data',
  },
  policyActivationKey: {
    lifetime: 600, // 10 minutes
  },
  languages: {
    lifetime: 3600 * 24 * 31,
    path: 'data.languages',
    transformation: transformLanguagesData,
  },
  markets: {
    lifetime: 3600 * 24 * 7,
    path: 'data.markets',
  },
  messages: {
    lifetime: 15 * 60,
    path: 'data.messages',
    transformation: transformMessagesData,
  },
  paymentMethods: {
    lifetime: 15 * 60,
    path: 'data.paymentMethods',
  },
  paymentFrequencies: {
    lifetime: 1,
    path: 'data',
  },
  paymentPlan: {
    lifetime: 3600 * 24,
    path: 'data',
    transformation: transformPaymentPlanData,
  },
  payments: {
    lifetime: 3600 * 24,
    path: 'data.paymentHistory',
    transformation: transformPaymentData,
  },
  phoneCountryCodes: {
    lifetime: 3600 * 24,
    path: 'data.countries',
    transformation: transformPhoneCountryCodesData,
  },
  policies: {
    // time (in seconds) before data is considered stale
    lifetime: 3600 * 24 * 7,
    // where in the response to find the data. Default 'data'
    path: 'data.contracts',
  },
  policy: {
    lifetime: 3600 * 24 * 7,
  },
  privacy: {
    lifetime: 3600 * 24 * 7,
    path: 'data.translations',
  },
  projectedValues: {
    lifetime: 3600 * 24,
    path: 'data.projectedValues',
    transformation: transformProjectedValuesData,
  },
  salutations: {
    lifetime: 3600 * 24 * 31,
    path: 'data.salutations',
  },
  unreadMessageCount: {
    lifetime: 5 * 60,
    path: 'data.unread',
    transformation: v => parseInt(v),
  },
  user: {
    lifetime: 3600 * 24 * 7,
  },
  possibleAlterationList: {
    lifetime: 5 * 60,
  },
  countries: {
    lifetime: 3600 * 24 * 7,
  },
  translateScreen: {
    lifetime: 3600 * 24 * 7,
  },
};

export { endpoints, sources };
