import React from 'react';
import style from './style.module.scss';

function DashboardPagination({ index, total }) {
  return (
    <div className={style.container}>
      <div className={style.numerator}>{`0${index + 1}`}</div>
      <div className={style.denominator}>{`0${total}`}</div>
    </div>
  );
}

export default DashboardPagination;
